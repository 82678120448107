

.responseLoaderWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px;
}

.responseLoaderWrapper .circleWarning{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgba(235, 87, 87, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    box-shadow: rgba(214, 60, 60, 0.52) 0px 5px 15px;
    
}
.responseLoaderWrapper .circleCheck{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgba(0, 186, 103, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    box-shadow: rgb(36, 189, 120) 0px 5px 15px;
}
.responseLoaderWrapper span{
      font-size:24px;
      color:#fff;
      font-weight: 600;
      z-index: 1;
      text-wrap: nowrap;
      white-space: nowrap
  }

.responseLoaderWrapper .message{
    font-size: 24px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: center;
    color:rgba(70, 81, 102, 1)
  }