.evaluatorWrapper {
    padding: 40px 40px 80px 40px;
    h3 {
        font-size: 18px;
        display: flex;
        justify-content: center;
        span {
            font-weight: bold;
            padding-left: 5px;
        }
    }
}