.modalWrapper {
    height: 100vh;
    width: 100%;
    background-color: #f4f6fc;
    z-index: 99;
    position: fixed;
    top: 0%;
    left: 0%;
    overflow: scroll;
}

.navigationbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    padding: 10px 0px;
    margin-bottom: 10px;
    z-index: 9;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    overflow: hidden;
    img {
        margin-left: 20px;
    }
    span {
        margin-left: 40px;
        font-size: 16px;
        font-weight: 500;
    }
}
.child {
    overflow-y: scroll;
}