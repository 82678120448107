.onOffButton {
    display: flex;
    align-items: center;
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 30px;
        input { 
            opacity: 0;
            width: 0;
            height: 0;
            &:checked + .slider {
                background-color: #0037FF;
                &::before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
            }
            &:focus + .slider {
                box-shadow: 0 0 1px #0037FF;
            }
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;
            &::before {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                left: 4px;
                bottom: 0;
                background-color: white;
                transition: .4s;
                border-radius: 50%;
                margin: auto;
                top: 0;
            }
        }
    }
    .labletext {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #0037FF;
        padding-left: 10px;
    }
}