.paymentPrStatus {
    background-color: #f9faff;
    padding: 10px;
    min-height: 166px;
    position: relative;
    .prInitialisedWrapper {
        text-align: center;
        .prInitialised, .prSubmitted, .prRejected, .prApproved {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.5px;
            background-repeat: no-repeat;
            margin: 0;
            padding: 0 0 0 30px;
            text-decoration: underline;
            display: inline-block;
            cursor: pointer;
        }
        .prInitialised {
            color: #2e384d;
            background-image: url('../../components/images/prInitialised.svg');
        }
        .prSubmitted {
            color: #2e384d;
            background-image: url('../../components/images/prSubmit.svg');
        }
        .prRejected {
            color: #e02020;
            background-image: url('../../components/images/prRejected.svg');
        }
        .prApproved {
            color: #00ba67;
            background-image: url('../../components/images/prApproved.svg');
        }
    }
    
    .bidRejected {
        margin-top: 8px;
        text-align: center;
        .textbid {
            font-size: 12px;
            letter-spacing: 0.5px;
            color: #e02020;
            position: relative;
            display: inline-block;
            padding-left: 20px;
            margin: 0;
            &::before {
                content: '';
                background-image: url('../../components/images/bidReject.svg');
                background-repeat: no-repeat;
                position: absolute;
                top: 4px;
                left: 0;
                width: 13px;
                height: 10px;
            }
        }
        
    }
    .ctaWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
    }
    .ctaWrapperBid {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            height: 30px;
            width: 100px;
        }
    }
}
.validText {
    text-align: center;
    margin: 10px 0;
    strong {
        color: #000;
    }
    p {
        margin: 0;
        padding: 0;
    }
}