.warningPopup {
    width: 385px;
    margin: auto;
    .error {
        color: #c80039;
        font-size: 12px;
        position: relative;
        width: 290px;
        padding-left: 50px;
        &::before {
            content: '';
            background-image: url('../images/infoIcon.svg');
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            top: 40%;
            transform: rotate(180deg);
        }
    }
}