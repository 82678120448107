.carDetailWrapper  {
    .carDetail {
        margin-left: 10px;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                font-size: 14px;
                letter-spacing: 1px;
                color: #585858;
                padding: 6px 0 6px 0px;
                display: flex;
                .detailsInfo {
                    padding: 0;
                    width: 90%;
                    font-size: 14px;
                    margin-left: 10px;
                    white-space: normal;
                    .max {
                        white-space: nowrap;
                        max-width: 18ch;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                }
                .detailsIcon {
                    margin: 0;
                    padding: 0;
                    width: 50px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #0037ff;
                    img {
                        // position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }
                }
                
            }
        }
    }
    .index {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #ebefff;
        min-width: 23px;
        min-height: 21px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
    }
    .whatsappchat {
        display: inline;
        text-align: center;
        float: right;
        p {
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            color: #00B934;
            border: 1px solid #00B934;
            border-radius: 5px;
            margin: 0px;
            padding: 1px 5px;
            display: inline;
        }
    }
}
