.negoTabs {
    width: 100%;
    margin-top: 20px;
    margin-bottom: -10px;
    padding-bottom: 17px;
    ul {
        margin: 0 0 -17px 0;
        padding: 0;
        display: flex;
        li {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #2E384D;
            list-style: none;
            margin-right: 100px;
            cursor: pointer;
            &.active {
                color: #0037FF;
                border-bottom: 1px solid #0037FF;
                padding-bottom: 10px;
            }
        }
    }
}