.acceptPriceWrapper {
    width: 350px;
    .priceText {
        color: #585858;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.88px;
        padding-top: 40px;
        margin: 0;
    }
    .buttonWrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
    }
}


.popupWrap{
    display: block;
    max-width: 255px;
    margin: 0 auto;
}
.popupWrap h2{
    padding-bottom: 24px;
    margin: 0;
}
.customerDesc{
    display: block;
    margin: 0;
    padding-bottom: 24px;
    padding-left: 0;
}
.customerDesc li{
    display: inline-block;
    list-style-type: none;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.67);
}
.customerDesc li::after {
    content: "|";
    margin: 0 5px;
}
.customerDesc li:last-child::after {
    content: "";
}
.getQuote{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
}
.getQuote button{
    width: 143px;
    height: 48px;
    display: inline-block;
}
.getQuote p{
    font-size: 14px;
    color: #b0bac9;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 28px;
}