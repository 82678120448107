#dashboard-superset iframe {
  width: 100%;
  height: calc(100vh - 60px);
  border: 0;
  overflow: hidden;
  margin-top: 60px;
}

.supersetErrorContainer {
  height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.supersetErrorContainer iframe{
  width: 100vw;
  height: 100vh;
}
.superset-error {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
}