.lostIcon {
    cursor: pointer;
    border-left: 1px solid #c7ccdc;
    padding-left: 10px;
    margin-left: 10px;
    background-color: transparent;
    border-right: none;
    border-bottom: none;
    border-top: none;
    &:focus {
        outline: none;
        border-right: none;
        border-bottom: none;
        border-top: none;
    }
}