.selfInspectionReport {
    padding: 0px 40px 200px 40px;
    .inspectionHeader {
        background-color: #0059A3;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin: 0 -40px;
        span {
            font-size: 18px;
            color: #fff;
            font-weight: bold;
        }
    }
    .selfInspectionHeading {
        margin: 70px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            position: relative;
            font-size: 26px;
            font-weight: bold;
            padding: 0 40px;
            text-decoration: underline;
            &::before, &::after {
                content: '';
                background-image: url('../../components/images/selfInspectionIcon.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                width: 28px;
                height: 40px;
            }
            &::after {
                content: '';
                right: 0;
                left: auto;
            }
        }
    }
    .appointmentEmail {
        margin-top: 80px;
        p {
            margin: 0;
            padding: 0;
            font-size: 18px;
            color: #2E384D;
            font-weight: 500;
            span {
                font-weight: normal;
            }
        }
    }
    .dataTable, .detailsTable {
        margin-top: 30px;
        border: 1px solid #D8D8D8;
        border-radius: 3px;
        table {
            tr {
                th {
                    width: 50%;
                    padding: 18px 15px;
                    color: #003FB8;
                    border-right: 1px solid #0059A3;
                    border-bottom: 1px solid #0059A3;
                    &:last-child {
                        border-right: none;
                    }
                }
                td {
                    width: 50%;
                    .firstTable {
                        border-right: 1px solid #0059A3;
                    }
                    .subTable {
                        tr {
                            td {
                                padding: 10px;
                                border-bottom: 1px solid #D8D8D8;
                                border-right: 1px solid #99c5e8;
                                &:last-child {
                                    border-right: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .detailsTable {
        width: 50%;
    }
    .bottomInfo {
        background-color: #fff;
        border-top: 4px solid #F69B00;
        padding: 10px 20px;
        margin-top: 100px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            color: #465166;
            line-height: 24px;
            text-align: center;
        }
    }
}