.carouselWrapper {
    width: 100%;
    height: 100%;
    :global .slick-prev, :global .slick-next {
        width: 47px;
        height: 47px;
        box-shadow: 0 1px 2px 0 rgba(199, 189, 189, 0.5);
        background-color: #ffffff;
        border-radius: 100%;
        top: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            color: #000;
        }
    }
    :global .slick-slider, :global .slick-list, :global .slick-track {
        height: 100%;
    }
    :global .slick-slide {
        position: relative;
    }
}
.carouselWrapper :global .slick-prev, .carouselWrapper :global .slick-next {
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
}
.carouselWrapper :global .slick-prev {
    left: 10px;
}
.carouselWrapper :global .slick-next {
    right: 10px;
}
.carouselWrapper :global .slick-prev:hover, .carouselWrapper :global .slick-prev:focus, .carouselWrapper :global .slick-next:hover, .carouselWrapper :global .slick-next:focus {
    color: #000;
    outline: none;
    background: #fff;
}