.customerLocation {
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            font-size: 14px;
            letter-spacing: 1px;
            color: #585858;
            padding: 6px 0 6px 0px;
            position: relative;
            display: flex;
            align-items: baseline;
            min-height: 35px;
            .detailsInfo {
                margin: 0;
                padding: 0;
                width: 90%;
                font-size: 12px;
                white-space: normal;
                display: flex;
                justify-content: space-between;
            }
            .detailsIcon {
                margin: 0;
                padding: 0;
                width: 50px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0037ff;
                img {
                    position: absolute;
                    top: -11px;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
            }
            .customerLocationPopup {
                position: absolute;
                right: 0;
            }
        }
    }
}