.editPopup {
  margin: 0;
  width: 100%;
}
.editPopup a, .editPopup a:hover  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  text-decoration: none;
  color: #2e384d;
}
.editPopup a span {
  white-space: nowrap;
  max-width: 18ch;
  min-width: 18ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.followUpHistoryWrapper {
  display: flex;
}
.followupWrapper {
  width: 330px;
}
.followuphistory {
  width: 720px;
}

 .editPopup {
  margin: 0;
  position: relative;
}
.editPopup::after {
  content: '';
  border-bottom:1px solid #e8e9ec;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
}
.imgBlock {
  display: block;
  height: 30px;
}
.tags {
  position: relative;
}
.notVerified, .notWorkable, .verified {
  font-size: 12px;
  font-weight: 600;
  color: #2e384d;
  margin: 0;
  padding: 0 10px; 
  /* border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px; */
  border-radius: 6px;
  height: 18px;
  line-height: 18px;
  display: inline-block;
  position: absolute;
  top: -41px;
  right: 0px;
}
.notVerified {
  background-color: #fdf0cc;
}
.notWorkable {
  background-color: #f9d2d2;
}
.verified {
  background-color: #ccf1e1;
}