.vahanBackground {
    margin-top: 20px;
    .documentWrapper {
        background: #FFFFFF;
        border: 1px solid #E0E7FF;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 20px;
        .headingMainWrapper {
            display: flex;
            .headingWrapper {
                margin-right: 10%;
                .heading {
                    display: flex;
                    align-items: center;
                    h3 {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 27px;
                        letter-spacing: 0.5625px;
                        color: #2E384D;
                        margin: 0;
                        padding: 0;
                    }
                    .activeRed {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 19px;
                        color: #FF6359;
                        margin: 0;
                        padding: 0 0 0 26px;
                    }
                    .activeGreen {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 19px;
                        color: #00BA67;
                        margin: 0;
                        padding: 0 0 0 26px;
                    }
                    button {
                        background: transparent;
                        border: none;
                        margin-left: 26px;
                        cursor: pointer;
                        &:focus {
                            outline: none;
                        }
                    }
                }
                .textList {
                    margin-top: 30px;
                    ul {
                        margin: 0;
                        padding: 0;
                        li {
                            list-style: none;
                            display: flex;
                            align-items: center;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 21px;
                            height: 42px;
                            color: #C4C4C4;
                            margin: 0;
                            padding: 0;
                            border-bottom:0.5px solid #C3D2FF;
                            &:last-child {
                                border-bottom: none;
                            }
                            p {
                                margin: 0;
                                padding: 0;
                                width: 200px;
                            }
                            .activeText {
                                color: #000000;
                            }
                            .activeRed {
                                color: #FF6359;
                            }
                            .activeGreen {
                                color: #00BA67;
                            }
                        }
                    }
                }
            }

        }
        
        
    }
}
