.paymentDeliveryStatus {
    min-height: 166px;
    padding: 20px 10px;
    margin: -10px 0;
    .tokanStatus {
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            li {
                list-style: none;
                text-align: center;
                width: 50%;
                white-space: break-spaces;
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                    z-index: 0;
                }
                h4 {
                    margin: 30px 0 0 0;
                    padding: 0;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.5px;
                    font-weight: normal;
                    color: #2e384d;
                }
                h4.paymentFailed {
                    color: #e02020;
                }
                h4.paymentDone {
                    color: #00ba67;
                }
                h4.paymenWaiting {
                    color: #2e384d;
                }
            }
            .deliveryBorder, .deliveryBorderFailed {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 55%;
                    height: 1px;
                    background-image: linear-gradient(253deg, #c1eadc, #00B86A);
                    width: 90%;
                }
            }
            .deliveryBorderFailed {
                &::after {
                    content: '';
                    background-image: linear-gradient(253deg, #e02020, #00B86A);
                }
            }
        }
    }
    .loan {
        border-top: 1px solid #e0e7ff;
        padding: 10px 0px 0 0px;
        white-space: break-spaces;
        margin-top: 30px;
        ul {
            margin: 0 0 5px 0;
            padding: 0;
            display: flex;
            li {
                list-style: none;
                width: 50%;
                &:nth-child(odd){
                    padding-right:10px;
                }
                h3 {
                    font-size: 14px;
                    color: #2e384d;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    margin: 0;
                    padding: 0;
                }
                .pending, .lcrReceived {
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    color: #2e384d;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    padding-left: 20px;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/pendingIcon.svg');
                        background-repeat: no-repeat;
                        background-size: 100%;
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 15px;
                        height: 15px;
                    }
                }
                .lcrReceived {
                    &::before {
                        content: '';
                        background-image: url('../../components/images/rightTickIcon.svg');
                    }
                }
            }
        }
    }
}
.loanStatus {
    margin-top: 10px;
}
.tokenPending, .tokenReceived {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.tokenPending span, .tokenReceived span {
    color: #F36018;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    display: flex;
    padding: 0 0 0 22px;
    white-space: normal !important;
    max-width: 100% !important;
    display: inline !important;
}
.tokenReceived span {
    color: #00BA67;
}
.tokenPending span::before, .tokenReceived span::before {
    content: '';
    background-image: url('./images/info.svg');
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.tokenReceived span::before {
    content: '';
    background-image: url('./images/rightTick.svg');
    background-repeat: no-repeat;
    background-size: 80%;
}
