.view {
    display: flex;
    padding-right: 10px;
}
.view span{
    white-space: nowrap;
    min-width: 20ch;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}