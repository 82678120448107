/* Unlock C24 Quote Popup Starts here */
.popupWrap{
    display: block;
    max-width: 255px;
    margin: 0 auto;
}
.popupWrap h2{
    padding-bottom: 24px;
    margin: 0;
}
.customerDesc{
    display: block;
    margin: 0;
    padding-bottom: 24px;
    padding-left: 0;
}
.customerDesc li{
    display: inline-block;
    list-style-type: none;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.67);
}
.customerDesc li::after {
    content: "|";
    margin: 0 5px;
}
.customerDesc li:last-child::after {
    content: "";
}
.otpDetail{
    font-size: 14px;
    color: #2e384d;
    width: 225px;
    margin: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e7ff;
}
.inputPhone{
    display: block;
    padding-top: 53px;
    position: relative;
    width: 233px;
}
.inputPhone::before{
    content: "+91 -";
    position: absolute;
    left: 14px;
    top: 60px;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.6);
    z-index: 9;
}
.inputPhone input{
    padding-left: 55px;
}
.inputPhone label{
    left: 45px;
    line-height: 36px;
}
.inputPhone input:focus~label{
    left: 0;
}
.saveText{
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #0037ff;
    position: absolute;
    top: 63px;
    right: 14px;
    margin: 0;
    z-index: 9;
}
.otpWrap{
    float: left;
    width: 100%;
    padding: 40px 0 16px;
}
.enterOTP{
    font-size: 12px;
    font-weight: 600;
    color: #2e384d;
    letter-spacing: 2px;
    float: left;
}
.sendOTP{
    font-size: 12px;
    font-weight: bold;
    color: #0037ff;
    letter-spacing: 1px;
    float: right;
}
.inputOtp{
    float: left;
    width: 100%;
    padding-bottom: 20px;
}
.inputOtp p{
    border-radius: 8px;
    border: solid 1px rgba(0, 55, 255, 0.2);
    background: #e0e7ff;
    width: 40px;
    float: left;
    margin: 0;
    line-height: 45px;
    font-size: 30px;
    color: rgba(46, 56, 77, 0.7);
    height: 40px;
    text-align: center;
}
.inputOtp span{
    padding: 7px;
    float: left;
}
.inputOtp input{
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #2e384d;
    float: left;
    border-radius: 8px;
    border: solid 1px #0037ff;
    text-align: center;
}
.inputOtp input:focus{
    outline: none;
}
.getQuote{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
}
.getQuote button{
    width: 143px;
    height: 48px;
    display: inline-block;
}
.getQuote p{
    font-size: 14px;
    color: #b0bac9;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 28px;
}
.text, .priceText, .clousrePrice{
    font-size: 12px;
    color: #2e384d;
    letter-spacing: 2px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}
.priceText {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 15px;
}
.clousrePrice {
    padding-top: 30px;
    padding-bottom: 12px;
}
.closeModal {
    background: none;
    border:none;
    outline: none;
    width: 190px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    color: #2e384d;
}
.closeModal:focus {
    outline: none;
}
.greenText, .orengeText, .redText {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    padding-top: 14px;
}
.greenText {
    color: #44ca6f;
}
.orengeText {
    color: #f77000
}
.redText {
    color: #fa0000;
}
.textPai {
    color: #2e384d;
    font-size: 12px;
    margin: 0;
    padding-top: 17px;
}
.textLabel {
    color: #2e384d;
    font-size: 12px;
    margin: 0px;
}
:global .MuiFormControl-marginDense {
    margin-bottom:0 !important;
}
:global .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #003fb8 !important;
}