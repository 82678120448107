.ctaButton {
  display: flex;
  justify-content: space-around;
  margin-top: 17px;
}
.txt {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #2e384d;
  margin: 10px 0 0 0;
  padding-bottom: 8px;
}
.errorText{
  color: red;
  font-size: 12px;
  font-weight: normal;
}
.customCalender{
  display: block;
}
.customCalender :global .react-datepicker{
  color: #2e384d;
  width: 150px;
  font-size: 12px;
}
.customCalender :global .react-datepicker__time-container{
  width: 148px;
}
.customCalender :global .react-datepicker__time, .customCalender :global .react-datepicker__time-box{
  width: 148px !important;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}
.customCalender :global .react-datepicker-time__header{
  font-size: 16px;
}
:global .css-1wa3eu0-placeholder {
  font-size: 14px;
  color:#9aa1b5 !important;
}
:global .datepicker_inputShift__1gC1Q input {
  font-size: 14px;
  color:#9aa1b5 !important;
  padding: 0 10px !important;
}
.rescheduleUrl {
  background-color: transparent;
  color: #007bff;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.rescheduleUrl:focus {
  border: none;
  background-color: transparent;
  outline: none;
}