.groupedButton {
    border-radius: 8px;
    display: flex;
    margin-top: 30px;
}
.groupedButton button {
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 12px;
    color: #2e384d;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: none;
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border: 1px solid #e0e7ff;
    border-left:none;
    cursor: pointer;
}
.groupedButton button:focus {
    outline: none;
}
.groupedButton button:hover, .groupedButton button.active {
    background-color: #0037ff;
    color: #fff;
}
.groupedButton button:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: 1px solid #e0e7ff;
}
.groupedButton button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}