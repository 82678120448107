.wrapper{
    margin-top: 66px;
    .priceWrapper{
        font-weight: 700;
        font-size: 28px;
        color: #FFFFFF;
        display: block;
        background: radial-gradient(93.63% 93.63% at 50.02% 6.37%, #0071BC 0%, #113167 50%, #001B4B 75%, #000035 100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 28px 0 40px 0;
        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            border-bottom: 2px solid #F69B00;
            padding-bottom: 8px;
            margin-bottom: 12px;
        }
        .thankyou {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            opacity: 0.7;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            padding: 4px 8px;
            margin-top: 12px;
        }
    }
    .rejection {
        align-items: center;
        background: #FFFFFF;
        border-radius: 2px;
        padding-top: 40px;
        h5 {
            margin: 28px 0 0 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #465166;
        }
        .congratulations{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.01rem;
            color: #FFFFFF;
        }
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #465166;
            margin: 0;
        }
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #465166;
            position: relative;
            text-align: center;
            display: block;
            margin: 28px 0 0 0;
            padding-top: 16px;
            &::before {
                content: '';
                border-top: 1px solid #DDE5EB;
                position: absolute;
                top: 0;
                left: 10%;
                right: 10%;
            }
        }
    }
}