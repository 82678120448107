.infoWrapper {
    margin: 16px 0 0 0;
}
.infoSection {
    background-color: #FAFAFA;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
}
.infoSection p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #465166;
    padding: 0 0 0 12px;
    margin: 0;
}
.houseimg {
    margin: 30px 0 0 0;
}
.houseimg img {
    display: block;
    margin: auto;
}
.locationMainWrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
}
.locationWrapper {
    padding: 13px 12px;
    display: flex;
    align-items: center;
    .titleText {
        margin-bottom: 10px;
        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #002441;
            padding: 0px 10px 0px 10px;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.01em;
            color: #465166;
        }
    }
    .searchInput {
        margin-left: 19px;
        margin-right: 19px;
        padding: 10px;
        position: relative;
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 8px;
    }
    .searchInput::before {
        content: url('./images/search-icon.svg');
        color: rgba(46, 56, 77, 0.6);
    }
    .searchInput input {
        width: 80%;
        border: none;
        outline: none;
    }
    .searchInput img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 16px;
        top: 0px;
        bottom: 0;
        margin: auto;
        z-index: 99;
        display: flex;
    }
    .searchInput input:focus-within {
        left: 0;
    }
    .locationList {
        margin-bottom: 0;
    }
    .locationList li {
        display: grid;
        border-bottom: 1px solid rgba(216, 216, 216, 0.8);
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #002441;
            align-self: stretch;
        }
    }
    .locationList li:last-child{
        border-bottom: 0 none;
        margin-bottom: 20px;
    }
}
.locationInfo {
    display: flex;
    align-items: flex-start;
    background: rgba(235, 87, 87, 0.05);
    border-radius: 12px;
    padding: 16px 12px;
    margin-top: 16px;
}
.locationInfo img {
    margin-top: 3px;
}
.locationInfo .infoText {
    margin-left: 8px;
}
.locationInfo .infoText p,
.locationInfo .infoText span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #465166;
    margin: 0;
    padding: 0 0 7px 0;
    display: block;
}
.locationInfo .infoText span {
    font-weight: 400;
    padding: 0;
}