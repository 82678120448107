:global .required{
    border: 1px solid red;
}
:global .required:focus {
    box-shadow: 0 0 0 0.2rem red;
}
.dropdown {
    position: relative;
}
.dropdown span {
    position: absolute;
    height: 14px;
    background-color: #fff;
    left: 15px;
    color: #9aa1b5;
    font-size: 13px;
    top: -9px;
}
select option[disabled] {
    color: red;
}