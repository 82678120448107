.checkinWrapper {
    padding: 80px 50px 0;
    height: 100vh;
    .mainImage {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .checkinText {
        font-size: 18px;
        color: #465166;
        font-weight: 500;
        text-align: center;
        padding-top: 20px;
    }
    .subText {
        font-size: 14px;
        color: #465166;
        text-align: center;
    }
    .selfCta {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 46px;
    }
}