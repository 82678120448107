.leadDetails {
    padding: 60px 10px 140px 10px;
    min-height: calc(100vh - 70px);
    overflow: scroll;
}
.heading{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #465166;
}
.bottomText{
    font-size: 14px;
    color: rgba(70,81,102,.7);
}
.bookDetailwrapper {
    padding: 14px 5px 8px 0;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    line-height: 22px;
}
.bookDetailwrapper p {
    margin-bottom: 0;
}
.bookDetailwrapper .bookingDetails {
    h1 {
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    p {
        margin: -10px 0px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7E8594;
    }
}
.question {
    margin-top: 20px;
}