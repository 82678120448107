.heading{
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #2e384d;
    display: block;
    margin: 0;
    padding-bottom: 5px;
    text-align: left;
}
.diyRaPriceButtonWrapper {
    display: flex;
    justify-content:space-around;
    align-items: center;
    padding-bottom: 5px;
    margin-top: 10px;
}  
.diyRaConfirmPrice {
    padding-bottom: 5px;
    border-bottom: solid 1px rgba(0, 55, 255, 0.08);
    p {
        font-size: 14px;
        color: #333;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
    }
}
.ourPrice {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #ed6c0d;
    margin: 0px 10px 20px 0px;
}
.ocbourPrice {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0px 10px 20px 0px;
}
.extraCharges{
    font-size: 12px;
    color: #2e384d;
    padding-left: 11px;
}
.parkAndSellContainer{
    background-color: #F2F5FF;
    padding: 5px 24px;
    margin: 18px -33px 8px 0;
}
.marketPrice {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #979797;
    margin: 0;
    padding: 0;
}
.offerText {
    font-size: 12px;
    color: #2e384d;
    margin: 0;
    padding: 0 0 5px 0;
}
.starRating{
    font-size: 20px;
    font-weight: 600;
    color: #b0bac9;
    letter-spacing: 1px;
    display: block;
    margin: 0;
    padding-bottom: 10px;
}
.runningAt, .suspended{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
}
.runningAt {
    color: #f5ae4d;
}
.redText {
    color: #ff6359;
    font-size: 12px;
    &::before {
        color: #ff6359;
        top: 0px;
    }
}
.suspended {
    color: #ff6359;
    display: flex;
    justify-content: center;
}
.green {
    color: #44ca6f;
    font-weight: bold;
    font-size: 14px;
}
.link{
    color: #0037FF;
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 0px;
    margin-left: 16px;
    cursor: pointer;
}
.narrowPrice {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin: 0;
    color: #2E384D;
}

.runningPrice, .expire {
    display: block;
    position: relative;
    font-size: 24px;
    font-weight: 600;
    color: #2e384d;
    margin: 0;
}
.runningPrice::before, .expire::before{
    content: "₹";
    position: absolute;
    top: 5px;
    color: #2e384d;
    font-size: 14px;
    font-weight: normal;
    margin-left: -16px;
}
.expire {
    display: inline;
    color: #ff6359;
    font-size: 16px;
    margin-left: 20px;
    font-weight: bold;
}
.expire::before{
    color: #ff6359;
    top: 0px;
}
.expireTime {
    font-size: 12px;
    color: #ff6359;
    margin: 15px 0 0 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
.timeText{
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #2e384d;
    position: relative;
    text-align: left;
    padding-left: 30px;
    margin-left: 20px;
}
.timeText::before{
    content: "";
    background: url(./images/timer.svg) no-repeat left top;
    position: absolute;
    top: 5px;
    left: 0px;
    width: 21px;
    height: 24px;

}
.acceptance{
    display: block;
}

/* Unlock C24 Quote Popup Starts here */
.popupWrap h2{
    padding-bottom: 24px;
}
.customerDesc{
    display: block;
    margin: 0;
    padding-bottom: 24px;
    padding-left: 0;
}
.customerDesc li{
    display: inline-block;
    list-style-type: none;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.67);
}
.otpDetail{
    font-size: 14px;
    color: #2e384d;
    width: 225px;
    margin: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e7ff;
}
.inputPhone{
    display: block;
    padding-top: 53px;
    position: relative;
    width: 233px;
}
.inputPhone::before{
    content: "+91 -";
    position: absolute;
    left: 14px;
    top: 60px;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.6);
    z-index: 9;

}
.inputPhone input{
    padding-left: 55px;
}
.inputPhone label{
    left: 45px;
    line-height: 36px;
}
.inputPhone input:focus~label{
    left: 0;
}
.saveText{
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #0037ff;
    position: absolute;
    top: 63px;
    right: 14px;
    margin: 0;
    z-index: 9;
}
.otpWrap{
    float: left;
    width: 100%;
    padding: 40px 0 16px;
}
.enterOTP{
    font-size: 12px;
    font-weight: 600;
    color: #2e384d;
    letter-spacing: 2px;
    float: left;
}
.sendOTP{
    font-size: 12px;
    font-weight: bold;
    color: #0037ff;
    letter-spacing: 1px;
    float: right;
}
.inputOtp{
    float: left;
    width: 100%;
    padding-bottom: 20px;
}
.inputOtp p{
    border-radius: 8px;
    border: solid 1px rgba(0, 55, 255, 0.2);
    background: #e0e7ff;
    width: 40px;
    float: left;
    margin: 0;
    line-height: 45px;
    font-size: 30px;
    color: rgba(46, 56, 77, 0.7);
    height: 40px;
    text-align: center;
}
.inputOtp span{
    padding: 7px;
    float: left;
}
.inputOtp input{
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #2e384d;
    float: left;
    border-radius: 8px;
    border: solid 1px #0037ff;
    text-align: center;
}
.inputOtp input:focus{
    outline: none;
}
.getQuote{
    display: block;
}
.getQuote button{
    width: 143px;
    height: 48px;
    display: inline-block;
}
.getQuote p{
    font-size: 14px;
    color: #b0bac9;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 28px;
}
.timer {
    position: relative;
    padding-left: 30px;
}
.timer::before {
    background-image: url('../images/timer.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 21px;
    height: 24px;
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
}
.timer p{
    font-size: 12px;
    text-transform: uppercase;
    color: #2e384d;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
    text-align: left;
}
.c24Wrapper {
    width: 95%;
    margin-left: 5%;
    .heading {
        text-align: center;
    }
    .listingPrice {
        display: block;
        grid-template-columns: 100%;
        position: relative;
        padding-top: 20px;
        &::after {
            border: none;
        }
        .heading {
            text-align: center;
        }
        .priceTextWrapper {
            display: block;
        }
    }
    button {
        margin: 5px 0;
    }
    .expireTime {
        position: static;
    }
    .cta {
        // width: 100%;
        button {
            // width: 100%;
            float: none;
        }
    }
}
.priceDiff {
    font-size: 12px;
    font-weight: bold;
    border:1px solid #0037ff;
    justify-content: center;
    display: flex;
    width: 130px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.priceDiff Button {
    padding: 0px;
    font-size: 12px;
    height: 24px;
}
.reauction {
    color: #2e384d;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}
.priceTextWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 16px;
}
.c24QuoteTag {
    font-size: 10px;
    width: 70px;
    margin: 0;
    line-height: 12px;
}
.currentHbWrapper p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: 15px;
}
.currentHb {
    display: flex;
    font-size: 14px;
    color: #585858;
}
.currentHb span {
    font-size: 14px;
    color: #05c410;
    display: block;
    text-align: left;
    padding-left: 2px;
}
.tillDate {
    margin-left: -18px
}
.currentHb font {
    position: relative;
    padding-left: 20px;
}
.currentHb font::before {
    content: "₹";
    position: absolute;
    left: 5px;
    top: 0px;
    font-size: 14px;
    z-index: 1;
}
.currentHb .hb_text {
    font-size: 14px;
    width: 150px;
    color: #585858;
    display: inline-flex;
    position: relative;
    margin-right: 10px;
    font-weight: 600;
}
.currentHb .hb_text:after {
    position: absolute;
    right: 0;
    content: ':';
    top: -1px;
}
.acceptedHb p{
    position: relative;
    font-size: 24px;
    font-weight: 600;
    color: #2e384d;
    margin: 0;
}
.acceptedHb p:before{
    content: "₹";
    position: absolute;
    top: 5px;
    color: #2e384d;
    font-size: 14px;
    font-weight: normal;
    margin-left: -16px;
}
.acceptedHb span{
    font-size: 14px;
    margin: 0;
    padding: 0;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.dealerNegoPrice {
    .priceTitles {
        font-size: 14px;
        color: #585858;
        font-weight: 600;
        letter-spacing: 1px;
        margin: 0 0 10px 0;
        padding: 0;
        position: relative;
    }
    .priceButtonWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 14px;
        margin-bottom: 12px;
        border-bottom: solid 1px rgba(0, 55, 255, 0.08);

        .price {
            color: #2e384d;
            font-size: 24px;
            font-weight: 600;
            margin: 0 10px 0 20px;
            padding: 0;
            position: relative;
            &::before {
                content: "₹";
                position: absolute;
                top: 5px;
                color: #2e384d;
                font-size: 14px;
                font-weight: normal;
                margin-left: -16px;
            }
        }
        .narrowPrice {
            // display: block;
            // position: relative;
            font-size: 16px;
            line-height: 22px;
            font-weight: 700;
            margin: 0;
            color: #2E384D;
        }
    }  
    .ocbRuningWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .ocbruning {
            color: #f5ae4d;
            font-size: 12px;
            letter-spacing: 2px;
            margin: 0;
        }
        .ocbPrice {
            position: relative;
            color: #2e384d;
            font-size: 12px;
            font-weight: 600;
            margin: 0 0 0 30px;
            &::before {
                content: "₹";
                position: absolute;
                top: 0px;
                color: #2e384d;
                font-size: 14px;
                font-weight: normal;
                margin-left: -16px;
            }
        }
    } 
    .ocbTimer {
        position: relative;
        font-size: 10px;
        color: #2e384d;
        font-weight: 600;
        letter-spacing: 1.67px;
        padding-left: 25px;
        text-transform: uppercase;
        margin-top: 10px;
        span {padding-right: 5px;}
        &::before { 
            background-image: url('../images/timer.svg');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 80%;
            width: 20px;
            height: 20px;
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
        }
    }
    .expires {
        font-size: 12px;
        color: #ff6359;
        letter-spacing: 0.5px;
        font-weight: 600;
        text-align: center;
        margin: 10px 0 0 0;
        padding: 0;
    }
}
.plllistingPrice {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 20px;
    position: relative;
    padding-top: 20px;
    &::after {
        content: '';
        position: absolute;
        top: 20px;
        left: 51%;
        border-left: 1px solid #979797;
        // height: 50px;
    }
    .redText {
        color: #ff6359;
        font-size: 12px;
        &::before {
            color: #ff6359;
            top: 0px;
        }
    }
    .heading {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #2e384d;
        display: block;
        margin: 0;
        padding-bottom: 5px;
        text-align: left;
    }
}
.pllredText {
    color: #ff6359;
    font-size: 12px;
    &::before {
        color: #ff6359;
        top: 0px;
    }
}
.listingPrice, .listingPriceCongratulations, .ourPriceWrapper {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 20px;
    column-gap: 20px;
    position: relative;
    padding-top: 20px;
    &::after {
        content: '';
        position: absolute;
        top: 20px;
        left: 51%;
        border-left: 1px solid #979797;
        height: 50px;
    }
}
.listingPriceCongratulations {
    &::after {
        content: '';
        border-left: 0px solid #979797;
    }
}
.c24WrapperTest {
    margin-bottom: 30px;
    text-align: left;
    width: 90%;
    .reauction {
        width: 50%;
        text-align: left;
        float: left;
    }
    .dealerNegoPrice {
        width: 50%;
        float: left;
    }
    .priceButtonWrapper, .ocbRuningWrapper {
        flex-wrap: wrap;
        justify-content: start;
    }
    .ocbPrice {
        margin: 0 0 0 17px !important;
    }
    .expires {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .priceTextWrapper {
        flex-wrap: wrap;
        justify-content: start;
    }
    .c24QuoteTag {
        width: 100%;
    }
    .suspended {
        justify-content: start;
    }
    .redText {
        color: #ff6359;
        font-size: 12px;
        &::before {
            color: #ff6359;
            top: 0px;
        }
    }
    > .cta {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    .priceAcceptedFlag {
        margin: 22px -33px 5px 0;
    }
}
.starCta {
    position: absolute;
    bottom: -16px;
    right: -16px;
    width: 40px;
    height: 40px;
    background-color: #f4f6fc;
    border: 1px solid #ebefff;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 20px;
    button, .active {
        width: 16px;
        height: 16px;
        background-color: #f4f6fc;
        background-image: url('../../components/images/starnew.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        border:none;
        outline: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        &.active {
            background-image: url('../../components/images/starnewActive.svg');
        }
        &:focus {
            outline: none;
            border: none;
        }
    }
}
.backout {
    background-color: #000;
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: -34px;
    top: 0px;
    padding: 0 5px 0 12px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0; 
        height: 0; 
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 9px solid #fff;
    }
}
.cashbackBank {
    color: #00ba67;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    .priceText {
        margin: 0;
        padding-bottom: 5px;
        text-align: center;
    }
}
.clear {
    clear:both
}
.priceAcceptedFlag{
    margin: 22px -14px 5px 0;
    display: flex;
    justify-content: flex-end;
    h6{
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: #0059A3;
        margin: 0;
        background-color: rgba(0, 89, 163, 0.19);
        border: 1px solid #0059A3;
        border-right: none;
        box-sizing: border-box;
        height: 26px;
        display: flex;
        align-items: center;
        padding: 0 10px 0 30px;
        border-bottom-left-radius: 24px;
        border-top-left-radius: 24px;
        position: relative;
        &::before {
            content: '';
            background: linear-gradient(180deg, #205C8C 0%, #3D89BA 100%);
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 3px;
            border-radius: 100%;
            bottom: 0;
            margin: auto;
        }
        &::after {
            content: '';
            background-image: url('../../components/images/whiteRightSign.svg');
            background-repeat: no-repeat;
            width: 11px;
            height: 9px;
            position: absolute;
            top: 0;
            left: 8px;
            bottom: 0;
            margin: auto;
        }
    }
}
.congratulationsFlag {
    background-image: linear-gradient(to left, #f7b500, #ffe8aa);
    min-height: 60px;
    position: relative;
    margin: -14px -33px 0px 0;
    border-top-right-radius: 5px;
    padding-left: 70px;
    padding-right: 10px;

    &::before {
        content: '';
        background-image: url('../images/gift.svg');
        width: 41px;
        height: 45px;
        position: absolute;
        top: 0;
        left: 16px;
        bottom: 0;
        margin: auto;
    }
    h6 {
        opacity: 0.8;
        font-size: 12px;
        font-weight: 600;
        color: #2e384d;
        margin: 0;
        padding-top: 10px;
        font-style: italic;
        text-align: left;
    }
    h4 {
        font-size: 14px;
        font-weight: bold;
        color: #2e384d;
        margin: 10px 0 0 0;
        padding: 0 0 10px 0;
        line-height: 18px;
        font-style: italic;
        text-align: left;
    }
}
.cta {
    width: 45%;
    display: inline-flex;
    button {
        width: 100%;
        float: none;
    }
}
.orangeTag, .reInspectionPending{
    font-weight: bold;
    font-size: 12px;
    color: #efa441;
    letter-spacing: 0.9px;
}
.reInspectionDone{
    font-weight: bold;
    font-size: 12px;
    color: #00ba67;
    letter-spacing: 0.9px;
}
.qcReview, .qcRejected  {
    margin: 20px 0 0 0;
    padding: 0px 0 0 25px;
    font-size: 14px;
    color: #00ba67;
    position: relative;
    &::before {
        content: '';
        background-image: url('../../components/images/clockGreen.svg');
        background-repeat: no-repeat;
        position: absolute;
        top: 7px;
        left: 0px;
        bottom: 0;
        width: 20px;
        height: 20px;
        background-size: 80%;
        margin: auto;
    }
}
.qcRejected {
    color: #e02020;
    &::before {
        content: '';
        background-image: url('../../components/images/qcRejected.svg');
    }
}
.greatOffers {
    width: 110%;
    min-height: 54px;
    margin-top: -14px;
    margin-bottom: -18px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(197,213,254,1) 100%);
    border-top-right-radius: 15px;
    position: relative;
    padding-left: 74px;
    &::before {
        content: '';
        background-image: url('../../components/images/superAuction24qute.svg');
        background-repeat: no-repeat;
        width: 54px;
        height: 48px;
        position: absolute;
        left: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    h3 {
        margin: 0;
        padding: 10px 0 0 0;
        font-size: 14px;
        font-weight: bold;
        font-style: italic;
        color: #2e384d;

    }
    p {
        margin: 0;
        padding: 5px 0 0 0;
        color: #ed6c0d;
        font-size: 12px;
        span {
            font-weight: bold;
        }
    }
}
.highConversionChanceWrapper {
    position: relative;
    margin: 20px 0 -15px 0;
    height: 26px;
    .highConversionChance {
        border-right: 1px solid #09c162;
        position: absolute;
        right: -36px;
        cursor: pointer;
        padding-left: 35px;
        height: 26px;
        width: 35px;
        transition: width 0.1s;
        overflow: hidden;
        &::before {
            content: '';
            background-image: url('../../components/images/highRectangle.svg');
            background-position: 0 0;
            background-repeat: no-repeat;
            height: 26px;
            width: 34px;
            position: absolute;
            left: 0;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 95%;
            right: 0;
        }
        &:hover {
            width: 225px;
            right: -45px;
            &::before {
                content: '';
                width: 225px;
                position: absolute;
                left: 0px;
                right: auto;
            }
        }
        h6 {
            font-weight: bold;
            font-size: 12px;
            color: #219F28;
            margin: 0;
            padding: 0;
            background-color: #d7ecd6;
            border-top: 1px solid #09c162;
            border-bottom: 1px solid #09c162;
            height: 26px;
            width: 100%;
            line-height: 26px;
        }
    }

}
.reInspectionPending, .reInspectionDone {
    position: relative;
    padding-left: 25px;
    margin-bottom: -5px;
    &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
    }
}
.reInspectionPending {
    &::before {
        content: '';
        background-image: url('../../components/images/rightOrangeRounded.svg');
    }
}
.reInspectionDone {
    &::before {
        content: '';
        background-image: url('../../components/images/rightGreenRounded.svg');
    }
}

.priceUpdateText {
    font-size: 12px;
    line-height: 15px;
    color: #2E384D;
    mix-blend-mode: normal;
    position: relative;
    padding-left: 25px;
    margin-top: 10px;
    &::before {
        content: '';
        background-image: url('../images/downloadIcon.svg');
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.qcTextOrange, .qcTextRed, .qcTextGreen, .qcInfo {
    font-size: 12px;
    line-height: 15px;
    color: #F5AE4D;
    mix-blend-mode: normal;
    margin-top: 11px;
    font-weight: 500;
}
.qcTextRed {
    color: #DE350B;
}
.qcTextGreen {
    color: #3FCB5E;
}
.qcInfo {
    position: relative;
    span {
        position: relative;
        color: #DE350B;
        padding-right: 25px;
        cursor: pointer;
        &::after {
            content: '';
            background-image: url('../images/infoIcons.svg');
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            right: 0;
        }
        p {
            margin: 0;
            padding: 10px;
            background-color: #000;
            color: #fff;
            font-size: 12px;
            line-height: 21px;
            color: #F6F6F6;
            width: 185px;
            height: 66px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            display: none;
            position: absolute;
            top: -25px;
            right: -200px;
            &::before {
                content: '';
                width: 0;
                height: 0;
                border-top: 14px solid transparent;
                border-bottom: 14px solid transparent;
                border-right: 14px solid #000;
                position: absolute;
                top: 20px;
                left: -14px;
            }
        }
    }
}
.bidRejected {
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #D55045;
    position: relative;
    padding-left: 30px;
    margin-bottom: 60px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        background-image: url('../../components/images/cross.svg');
    }
}
.qcInfo span:hover p {
    display: block;
}
.trojanRejected {
    display: flex;
    justify-content: flex-end;
    margin-right: -15px;
    h6 {
        position: relative;
        background-color: #FF0000;
        height: 45px;
        color: #FFFFFF;
        align-items: center;
        display: flex;
        padding: 0 0px 0 40px;
        font-size: 14px;
        width: 100%;
        &::before {
            content: '';
            position: absolute;
            top: -4px;
            left: 5px;
            bottom: 0;
            margin: auto;
            background-image: url('../../components/images//noun_trojan.png');
            background-size: 100%;
            width: 28px;
            height: 34px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            border-right: 20px solid #fff;
            right: 0;
        }
    }
}
:global .blankComponentBoxLeft, :global .blankComponentBoxRight {
    width: 50%;
    float: left;
    height: 40px;
}
:global .blankComponentBoxRight {
    float: right;
}
.clearboth {
    clear: both;
}
.ourPriceWrapper {
    margin-top: 26px;
}
.ocbourPriceWrapper {
    margin-top: 5px;
	&::after {
        content: '';
        position: absolute;
        top: 60px;
        left: 51%;
        border-left: 1px solid #979797;
        height: 50px;
    }
}
.sellOnlinePriceView {
    margin-top: 26px;
    display: grid;
    grid-template-columns: 50% 50%;
    .previousPriceSection {
        border-left: 1px solid #3C5670;
        padding-left: 20px;
        .priceTextWrapper {
            .runningPrice {
                color: #929292;
                font-size: 16px;
                &::before {
                    color: #929292;
                    top: 0;
                }
            }
        }
    }
}
.riStautsText {
    p {
        font-size: 14px;
        color: #333;
        margin: 0;
        padding: 0;
        span {
            color: #929292;
            padding-right: 10px;
        }
    }
    img {cursor: pointer;}
}
.confirmPrice {
    p {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }
    h6{
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: #0059A3;
    }
}
.dateTime h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #333;
    line-height: 26px;
    text-align: left;
}
.scrapCar {
    font-size: 14px;
    color: #ff6359;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 500;
}
.diyC24 {
    color: #ED6C0D;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #ed6c0d;
    margin: 0;
    padding-bottom: 10px;
}
.c24QuoteTagPrice {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #2E384D;
}
.diylistingPrice {
    text-align: center;
    margin-top: 10px;
}

.qrCode{
  position: absolute;
  top: 30px;
  left: -60px;
}

.seller_intend{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.seller_intend_c24Quote{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  color: #585858;
}

