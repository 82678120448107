.offerPopupWrapper{
    min-width: 700px;
    .offerTable {
        font-size: 12px;
        height: 500px;
        overflow: auto;
        table {
            width: 100%;
            border-collapse: unset;
            table-layout: fixed;
            thead {
                tr {
                    th {
                        background-color: #f4f6fc;
                        height: 40px;
                        padding: 0 10px;
                        font-size: 12px;
                        color: #2e384d;
                        letter-spacing: 2px;
                        font-weight: 600;
                        text-transform: uppercase;
                        z-index: 2;
                        margin: 0;
                        position: sticky;
                        top: 0px;
                        &:nth-child(3){
                            width: 250px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        height: 40px;
                        padding: 5px 10px;
                        border-bottom: 1px solid #f4f6fc;
                    }
                }
            }
        }
    }
}