.txt {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #2e384d;
  margin: 10px 0 0 0;
  padding-bottom: 8px;
}
.errorText{
  color: red;
  font-size: 12px;
  font-weight: normal;
}
.ctaButton {
  display: flex;
  /* justify-content: space-around; */
  margin-top: 17px;
  position: relative
}
.fixWrapper::before {
  content: '';
  position: absolute;
  background-color: #fff;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
:global body.ReactModal__Body--open {
  overflow: hidden !important;
}
.quoteNew {
  height: 100px;
}
.inputWrap{
  position: relative;
}
.inputWrap label {
  padding-left: 10px !important;
}
.inputWrap::before {
  content: "₹";
  position: absolute;
  left: 10px;
  top: 7px;
  font-size: 18px;
  color: rgba(46, 56, 77, 0.6);
  z-index: 1;
}
.inputWrap input{
  padding-left: 25px;
}

.inputWrap :global .MuiOutlinedInput-root:hover 
:global .MuiOutlinedInput-notchedOutline {
  border-color:  #0037ff !important;
}
.radioButton {
  display: flex;
}
.radioButton :global .radio-button_checkboxHolder__3wKE_ {
  margin-right: 20px;
}
.offersPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.offersPrice p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #465166;
}
.offersPrice span {
  font-size: 14px;
  font-weight: 700;
  color: #0037FF;
}
.call {
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #0037FF;
  position: relative;
  padding-left: 30px;
}
.call::before {
  content: '';
  background-image: url('../../components/images/nego_phone.svg');
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
}
.bikeFollowWrapper {
  min-width: 450px;
}
.aapid {
  cursor: pointer;
  text-decoration: underline;
  color: hsl(227, 100%, 67%);
}
.aapid:hover {
  color: #0037FF;
}