.downpaymentWrapper{
    /* margin-bottom: 50px; */
    margin-bottom: 30px;
}
.amountSign{
    position: relative;
}
.headerWrapper{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.amountSign span{
    font-size: 16px;
    line-height: 21px;
    color: #465166;
    opacity: 0.5;
    position: absolute;
    left: 16px;
    top: 11px;
}
.amountSign input{
    background: #FFFFFF;
    border: 1px solid #DDE5EB;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
    width: 118px;
    padding: 10px 16px 8px 16px;
    font-size: 16px;
    line-height: 21px;
    padding-left: 30px;
}