.rescheduleAppointmentPopup {
    width: 1000px;
    min-height: 300px;
    .heading {
        text-align: center;
    }
    .title {
        margin: 0;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #2e384d;
    }
    .storesWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        max-height: 120px;
        overflow: auto;
        .store {
            padding: 15px 10px;
            border-radius: 10px;
            border: solid 1px #e0e7ff;
            width: 300px;
            margin-right: 10px;
            margin-bottom: 10px;
            &:hover, &.active {
                border: solid 1px #0037ff;
                background-color: #f3f6fb;
            }
            h3 {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 600;
                color: #465166;
            }
            p {
                opacity: 0.7;
                font-size: 12px;
                color: #465166;
                margin: 0;
                padding: 0;
            }
        }
    }
    .dateWrapper {
        display: flex;
        margin-top: 15px;
        flex-wrap: wrap;
        max-height: 150px;
        overflow: auto;
        .date {
            padding: 16px;
            border-radius: 10px;
            border: solid 1px #e0e7ff;
            text-align: center;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 100px;
            &:hover, &.active {
                border: solid 1px #0037ff;
                background-color: #f3f6fb;
            }
            h3 {
                font-size: 14px;
                font-weight: normal;
                color: #2e384d;
            }
            p {
                font-size: 18px;
                font-weight: 600;
                color: #2e384d;
                margin: 0;
                padding: 0;
            }
        }
    }
    .timeWrapper {
        display: flex;
        margin-top: 15px;
        flex-wrap: wrap;
        max-height: 100px;
        overflow: auto;
        .time {
            padding: 5px 16px;
            border-radius: 10px;
            border: solid 1px #e0e7ff;
            text-align: center;
            margin-right: 10px;
            margin-bottom: 10px;
            &:hover, &.active {
                border: solid 1px #0037ff;
                background-color: #f3f6fb;
            }
            h3 {
                font-size: 14px;
                font-weight: 600;
                color: #2e384d;
                margin: 0;
                padding: 0;
            }
        }
    }
    .ctaWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
}
.inputWrap {
    margin-top: 30px;
    width: 50%;
}
