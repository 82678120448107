.lostLeadpopup {
    min-width: 450px;
    min-height: 400px;
    .list {
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            li {
                font-size: 12px;
                letter-spacing: .5px;
                color: #2e384d;
                margin: 0;
                padding-bottom: 8px;
                position: relative;
                list-style: none;
                padding-right: 20px;
                &::after{
                    position: absolute;
                    right: 10px;
                    top: 0;
                    content: "|";
                }
                &:last-child::after {
                    content: "";
                }
            }
        }
    }
    .reason {
        font-size: 12px;
        color: #2e384d;
        letter-spacing: 1px;
        font-weight: 600;
        margin-top: 20px;
    }
    .text {
        font-size: 12px;
        color: #2e384d;
        padding-top: 10px;
        margin-bottom:10px;
    }
    .inspecList {
        display: flex;
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            letter-spacing: 1px;
            border: 1px solid rgba(0,55,255,.1);
            height: 32px;
            text-align: center;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            padding: 0 10px;
            cursor: pointer;
            position: relative;
            color: #2e384d;
            font-size: 12px;
            font-weight: 600;
            &:hover {
                color: #0037ff;
                border: 1px solid #0037ff!important;
            }
        }
    }
    .textara {
        margin-bottom: -10px;
    }
    .submitCta {
        display: flex;
        margin: 50px 0 20px;
        position: absolute;
        bottom: 20px;
        left: 50px;
        right: 50px;
    }
    .checkboxWrapper{
        border-radius: 4.5px;
        box-shadow: 0 2px 4px 0 rgba(0, 55, 255, 0.1);
        background-color: #ffffff;
        margin: 14px 0;
        padding: 0px 17px;
        height: 40px;
    }
    .checkboxWrapper:hover{box-shadow: 0 1px 3px 0 #0037ff;}
    .radioSction{
        display: flex;
        justify-content: space-between;
        .reasonWapper {
            display: flex;
            align-items: center;
            p{
                margin:0;
                padding: 0;
            }
        }
    }
}
.lostLeadpopup :global .css-yk16xz-control, .lostLeadpopup :global .css-g1d714-ValueContainer {
    min-height: 40px;
    height: 100% !important;
    overflow: auto !important;
}
.lostLeadpopup :global .react-select__menu .css-26l3qy-menu {
    height: 200px !important;
}
.lostLeadpopup :global .react-select__menu-list {
    height: 200px !important;
    overflow: scroll !important;
}