.leadActivityFeed {
    padding-top: 30px;
    width: 50%;
}
.fixedWrap {
    overflow: auto;
}
.scrollwrapper {
    /* display: flex; */
    position: fixed;
    top: 260px;
    right: 40px;
    bottom: 0;
    width: 42%;
    height: auto;
    margin: 20px 0 10px 0px;
    overflow: auto;
}