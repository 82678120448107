.detailsPage {
    padding: 80px 40px 0; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .headingWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .backHeadingWrapper {
            display: flex;
            align-items: center;
            flex: 1;
            h2 {
                padding-left: 10px;
                margin: 0;
            }
        }
        
    }
    .backTitle {
        font-size: 12px;
        font-weight: 600;
        color: #2e384d;
        letter-spacing: 1px;
        padding-left:17px;
        margin: 36px 0 23px 0;
        position: relative;
        cursor: pointer;
        &::before {
            content: '';
            background-image: url("../../components/images/arrow.svg");
            background-position: 0 0;
            background-repeat: no-repeat;
            width: 11px;
            height: 6px;
            position: absolute;
            left: -2px;
            top: 5px; 
            transform: rotate(90deg)
        }
    }
    .LeadDetail {
        display: flex;
        position: fixed;
        top: 130px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 94%;
        height: auto;
        margin: 30px auto;
    }
}
