.initiatePaymentPopup {
    .customerDesc {
        margin: 10px 0 0 0;
        padding: 0;
        li {
            display: inline-block;
            list-style-type: none;
            font-size: 14px;
            color: rgba(46, 56, 77, 0.67);
            &::after {
                content: "|";
                margin: 0 5px;
            }
        }
    }
    .paymentOption {
        margin-top: 40px;
        label {
            font-size: 14px;
            color: #465166;
        }
        .checkboxHolder{
            margin: 25px 0;
        }
    }
    .ctaWrapper {
        margin: 50px 0 0 0;
    }
}