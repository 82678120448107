.ccConfirmLeads {
    margin: 7px 40px 0;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            font-size: 18px;
            letter-spacing: 1.5px;
            color: #2e384d;
            text-transform: uppercase;
            margin-right: 30px;
            padding: 0 10px;
            height: 50px;
            display: flex;
            align-items: center;
            &.active {
                font-weight: bold;
                border-bottom: 4px solid #0037ff;
                background-color: #e0e7ff;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }
        }
    }
}