.viewccremarkswrap {
	display: block;
	margin: 0 auto;
	min-width: 700px;
	max-height: 80vh;
	h2 {
		padding-bottom: 24px;
		margin: 0;
	}
	.infowrapper {
		display: flex;
        justify-content: space-between;
        padding-top: 32px;
		margin-bottom: 30px;
	}
	.remarkswrapper {
		.title {
			padding: 20px;
			margin-left: 100px;
			font-size: large;
			font-weight: 600;
		}
		.callLogCard {
			border-radius: 8px;
			border: solid 1px #e0e7ff;
			background-color: #ffffff;
			min-height: 100px;
			margin-bottom: 12px;
			display: flex;
			flex-direction: row;
			padding: 5px;
			text-align: left;
			.datetimeinfo {
				width: 15%;
				min-width: 15%;
				text-align: center;
				border-right: solid 1px #e0e7ff;
			}
			.remarksInfo {
				margin-left: 10px;
			}
		}
	}
}