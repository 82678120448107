.carDetailsPopup {
    min-width: 740px;
    .carHeader {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
        .carName {
            span {
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #465166;
                display: block;
                padding-bottom: 8px;
            }
        }
        .priceSection {
            span {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: #ED6C0D;
                display: block;
            }
            h4 {
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                color: #2E384D;
                margin: 10px 0 0 0;
            }
        }
    
    }
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #2E384D;
        margin: 40px 0 20px 0;
    }
    .overflowWrapper {
        height: 600px;
        overflow: auto;
    }
    .gridWrapper {
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 10px;
    }
    .dataGridWapper {
        .dataGrid {
            margin-bottom: 10px;
            list-style: none;
            border: 1px solid #D8D8D8;
            border-radius: 8px;
            padding: 16px;
            .heading {
                display: flex;
                justify-content: space-between;
                padding-bottom: 16px;
                border-bottom: 1px dashed #c5cfd8;
                span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: 0.01em;
                    color: #465166;
                }
                h4 {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: 0.01em;
                    color: #465166;
                    margin: 0;
                    &.red {
                        color: #EB5757;
                    }
                    &.green {
                        color: #00BA67;
                    }
                }
    
            }
            .onRoadPriceStrip {
                background-color: #F1F8FF;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                padding: 12px 8px;
                margin: 16px 0;
                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.01em;
                    color: #465166;
                }
            }
            .text {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.01em;
                margin: 10px;
            }
            .list {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    padding-top: 16px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.01em;
                    color: #465166;
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    
}
