.negotiationPagewrapper {
    margin-top: 60px;
    padding-top: 10px;
    overflow:hidden;
}
.negoFllowup {
    display: flex;
    min-height: 450px;
}
.historypop {
    width: 750px;
}
.loadmore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}