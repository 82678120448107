.lostLeadWrapper {
    padding: 0px 40px 0 40px;
    background-color: #f4f6fc;
    .lostHeading {
      background-color: #f4f6fc;
        position: fixed;
        top: 0px;
        z-index: 1;
        left: 20px;
        right: 0;
        padding-top: 85px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        h2 {margin: 0; padding-left: 10px;}
        .pageDirection {
          font-size: 10px;
          color: #465166;
          margin: 0;
          padding-left: 5px;
          text-transform: capitalize;
        }
    }
    table {
        border-spacing: 0 8px;
        border-collapse: unset;
        position: relative;
        margin-top: 117px;
        width: 100%;
        thead {
          tr {
            &::after {
              content: "";
              box-shadow: 0px 17px 15px -16px rgba(0, 55, 255, 0.49);
              position: fixed;
              left: -40px;
              right: -40px;
              height: 40px;
              z-index: 1;
              background-color: #f4f6fc;
            }
          }
        }
        tr {
            th {
                background-color: #f4f6fc;
                height: 40px;
                font-size: 12px;
                color: #2e384d;
                letter-spacing: 2px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: default;
                padding: 0 10px 0 0px;
                z-index: 2;
                margin: 0;
                position: sticky;
                top: 125px;
                min-width: 20ch;
                p {
                  margin: 0;
                }
              &:first-child {
                padding-left:20px
              }
            }
            td {
                color: #2e384d;
                font-size: 12px;
                background: #fff;
                height: 48px;
                border: 0;
                letter-spacing: 0.5px;
                padding: 0 10px 0 0px;
                border: solid 1px transparent;
                box-shadow: none;
                white-space: nowrap;		
                max-width: 20ch;		
                overflow: hidden;		
                text-overflow: ellipsis;
                &:first-child{
                  padding-left: 20px;
                  border-top-left-radius: 6px;
                  border-bottom-left-radius: 6px;
                  position: relative;
                }
                &:last-child {
                  border-top-right-radius: 6px;
                  border-bottom-right-radius: 6px;
                }
              }
            &:hover {
              box-shadow: 0px 4px 8px 0 rgba(0, 55, 255, 0.1);
              td:first-child {
                border-left: solid 1px #0037ff;
              } 
              td:last-child {
                border-right: solid 1px #0037ff;
              }
              td {
                border-bottom: solid 1px #0037ff;
                border-top: solid 1px #0037ff;
              }
            }
            &:hover:first-child {
              box-shadow: none;
            }  
            :global .follow-up_editPopup__36kS3 a span {
              max-width: 100%;
              display: block;
            }
        }
    }
}

.fixed{
  position: fixed;
  top: 80px;
  left: 300px;
  z-index: 9;
}