.ctaButton {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
  .errorText{
    color: red;
    font-size: 12px;
    font-weight: normal;
  }
  .staticText {
    font-size: 14px;
    color: #2e384d;
    letter-spacing: 1px;
    font-weight: 600;
  }

.checkboxWrapper{
  border-radius: 4.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 55, 255, 0.1);
  background-color: #ffffff;
  margin: 14px 0;
  padding: 0px 17px;
  height: 40px;
}
.checkboxWrapper:hover{box-shadow: 0 1px 3px 0 #0037ff;}