.sideBarExpend {
    width: 225px !important;
}
.sideBar {
    width: 104px;
    top: 60px;
    left: 0;
    position: fixed;
    height: 100%;
    background-color: #003fb8;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    z-index: 5;
}
.logo {
    text-align: center;
    margin-top:17px;
    height: 68px;
}
.logo span.logoImage {
    display: block;
    margin: auto;
    width: 44px;
}
.logo img {
    width: 100%;
}
.logo p {
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 8px;
    margin-bottom: 0px;
}
.logo small {
    display: none;
    color: #fff;
    font-size: 8px;
    letter-spacing: 3px;
    text-transform: uppercase;
    
}
.sideBarExpend .logo span.logoImage {
    float: left;
    margin-right: 6px;
}
.sideBarExpend .logo {
    text-align: left;
    padding: 0 0 0 16px;
    overflow: hidden;
}
.sideBarExpend .logo img {
    width: 100%;
}
.sideBarExpend .logo small {
    display: block;
}
.todoList{
    margin-top: 68px;
}
.todoList .listTitle {
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 7px;
    font-weight: bold;
    padding-left: 15px;
}
.todoList ul {
    margin: 0;
    padding: 0;
}
.todoList ul li {
    list-style: none;
    border-bottom: 1px solid #003ea8;
}
.todoList ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    position: relative;
    padding-left: 60px;
}
.todoList ul li a i {
    background-color: #ff6359;
    border-radius: 100%;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    position: absolute;
    top:2px;
    left: 30px;
    min-width: 25px;
    min-height: 25px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.todoList ul li a span {
    display: block;
    height: 64px;
    line-height: 64px;
    margin-right: 0px;
    color: #fff;
    font-size: 14px;
    width: 50px;
}
.todoList ul li a p {
    color: #fff;
    text-decoration: none;
    margin: 0;
    position: relative;
    display: none;
    font-size: 14px;
}
.sideBarExpend .todoList ul li a p {
    display: block;
}
.todoList ul li.active, .todoList ul li:hover {
    background: #f4f6fc;
}

.todoList ul li.active a span,
.todoList ul li:hover a span,
.todoList ul li.active a p,
.todoList ul li:hover a p {
    color:#0037ff;
}
.planningIcon::before, .sellOnlineIcon::before, .scheduledIcon::before, .visitedIcon::before, .inspectedIcon::before, .negotiationIcon::before, .paymentProcessingIcon::before, .clearancePendingIcon::before, .diyIcon::before {
    content: '';
    position: absolute;
    left: 16px;
    width: 28px;
    background-repeat: no-repeat;
    background-position: 0 0;
}
.planningIcon::before {
    background-image: url('../images/planning.svg');
    height: 24px;
}
.todoList ul li.active .planningIcon::before, .todoList ul li:hover .planningIcon::before{
    background-image: url('../images/planningActive.svg');
}
.sellOnlineIcon::before {
    background-image: url('../images/scheduled.svg');
    height: 27px;
}
.todoList ul li.active .sellOnlineIcon::before, .todoList ul li:hover .sellOnlineIcon::before{
    background-image: url('../images/scheduled_active.svg');
}
.scheduledIcon::before {
    background-image: url('../images/scheduled.svg');
    height: 27px;
}
.todoList ul li.active .scheduledIcon::before, .todoList ul li:hover .scheduledIcon::before{
    background-image: url('../images/scheduled_active.svg');
}
.todoList ul li .visitedIcon::before{
    background-image: url('../images/visited.svg');
    height: 33px;
}
.todoList ul li.active .visitedIcon::before, .todoList ul li:hover .visitedIcon::before {
    background-image: url('../images/visited_active.svg');
}
.todoList ul li .inspectedIcon::before{
    background-image: url('../images/inspected.svg');
    height: 28px;
}
.todoList ul li.active .inspectedIcon::before, .todoList ul li:hover .inspectedIcon::before {
    background-image: url('../images/inspected_active.svg');
}
.todoList ul li .negotiationIcon::before{
    background-image: url('../images/negotiation.svg');
    height: 21px;
}
.todoList ul li.active .negotiationIcon::before, .todoList ul li:hover .negotiationIcon::before {
    background-image: url('../images/negotiation_active.svg');
}
.todoList ul li .paymentProcessingIcon::before{
    background-image: url('../images/payment.svg');
    height: 25px;
}
.todoList ul li.active .paymentProcessingIcon::before, .todoList ul li:hover .paymentProcessingIcon::before {
    background-image: url('../images/payment_active.svg');
}
.todoList ul li .clearancePendingIcon::before{
    background-image: url('../images/clearance.svg');
    width: 30px;
    height: 30px;
}
.todoList ul li.active .clearancePendingIcon::before, .todoList ul li:hover .clearancePendingIcon::before {
    background-image: url('../images/clearance_active.svg');
}
.todoList ul li .diyIcon::before{
    background-image: url('../images/diySelect.svg');
    width: 32px;
    height: 38px;
}
.todoList ul li.active .diyIcon::before, .todoList ul li:hover .diyIcon::before {
    background-image: url('../images/diyNonSelect.svg');
}
.sideBarExpend .toggleArrow{
    transform:rotate(90deg);
}
.toggleArrow {
    width: 24px;
    height: 24px;
    box-shadow: 0 5px 10px 0 rgba(0, 55, 255, 0.1);
    background-color: #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform:rotate(-90deg);
    position: absolute;
    top: 90px;
    right: -11px;
    cursor: pointer;
    transition: all 0.2s;
}
.sideBarExpend .submenuList {
    display: block;
}
.submenuList {
    display: none;
    margin-left: 50px !important;
    margin-bottom: 10px !important;
}

.submenuList li {
    border-bottom: none !important;
    position: relative;
}
.submenuList li::before {
    content: '';
    border-left: 1px solid #c1c1c1;
    position: absolute;
    left: 40px;
    top: 0px;
    bottom: 0;
    height: 30px;
    margin: auto;
}
.submenuList li a {
    font-size: 12px !important;
    height: 30px !important;
    color: #333;
    position: relative;
}
.submenuList li a.active {
    color: #0037ff;
}
.submenuList li a:hover::before, .submenuList li a.active::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 40px;
    height: 20px;
    border-left: 1px solid #0037ff;
}
.todoList ul li:hover .submenuList li a {
    color: #2e384d;
}
.todoList ul li:hover .submenuList li a:hover {
    color: #0037ff;
}