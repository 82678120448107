.leadPayment {
    // padding-top: 28px;
    // border-bottom:  1px solid #e0e7ff;
    // padding-bottom: 38px;

    margin-top: 15px;
    border-radius: 8px;
    border: 1px solid #e0e7ff;
    padding: 15px 0 38px 30px;
    background-color: #fff;
}
.textWrapper {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    padding-top: 32px;
}
.detailsLeftWrapper {
    flex: 0.6;
}
.detailsRightWrapper {
    flex: 0.4;
}
.fieldWrapper {
    display: flex;
}

.overText {
    display: flex;
    align-items: center;
}
.overText span {
    white-space: nowrap;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.overText h5 {
    font-weight: normal;
    font-size: 14px;
    color: #0037ff;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
    padding-left: 10px;
}
.responseList ul li span h5 {
    color: #0037ff;
    text-decoration: underline;
    cursor: pointer;
}
.responseList ul li span {
    position: relative;
}
.toolTipWrapper {
    position: absolute;
    bottom: 30px;
    background-color: #333;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    left: 32px;
    display: none;
}
.toolTip {
    width: 200px;
    min-height: 50px;
    max-height: 130px;
    overflow: auto;
    font-size: 12px;
}
.toolTipWrapper::after {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #333;
    position: absolute;
    bottom: -15px;
    right: 15px;
} 
.toolTipTextWrapper {
    position: relative;
}   
.toolTipTextWrapper:hover .toolTipWrapper {
    display: block;
}