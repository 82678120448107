.currentHubWrap{
    display: block;
    margin: 0 auto;
    max-width: 255px;
    h2{
        padding-bottom: 24px;
        margin: 0;
    }
    .customerDesc{
        display: block;
        margin: 0;
        padding-bottom: 24px;
        padding-left: 0;

        li{
            display: inline-block;
            list-style-type: none;
            font-size: 14px;
            color: rgba(46, 56, 77, 0.67);
            &::after {
                content: "|";
                margin: 0 5px;
            }
            &:last-child::after {
                content: "";
            }
        }
    }
    .currentHub {
        h3 {
            font-size: 12px;
            color: #2e384d;
            letter-spacing: 2px;
            font-weight: 600;
            margin: 0;
            text-transform: uppercase;
        }
        h3.price {
            font-size: 16px;
            letter-spacing: 1px;
            font-weight: 700;
            padding-top: 8px;
            padding-bottom: 15px;
        }
    }
    .currentHubButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 28px;
        button{
            width: 143px;
            height: 48px;
            display: inline-block;
        }
        p{
            font-size: 14px;
            color: #b0bac9;
            letter-spacing: 1px;
            display: inline-block;
            padding: 0 28px;
        }
    }
    .closeModal {
        background: none;
        border:none;
        outline: none;
        width: 190px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        color: #2e384d;
    }
    .closeModal:focus {
        outline: none;
    }
    .infoText {
        font-size: 12px;
        color: #212529;
        margin: 0;
        padding-top: 5px;
    }
    .error {
        color: #c80039;
        font-size: 12px;
        margin: 0;
        padding: 0;
    }
    .totalRequest {
        color: #2e384d;
        font-size: 12px;
        margin: 0;
        padding-top: 17px;
    }
	.directOCBInfo {
		margin-top: 10px;
		background-color: #FFF5E5;
		display: flex;
		justify-content: space-between;
		border-radius: 8px;
		img {
			padding: 10px;
		}
		p {
			padding: 10px;
		}
	}
}

.greenText, .orengeText, .redText,.yellowText{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    padding-top: 14px;
}
.greenText {
    color: #44ca6f;
}
.yellowText{
  color: yellowgreen;
}
.orengeText {
    color: orange;
}
.redText {
    color: red;
}
.text, .priceText, .clousrePrice{
    font-size: 12px;
    color: #2e384d;
    letter-spacing: 2px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}

.priceText {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 15px;
}
.clousrePrice {
    padding-top: 30px;
    padding-bottom: 12px;
}