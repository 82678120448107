.notificationsListWrapper {
    width: 340px;
    height: 350px;
    position: absolute;
    right: 0;
    &::before {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: 5px;
        right: 4px;
        z-index: 9;
    }
    ul {
        margin: 0;
        padding: 0;
        height: 350px;
        width: 340px;
        overflow: auto;
        position: absolute;
        top: 14px;
        left: auto;
        right: -5px;
        border: 1px solid #e0e7ff;
        box-shadow: 0 1px 4px 0 rgba(202, 201, 201, 0.5);
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        li {
            list-style: none;
            min-height: 75px;
            display: flex;
            align-items: center;
            padding: 20px 18px 10px 18px;
            position: relative;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.78px;
            color: #2e384d;
            border-bottom: 1px solid #e0e7ff;
            position: relative;
            &:hover {
                background-color: #f4f6fc;
            }
            .chatIcons, .rejectedIcon, .acceptedIcon, .negotiatedIcon {
                margin-right: 15px;
                position: relative;
                border-right: 1px solid #eceaea;
                min-height: 40px;
            }
            .chatIcons {
                width: 23px;
                height: 21px;
                padding-right: 36px;
                background-image: url('../../components/images/chat.svg');
                background-repeat: no-repeat;
                background-position: 0;
            }
            .rejectedIcon, .acceptedIcon, .negotiatedIcon, .tokenKycIcon, .submitTokenIcon, .customerExitIcon, .contactRaIcon, .partiallyVerifiedIcon, .tokenSuccessfulIcon, .tokenRejectedIcon, .selfCheckInIcon {
                width: 42px;
                height: 45px;
                padding-right: 30px;
                background-repeat: no-repeat;
                background-position: 0 10px;
                background-size: 30px;
            }
            .rejectedIcon {
                background-image: url('../../components/images/rejectedIcon.svg');
            }
            .acceptedIcon {
                background-image: url('../../components/images/acceptedIcon.svg');
            }
            .negotiatedIcon {
                background-image: url('../../components/images/negotiatedIcon.svg');
            }
            .tokenKycIcon {
                background-image: url('../../components/images/tokenkyc.svg');
            }
            .submitTokenIcon {
                background-image: url('../../components/images/submitsToken.svg');
            }
            .customerExitIcon {
                background-image: url('../../components/images/customerExit.svg');
            }
            .contactRaIcon {
                background-image: url('../../components/images/contactRa.svg');
            }
            .partiallyVerifiedIcon {
                background-image: url('../../components/images/partiallyVerified.svg');
            }
            .tokenSuccessfulIcon {
                background-image: url('../../components/images/tokenSuccessful.svg');
            }
            .tokenRejectedIcon {
                background-image: url('../../components/images/tokenRejected.svg');
            }
            .selfCheckInIcon {
                background-image: url('../../components/images/newCustomerIcon.svg');
            }
            p {
                margin: 0;
                padding: 10px 0 10px 0;
                line-height: 18px;
            }
            span {
                position: absolute;
                right: 10px;
                top: 5px;
                color: #999;
            }
        }
    }
}