.sliderTicks{
    position: absolute;
    margin-top: 4px;
    width: 1px;
    height: 4px;
    background-color: #dde5eb;
}

.maxMinLabel{
    justify-content: space-between;
    display: flex;
}

.tickRight{
    position: absolute;
    right: -30px;
    margin-top: 10px;
}

.tickLeft{
    position: absolute;
    left: -30px;
    margin-top: 10px;
}