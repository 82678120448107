.wrapper {
    width: 100%;
    margin:0 auto 20px;
    padding: 0 50px;
    .dealerWrapper {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .dealerCard {
            width: 20%;
            height: 226px;
            border-radius: 15px;
            box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
            background-color: #ffffff;
            position: relative;
            margin-right: 30px;
            img { 
                position: absolute;
                top: 10px;
                left: 10px;
            }
            h3 {
                color: #0091ff;
                font-size: 60px;
                font-weight: bold;
                font-family: "Open Sans";
                text-align: center;
                padding: 44px 0 22px 0;
            }
            p {
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                color: #2e384d;
                margin: 0;
                padding: 0 20px;
                margin: auto;
                position: absolute;
                bottom: 15px;
            }
        }
        .offerPriceMainWrapper {
            width: 80%;
            min-height: 226px;
            border-radius: 15px;
            box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
            background-color: #ffffff;
        }
        .offerPriceSection {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            .moneyIcon {
                position: absolute;
                width: 48px;
                height: 48px;
                margin: 10px;
                top: 0;
                left: 0;

                .GreenIcon, .BlueIcon, .OrangeIcon, .BlackIcon, .acceptedIcon{
                    position: relative;
                    &::before {
                        content: '';
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        top: 0;
                        background-repeat: no-repeat;
                    }
                }
                .GreenIcon {
                    &::before {
                        background-image: url('../images/greenIcon.svg');
                    }
                }
                .BlueIcon {
                    &::before {
                        background-image: url('../images/blueIcon.svg');
                    }
                }
                .OrangeIcon {
                    &::before {
                        background-image: url('../images/orangeIcon.svg');
                    }
                }
                .BlackIcon {
                    &::before {
                        background-image: url('../images/blackIcon.svg');
                    }
                }
                .acceptedIcon {
                    &::before {
                        background-image: url('../images/greenIcon.svg');
                    }
                }
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
            }
            .offersContent {
                margin: 10px 0 0 70px;
                width: 50%;
                .offerTitleRed, .offerTitleGreen, .offerTitleBlack, .offerTitleBlue, .offerTitleYellow  {
                    font-size: 24px;
                    font-weight: 600;
                    color: #ff766e;
                    font-family: Open Sans;
                    margin: 0;
                    padding-bottom: 5px;
                }
                .offerPriceRed, .offerPriceGreen, .offerPriceBlack, .offerPriceBlue, .offerPriceYellow {
                    font-size: 48px;
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    color: #ff766e;
                    cursor: pointer;
                }
                .offerTitleGreen, .offerPriceGreen {
                    color: #00ba67;
                }
                .offerTitleBlack, .offerPriceBlack {
                    color: #2e384d;
                }
                .offerTitleBlue, .offerPriceBlue {
                    color: #0091ff;
                }
                .offerTitleYellow, .offerPriceYellow {
                    color: #f7b500;
                }
                .predictedPrice {
                    font-size: 16px;
                    color: #2e384d;
                    margin: 0;
                    opacity: 0.7;
                    padding-bottom: 10px;
                }
                .text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #2e384d;
                    margin: 0;
                }
                .services {
                    display: flex;
                    padding-top: 5px;
                    p {
                        margin: 0;
                        padding-right: 15px;
                        opacity: 0.7;
                        font-size: 16px;
                        color: #e02020;

                    }
                }
            }
            .priceSlideWrapper {
                display: flex;
                position: absolute;
                right: 0;
                border: 0;
                top: 0;
                height: 100%;
                width: 40%;
                .deactiveArrow {
                    background-color: #f4f6fc;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    width: 100%;
                    position: absolute;
                    right: 0;
                    height: 100%;
                    z-index: 1;
                    transition: width .5s;
                    img {
                        transform: rotate(-90deg);
                        width: 17px;
                        position: absolute;
                        top: 0;
                        right: auto;
                        bottom: 0;
                        left: 5px;
                        margin: auto;
                        cursor: pointer;
                    }
                }
                .priceTextAmountWrapper {
                    width: 85%;
                    margin: auto;
                }
            }
            .priceSlideWrapper.active {
                .deactiveArrow {
                    width: 20px;
                    transition: width .5s;
                    img  {
                        transform: rotate(90deg);
                        cursor: pointer;
                        left: 0;
                        right: auto;
                    }
                }
            }
            .priceSliderContent {
                display: block;
                position: absolute;
                right: 0px;
                z-index: 2;
                width: 90%;
                .textRed {
                    font-size: 12px;
                    color: #e02020;
                    padding-top: 10px;
                    text-align: center;
                    font-weight: 600;
                }
            }
            .priceSliderContent.active {
                display: none;
            }
            .greatOffers {
                width: 100%;
                min-height: 75px;
                margin-top: -10px;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(197,213,254,1) 100%);
                border-top-right-radius: 15px;
                position: relative;
                padding-left: 240px;
                &::before {
                    content: '';
                    background-image: url('../../components/images/superAuction.svg');
                    background-repeat: no-repeat;
                    width: 105px;
                    height: 75px;
                    position: absolute;
                    left: 110px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                h3 {
                    margin: 0;
                    padding: 10px 0 0 0;
                    font-size: 24px;
                    font-weight: bold;
                    font-style: italic;
                    color: #2e384d;

                }
                p {
                    margin: 0;
                    padding: 5px 0 0 0;
                    color: #ed6c0d;
                    font-size: 18px;
                    span {
                        font-weight: bold;
                    }
                }
            }
            
        }
    }
    
}
.logoHeader {
    height: 30px;
    box-shadow: 0 10px 10px 0 rgba(0,55,255,.15);
    display: flex;
    align-items: center;
    img {
        width: 50px;
    }
}
.offerPriceWrapper {
    background-color: #fff;
    margin-top: 20px;
    padding: 30px 0 20px 0;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
    background-color: #ffffff;
    .title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #2e384d;
        padding-top: 15px;
        text-align: center;
        margin: 0;
    }
    .price {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.54px;
        color: #205c8c;
        text-align: center;
        margin-top: 5px;
        height: 40px;
        span {
            position: relative;
            &::before {
                content: '';
                background-image: url('../../components/images/ruppes.svg');
                position: absolute;
                left: -18px;
                top: 5px;
                width: 21px;
                height: 23px;
                background-size: 50%;
                background-repeat: no-repeat;
            }
            &::after {
                content: '';
                background-image: url('../../components/images/shape.svg');
                position: absolute;
                right: -60px;
                top: 0;
                width: 45px;
                height: 32px;
                background-size: 60%;
                background-repeat: no-repeat;
            }
        }
    }
    .offersSection {
        margin: 15px 70px 14px;
        display: flex;
        justify-content: space-between;
    }
}
.similarCars {
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    padding: 15px 35px;
    .customerYourcars {
        width: 246px;
    }
}
.priceWrapper {
    margin-top: -20px;
    .priceSlider {
        position: relative;
        .priceTextAmountWrapper {
            position: absolute;
            top: -40px;
            right: 15%;
            width: 250px;
        }
    }
}

.greenText, .orengeText, .redText {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    flex: 0.6;
    text-align: left;
}
.greenText {
    color: #44ca6f;
}
.orengeText {
    color: #f77000
}
.redText {
    color: #fa0000;
}
.noData {
    font-size: 14px;
    padding-top: 50px;
    display: block;
    text-align: center;
}
.optimumAmount {
    position: absolute;
    top: 10px;
    color: #585858;
    font-size: 12px;
    font-weight: bold;
    left: 0;
    right: 0;
    text-align: center;
}
.ctaWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
}
.tabing {
    margin-left: -11%;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            font-size: 12px;
            color: #2e384d;
            text-transform: capitalize;
            background-color: #e0e7ff;
            font-weight: 600;
            text-align: center;
            color: #2e384d;
            outline: none;
            width: 100%;
            cursor: pointer;
            line-height: 24px;
            &:last-child {
                border-top-right-radius: 15px;
            }
            &.active {
                background: #0037ff;
                color: #fff;
            }
        }
    }
}


.blueCta, .blueCtaActive, .listCarCta, .listCarCtaActive {
    text-transform: capitalize;
    background-color: #e0e7ff;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #2e384d;
    outline: none;
    width: 100%;
    cursor: pointer;
}
.blueCta:focus, .blueCtaActive:focus, .listCarCta:focus {
    border: none;
    outline: none;
}
.blueCtaActive, .listCarCtaActive {
    background: #0037ff;
    color: #fff;
}
.listCarCta, .listCarCtaActive {
    border-top-right-radius: 15px;
}
.textLabel {
    color: #2e384d;
    font-size: 12px;
    margin: 0px;
}
.listCarPrice {
    margin-top: -10px;
    :global .customer-offer-list-car_clousrePrice__1UGKL {
        padding-top: 0 !important;
    }
    :global .customer-offer-list-car_sliderWrapper__32y0h {
        margin-bottom: 0 !important;
    }
    :global .customer-offer-list-car_ctaButton__17TDq {
        margin-top: 10px !important;
    }
    :global .customer-offer-list-car_listingCarPopup__DjE5c {
        margin: auto;
        width: 90%;
    }
    :global .psi-slider_psiSlider__1gSFD {
        margin-top: 15px !important;
    }
}
.priceSliderContent :global .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    background-color: #f4f6fc !important;
    z-index: 2 !important;
} 
.generateCta {
    margin-top: 10px;
    text-align: center;
}
.ocbRuningWrapper {
    display: flex;
    .ocbruning, .ocbPrice {
        color : #ed6c0d;
        font-size: 24px;
        margin: 0;
    }
    .ocbPrice {
        font-weight: bold;
        padding-left: 20px;
    }
}
.ocbTimer, .expires {
    text-align: left;
    margin: 0;
    font-size: 18px;
    color: #2e384d;
}
.ocbRuningExpired {
    display: flex;
    .ocbruning, .ocbPrice {
        color : #ff766e;
        font-size: 24px;
        margin: 0;
    }
    .ocbPrice {
        font-weight: bold;
        padding-left: 20px;
    }
}
.errorText {
    font-weight: bold;
    font-size: 12px;
    color: #c80039;
    text-align: center;
    margin: 0;
    padding-top: 10px;
}