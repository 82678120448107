.customerJourney {
    padding: 0px 0px 37px 0;
    background-color: #fff;
    h1 {
        font-size: 30px;
        font-weight: bold;
        color: #424b60;
        font-family: "Open Sans";
        text-align: center;
        margin: 38px 0 23px 0;
    }
    .strip {
        background-color: #5871c5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 26px;
        font-family: "Open Sans";
        font-weight: bold;
        padding: 0 90px;
        height: 60px;
        margin-top: 20px;
        cursor: pointer;
        .arrow {
            background-color: #e0e7ff;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 17px;
            }
        }
    }
    .resendOtpContainer {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 4px 4px 0px;
        position: sticky;
        top: 0px;
        z-index: 2;
        .resendOtpSection{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 3.5%;
            .countDownTextWrapper {
                display: flex;
                .countDown {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: #0037FF;
                    width: 100px;
                    p {
                        font-size: 14px;
                        line-height: 16px;
                        color: #0037FF;
                        opacity: 0.7;
                        padding-top:4px;
                        font-weight: 500;
                    }
                }
                .text {
                    
                    h4, p {
                        color: #2E384D;
                        opacity: 0.7;
                    }
                    h4 {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        margin: 0;
                    }
                    p {
                        margin: 0;
                        padding: 4px 0 0 0;
                        font-size: 14px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
    .congratulationsFlag {
        background-image: linear-gradient(to left, #f7b500, #ffe8aa);
        min-height: 60px;
        position: relative;
        border-top-right-radius: 5px;
        padding: 0 3.4%;
        &::before {
            content: '';
            background-image: url('../../components/images/gift.svg');
            width: 41px;
            height: 45px;
            position: absolute;
            top: 0;
            left: 3.5%;
            bottom: 0;
            margin: auto;
        }
        h6, h4 {
            padding-left: 60px;
            font-style: italic;
            text-align: left;
            color: #2e384d;
            font-weight: bold;
        }
        h6 {
            opacity: 0.8;
            font-size: 18px;
            margin: 0;
            padding-top: 10px;
        }
        h4 {
            font-size: 14px;
            margin-top: 10px;
            padding-bottom: 10px;
            line-height: 18px;
        }
    }
    .depReportJourney {
        transition: 0.5s;
    }
    .depReportJourney.active, .customerOfferPriceJourney.active  {
        transition: 0.5s;
        .strip {
            img {
                transform: rotate(180deg);
            }
        }
        .depReportJourneyContent, .customerOfferPriceJourneyContent{
            display: block;
        }
    }
    .depReportJourneyContent, .customerOfferPriceJourneyContent {
        display: none;
    }
    .covidWrapper {
        display: flex;
        background-color: #e020202e;
        border-radius: 10px;
        padding: 10px;
        margin: 0 12% 20px 12%;
        display: none;
        p {
            margin: 0 0 0 10px;
            padding: 0;
            font-size: 21px;
            color: #2e384d;
            h3 {
                font-weight: bold;
                display: inline-flex;
                margin: 0;
                padding: 0;
            }
        }
    }
}
:global #cars24_dvAuctionPrice {
    margin-bottom: 100px;
}