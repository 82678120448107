label {
    color: #b0bac9;
    font-size: 11px;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
}
.text {
    margin: 0;
    padding: 0;
    color: #2e384d;
    font-size: 14px;
    letter-spacing: 0.5px;
}
.backupNumber {
    width: 160px;
}