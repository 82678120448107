.schedulePickupPopup {
    width: 380px;
    .carDataList {
        padding: 15px 0 0 0;
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                font-size: 14px;
                letter-spacing: 0.58px;
                color: #2e384d;
                opacity: 0.51;
                position: relative;
                padding: 0 10px 0 0;
                margin-right: 6px;
                text-transform: capitalize;
                &::after {
                    content: '|';
                    position: absolute;
                    top: 0;
                    right: -1px;
                }
                &:last-child {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
    .address {
        position: relative;
        padding-left: 30px;
        margin-top: 40px;
        display: flex;
        &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            background-image: url('../images/homeIcons.svg');
            width: 20px;
            height: 22px;
            background-size: 100%;
        }
        .addressWrapper {
            width: 100%;
            // border: 1px solid #c1c1c1;
            // border-radius: 3px;
            // padding: 5px;
            p {
                margin: 0;
                padding: 0;
                font-size: 14px;
                color: #616773;
            }
        }
        .addressUpdateCta {
            display: flex;
            justify-content: flex-end;
        }
        .edit {
            position: relative;
            padding-left: 30px;
            font-size: 14px;
            color: #0037ff;
            font-weight: bold;
            margin-left: 10px;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0;
                background-image: url('../images/edit.svg');
                width: 25px;
                height: 25px;
                background-size: 100%;
            }
        }
    }
    .zone {
        position: relative;
        margin: 15px 0 0 0;
        padding-left: 30px;
        font-size: 14px;
        color: #616773;
        &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            background-image: url('../images/arrowcj.svg');
            width: 20px;
            height: 19px;
            background-size: 100%;
        }
    }
    .timeDateWrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .timeWrapper, .dateWrapper {
            position: relative;
            padding-left: 30px;
            width: 100%;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                background-image: url('../images/timeSlot.svg');
                width: 20px;
                height: 20px;
                background-size: 100%;
            }
        }
        .dateWrapper {
            margin-right: 12px;
            &::before {
                content: '';
                background-image: url('../images/calander.svg');
                background-size: 100%;
            } 
        }
    }
    .ctaWrapper {
        display: flex;
        justify-content: space-evenly;
        margin-top: 60px;
    }
}
.inputWrap {
    // margin-top: 30px;
    width: 49%;
}
.inputWrap label {
    display: none;
}