.leadProcurement {
    padding: 10px 10px 0 10px;
    top: 0;
    left: 0;
    right: 0;
}
.header {
    display: flex;
    flex-direction: row;
    background: #F5F5F5;
}
.headerItem {
    margin: 10px;
}
.mainWrapper {
    padding-top: 40px;
    min-height: calc(100vh - 40px);
    padding-bottom: 50px;
}


