.cjplanningCard {
    position: relative;
    :global .table-header_tableHeading__2F6Js {
        max-width: -webkit-fill-available;
    }
    table {
        border-spacing: 0 12px;
        border-collapse: unset;
        table-layout: fixed;
        margin-top: -10px;
        thead {
            tr {
                position: sticky;
                z-index: 1;
                top: 122px;
                box-shadow: 1px 10px 8px -7px #d1d1d1;
                background: #f4f6fc;
                height: 50px;
                th {
                    padding: 0 10px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #2E384D;
                    text-align: center;
                    &:last-child {
                        text-align: center;
                    }
                    ul {
                        margin: 0;
                        padding: 10px 0 0 0;
                        display: flex;
                        justify-content: space-evenly;
                        li {
                            list-style: none;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 14px;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            color: #979797;
                        }
                    }
                    .infoSection {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        .tooltipSection {
                            position: relative;
                            img {
                                margin-left: 10px;
                                cursor: pointer;
                            }
                            .infoTool {
                                width: 180px;
                                background-color: #FFFFFF;
                                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
                                border-radius: 10px;
                                padding: 12px;
                                display: none;
                                position: absolute;
                                right: -40px;
                                top: 24px;
                                &::before{
                                    content: '';
                                    width: 0; 
                                    height: 0; 
                                    border-left: 25px solid transparent;
                                    border-right: 25px solid transparent;
                                    border-bottom: 25px solid #fff;
                                    position: absolute;
                                    top: -9px;
                                    right: 20px;
                                }
                                p {
                                    margin: 0;
                                    padding: 0;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 18px;
                                    letter-spacing: 0.5px;
                                    color: #585858;
                                    text-align: left;
                                    text-transform: none;
                                }
                                ul.attendanceInfo {
                                    margin: 16px 0 0 0;
                                    padding: 0;
                                    display: block;
                                    li {
                                        display: flex;
                                        align-items: center;
                                        margin: 8px 0;
                                        line-height: 32px;
                                        letter-spacing: 0;
                                        span {
                                            list-style: none;
                                            width: 32px;
                                            height: 32px;
                                            background-color: #00BA67;
                                            border-radius: 100%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            margin-right: 10px;
                                            &::before {
                                                content: 'P';
                                                font-weight: 600;
                                                font-size: 14px;
                                                line-height: 32px;
                                                color: #FFFFFF;
                                            }
                                            &.leave {
                                                background-color: #E02020;
                                                &::before {
                                                    content: 'L';
                                                }
                                            }
                                            &.weeklyOff {
                                                background-color: #2E384D;
                                                &::before {
                                                    content: 'O';
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover {
                                .infoTool {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                border: 1px solid rgba(0, 55, 255, 0.0796274);
                td {
                    padding: 10px;
                    background-color: #FFFFFF;
                    position: relative;
                    box-sizing: border-box;
                    text-align: center;
                    &:first-child {
                        text-align: left;
                        border-radius: 8px 0 0 8px;
                    }
                    &:last-child {
                        border-radius: 0px 8px 8px 0px;
                    }
                    :global .hi-customer-detail_index__28rdL {
                        font-size: 12px;
                    }
                    :global .hi-location-detail_detailsInfo__1j_97 {
                        text-align: left;
                    }
                    .carDetail {
                        margin-left: 10px;
                        ul {
                            margin: 0;
                            padding: 0;
                            li {
                                list-style: none;
                                font-size: 14px;
                                letter-spacing: 1px;
                                color: #585858;
                                padding: 6px 0 6px 0px;
                                display: flex;
                                align-items: center;
                                .detailsInfo {
                                    padding: 0;
                                    width: 90%;
                                    margin-left: 16px;
                                    font-size: 12px;
                                    line-height: 16px;
                                    letter-spacing: 0.5px;
                                    color: #2E384D;
                                    .max {
                                        white-space: nowrap;
                                        max-width: 18ch;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: block;
                                    }
                                }
                                .detailsIcon {
                                    margin: 0;
                                    padding: 0;
                                    width: 18px;
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #0037ff;
                                    img {
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                    .callStatus {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .notConnected, .callConnected, .notCalled {
                            font-weight: normal;
                            font-size: 10px;
                            line-height: 15px;
                            letter-spacing: 0.416667px;
                            color: #E02020;
                            margin: 0;
                            padding: 0 0 0 28px;
                            position: relative;
                            &::before {
                                content: '';
                                background-repeat: no-repeat;
                                width: 16px;
                                height: 16px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                            }
                            span {
                                display: block;
                                text-align: left;
                            }
                            
                        }
                        .notConnected {
                            &::before {
                                content: '';
                                background-image: url('./images/callIconRed.svg');
                            }
                        }
                        .callConnected {
                            color: #00BA67;
                            &::before {
                                content: '';
                                background-image: url('./images/callIconGreen.svg');
                            }
                        }
                        .notCalled {
                            color: #0037FF;
                            &::before {
                                content: '';
                                background-image: url('./images/callIconBlue.svg');
                            }
                        }
                    }
                    .followupSection {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .location {
                            position: relative;
                            font-weight: normal;
                            font-size: 10px;
                            line-height: 18px;
                            letter-spacing: 0.5px;
                            color: #2E384D;
                            margin: 0;
                            padding: 0 0 0 30px;
                            text-align: left;
                            &::before {
                                content: '';
                                background-image: url('../images/upArrow.svg');
                                background-repeat: no-repeat;
                                width: 20px;
                                height: 19px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }
                    .time {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        color: #2E384D;
                        margin: 0;
                        padding: 0;
                    }
                    .selectedLocation {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 10px;
                        line-height: 16px;
                        color: #2E384D;
                        margin: 16px 0 0 0;
                        padding: 0 0 0 30px;
                        text-align: left;
                        &::before {
                            content: '';
                            background-image: url('../images/homeIcon.svg');
                            background-repeat: no-repeat;
                            width: 20px;
                            height: 19px;
                            position: absolute;
                            top: 20px;
                            left: 10px;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                    .cjName {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        color: #2E384D;
                    }
                    .reallocate {
                        font-weight: normal;
                        font-size: 10px;
                        line-height: 18px;
                        text-align: center;
                        letter-spacing: 0.5px;
                        text-decoration: underline;
                        color: #003FB8;
                        margin: 0;
                        padding: 5px 0 0 0;
                        cursor: pointer;
                    }
                    .denied, .headingText {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        letter-spacing: 0.5px;
                        color: #E02020;
                        margin: 0;
                        padding: 0;
                    }
                    .headingText {
                        color: #2E384D;
                    }
                    .connectTool{
                        font-weight: normal;
                        font-size: 10px;
                        line-height: 14px;
                        text-align: center;
                        letter-spacing: 0.5px;
                        color: #2E384D;
                        margin-top: 10px;
                        position: relative;
                        display: flex;
                        p {
                            margin: 0;
                            padding: 0 0 0 20px;
                            position: relative;
                            text-align: left;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            &::before {
                                content: '';
                                background-image: url('../images/help.svg');
                                width: 12px;
                                height: 12px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                        span {
                            font-weight: bold;
                            cursor: pointer;
                        }
                        .helpInfo {
                            display: none;
                            position: absolute;
                            top: 20px;
                            z-index: 9;
                            background: #FFFFFF;
                            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
                            border-radius: 10px;
                            padding: 16px;
                            text-align: left;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.5px;
                            color: #585858;
                        }
                        &:hover .helpInfo {
                            display: block;
                        }
                    }
                    .sellOnline, .homeInspection, .reinspection {
                        background-color: #FDF0CC;
                        border-radius: 71px 60px 0px 71px;
                        font-weight: 600;
                        font-size: 8px;
                        line-height: 12px;
                        color: #2E384D;
                        text-align: center;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 3px 10px;
                        &.homeInspection {
                            background-color: #CCF1E1;
                        }
                        &.reinspection {
                            background-color: #CCE9FF;
                        }
                    }
                }
            }
        }
    }
    ul.attendance {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        li, &.leave, &.weeklyOff {
            list-style: none;
            width: 32px;
            height: 32px;
            background-color: #00BA67;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
                content: 'P';
                font-weight: 600;
                font-size: 14px;
                line-height: 32px;
                color: #FFFFFF;
            }
            &.leave {
                background-color: #E02020;
                &::before {
                    content: 'L';
                }
            }
            &.weeklyOff {
                background-color: #2E384D;
                &::before {
                    content: 'O';
                }
            }
        }
    }
}
.loadmore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}