.progressStep {
    margin-top: 20px;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            position: relative;
            width: 100%;
            &:first-child {
                &::before {
                    content: " ";
                    display: block;
                    width: 0;
                    height: 0;
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    border-left: 24px solid #f4f6fc;
                    position: absolute;
                    top: 100%;
                    margin-top: -32px;
                    margin-left: 1px;
                    left: -1%;
                    z-index: 1;
                }
            }
            a {
                color: #2e384d;
                font-size: 14px;
                text-decoration: none; 
                padding: 6px 0 6px 30px;
                background: #d8d8d8;
                position: relative; 
                display: flex;
                justify-content: center;
                cursor: default;
                &::after, &::before {
                    content: " "; 
                    display: block; 
                    width: 0; 
                    height: 0;
                    position: absolute;
                    top: 100%;
                    left: 100%;
                }
                &::after {
                    border-top: 16px solid transparent;
                    border-bottom: 17px solid transparent;
                    border-left: 20px solid #d8d8d8;
                    margin-top: -33px;
                    z-index: 2; 
                }
                &::before {
                    border-top: 24px solid transparent;
                    border-bottom: 24px solid transparent;
                    border-left: 30px solid #f4f6fc;
                    margin-top: -41px;
                    margin-left: 1px;
                    z-index: 1;
                }
                // &:hover {
                //     background: #44ca6f;
                //     color: #fff;
                //     &::after {
                //         border-left-color: #44ca6f !important; 
                //     }
                // }
                &.active {
                    background: #44ca6f;
                    color: #fff;
                    &::after {
                        content: " ";
                        display: block;
                        width: 0;
                        height: 0;
                        border-top: 16px solid transparent;
                        border-bottom: 17px solid transparent;
                        border-left: 20px solid #44ca6f;
                        position: absolute;
                        top: 100%;
                        margin-top: -33px;
                        margin-left: 0px;
                        left: 100%;
                        z-index: 1;
                    }
                }
                &.lost{
                    background: #e84646;
                    color: #fff;
                    &::after {
                        content: " ";
                        display: block;
                        width: 0;
                        height: 0;
                        border-top: 16px solid transparent;
                        border-bottom: 17px solid transparent;
                        border-left: 20px solid #e84646;
                        position: absolute;
                        top: 100%;
                        margin-top: -33px;
                        margin-left: 0px;
                        left: 100%;
                        z-index: 1;
                    }
                }
                
            }
        }
    }
}