.searchSection {
    margin-top: 15px;
    display: flex;
}
.calenderWrapper {
    position: relative;
}
.calander {
    position: absolute;
    top: 44px;
    right: auto;
    background-color: #fff;
    border: 1px solid #e0e7ff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0;
}
.calander:focus {
    outline: none;
}
.calanderFormWrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #2e384d;
}
.calanderForm {
    position: relative;
    margin: 0;
    padding-left: 30px;
    border: 1px solid #e0e7ff;
    border-radius: 3px;
    height: 32px;
    width: 184px;
    margin: 0 10px;
    font-size: 12px;
    color: #2e384d;
    display: flex;
    align-items: center;


}
.calanderForm::before {
    background-image: url('../../components/images/calendar.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
}
.calander :global .DayPicker-Months {
    flex-wrap: nowrap !important;
}
.calander :global .DayPicker-wrapper {
    outline: none !important;
}