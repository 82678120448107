.carouselWrapper {
    width: 100%;
    position: relative;
}
.carouselWrapper img {
    width: 100%;
}
.carouselWrapper button{
    border: none;
    cursor: pointer;
}
.carouselWrapper button:focus {
    outline: none;
}
.carouselWrapper .prev{
    position: absolute;
    top: 50%;
    left: 0;
    background: transparent;
    padding: 0;
}
.carouselWrapper .nxt{
    position: absolute;
    top: 50%;
    right: 0;
    background: transparent;
    padding: 0;
}
.imgCaption{
    text-align: center;
    color: #fff;
    background: rgba(46, 56, 77, 0.85);
    padding: 6px 0px;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.imageWrapper {
    position: relative;
}
.imageWrapper :global .TransformComponent-module_container__3NwNd {

}