.filtercheckboxHolder{
    display: inline-block;
}
.filtercheckboxHolder input[type="checkbox"] {
    display: none
  }
.filtercheckboxHolder label {
    cursor: pointer;
    top: 3px;
    position: relative;
    
  }
  .filtercheckboxHolder input[type="checkbox"] + label:before,
  .filtercheckboxHolder input[type="checkbox"]:checked + label:before {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    padding: 0;
    content: "";
    display: inline-block;
    top: 2px;
  }

  .filtercheckboxHolder input[type="checkbox"] + label:before {
    background-image: url('../../images/filter-rectangle.svg');
    background-repeat: no-repeat;
    border-color: transparent;
    background-size: 24px;
  }
  .filtercheckboxHolder input[type="checkbox"]:checked + label:before {
    background-image: url('../../images/filter-selected.svg');
    background-repeat: no-repeat;
    border-color: transparent;
    background-size: 24px;
  }
  .filtercheckboxHolder input[type="checkbox"]:focus + label::before {
      outline: transparent;
  }