.raremarkswrap {
	display: block;
	margin: 0 auto;
	min-width: 500px;
	h2 {
		padding-bottom: 24px;
		margin: 0;
	}
	.actionbutton {
		display: flex;
		justify-content: flex-end;
		margin-top: 28px;
		button {
			width: 143px;
			height: 48px;
			display: inline-block;
		}
	}
}