.header {
    height: 60px;
    border-bottom: 1px solid #e0e7ff;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f6fc;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
}
.logoSection {
    display: flex;
    align-items: center;
}
.userRightSection {
    display: flex;
    align-items: center;
}
.userRightSection a {
    margin: 0 10px;
    display: flex;
}
.buttonList {
    margin: 0 10px;
    padding: 0;
    display: flex;
    align-items: center;
}
.buttonList li {
    list-style: none;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.uploadCsv {
    display: flex;
    align-items: center;
    margin-left: 24px;
    height: 60px;
}
.uploadCsv a {
    font-size: 14px;
    font-weight: bold;
    color: #2e384d;
    letter-spacing: 1px;
    padding: 0px;
    margin: 0;
    text-decoration: none !important;
}
.uploadCsv a:hover {
    color: #2e384d;
}
.activeButton {
    margin: 0 10px;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: aquamarine;
}
.inactiveButton {
    margin: 0 10px;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: pink;
}