.wrapper{
    // height: calc(100vh - 50px);
    overflow-y: scroll;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #002441;
}
.reasonArea {
    padding: 0 20px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #465166;
        margin-bottom: 10px;
    }
    .listTab{
        margin: 24px 0 0 0;
        padding: 0;
        list-style-type: none;
    }
    .tab {
        background-color: #ffffff;
        border: 1px solid #DDE5EB;
        box-sizing: border-box;
        border-radius: 12px; 
        text-align: center;
        font-size: 14px;
        line-height: 44px;
        color: #465166;
        cursor: pointer;
        margin-bottom: 12px;
    }
    .active {
        border-color: #0059A3;
        background: #F1F8FF;
        font-weight: 600;
        color: #0059A3;
    }
    .additionalCommentsTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #465166;
        margin-bottom: 10px;
        h3 {
            margin: 0 0 12px 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.01em;
        }
        textarea {
            border: 1px solid #BEBEBE;
            border-radius: 8px;
            padding: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.01em;
            color: #7E8594;
            &:focus {
                outline: none;
                border: 1px solid #BEBEBE !important;
            }
        }
    }
}
.error{
    color: #c80039;
    font-size: 14px;
    line-height: 6px;
    font-weight: 600;
}
.sorryComponent {
    padding-top: 150px;
    background-color: #f5f5f5;
    min-height: calc(100vh);
    padding-bottom: 100px;
}
.confirmBtn {
    background-color: #fff;
    padding: 8px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.confirmBtn button {   
    width: 100%;
    height: 48px;
    text-align: center;
    background: #EF6E0B;
    color: #fff;
    box-shadow: none;
    border-radius: 12px;
}