.login {
    min-height: 100vh;
    background-color: #F5F5F5;
}
.loginOuter{
    margin: auto;
}
.loginOuter :global #okta-sign-in form,
.loginOuter :global .auth-footer,
.loginOuter :global .auth-divider,
.loginOuter :global h2,
.loginOuter :global .okta-sign-in-header {
    display: none;
}
.loginOuter :global #okta-sign-in.no-beacon .auth-content {
    padding-top: 0;
}
.loginOuter :global #okta-sign-in.auth-container.main-container {
    background-color: #F5F5F5;
}
.errorContainer{
	font-weight: 600;
	font-size: 600;
	text-align: center;
	margin-left: 10px;
    margin-top: 1.25rem;
}
.loginOuter :global .auth-content {
    background-color: #F5F5F5;
}
.loginOuter h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #002441;
    margin: 48px 0 0 0;
}
.soBannerWrapper  {
    position: relative;
    width: 100%;
}
.soBannerWrapper picture {
    width: 100%;
    height: auto;
}
.soBannerWrapper .banners {
    max-width: 100%;
    width: 100%;
}
.bannerContent, .exchangeContent {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.content, .textWrapper {
    width: 100%;
    position: relative;
    z-index: 9;
    padding: 40px 0 0 20px;
}

.soBannerWrapper .puchline {
    margin: 0;
    padding: 12px 0 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-shadow: 0px 4.20438px 8.40876px rgba(0, 89, 163, 0.15);
}

.soBannerWrapper .banners {
    max-width: 100%;
    width: 100%;
}

.soBannerWrapper h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4.20438px 8.40876px rgba(0, 89, 163, 0.15);
    margin: 0;
    padding: 0 0 12px 0;
    position: relative;
}
.soBannerWrapper h1::after {
    content: '';
    background-image: url('./images/pattern.svg');
    width: 75px;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.hassleFree {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0 6px 20px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0px;
	pointer-events: none;
}
.hassleFree h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    text-transform: uppercase;
    color: #e5e5e5;
    margin: 0;
}