.depreciation svg.recharts-surface tspan {
  font-size: 11px !important;
  color: black !important;
  margin: 1%;
}
.rating{
  width: 60px;
  height: 26px;
  border-radius: 3px;
  padding-left: 3px;
  .ratingText{
      color: white;
      margin-left: 5px;
      font-size: 14px;
  }

}
.red{
  background-color: #FD3708;
}
.green{
  background-color: #05C410;
}
.yellow{
  background-color: #f2c60c
}

