.visitedWrap{
  padding-top: 17px;
  width: 100%;
  padding-right: 10px;
}

.filters, .sellOnlineFilter, .missedCallFilter {
  color: #2e384d;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filters ul, .sellOnlineFilter ul, .missedCallFilter ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.filters ul li, .sellOnlineFilter ul li, .missedCallFilter ul li {
  list-style: none;
  letter-spacing: 1px;
  border: 1px solid rgba(0, 55, 255, 0.1);
  min-width: 110px;
  height: 32px;
  text-align: center;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  padding: 0 5px;
  cursor: pointer;
  position: relative;
}
.filters ul li::after {
  content: '+';
  position: absolute;
  top: 6px;
  right: -16px;
  color: #2e384d;
}
.filters ul li:nth-last-child(2)::after, .filters ul li:last-child::after {
  content: '';
}
.filters ul li:last-child::before {
  content: '=';
  position: absolute;
  left: -13px;
  top: 6px;
  color: #2e384d;
}
.filters ul li:hover, .missedCallFilter ul li:hover, .sellOnlineFilter ul li:hover {
  color: #0037ff;
  border: 1px solid #0037ff;
}
.filters.pllCenterRole ul li:last-child::before {
  content: ''
}
