.actionButtons {
    display: flex;
    place-items: center;
    gap: 20px;
}

.actionButtons button:nth-child(1) {
    background-color: blue;
    color: white;
}

.actionButtons button:nth-child(2) {
    background-color: red;
    color: white;
}

.procurementStatus {
    font-size: 16px;
}