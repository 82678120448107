.railOuter{
    position: absolute;
    width: 100%;
    height: 5px;
    transform: translate(0%, -50%);
    border-radius: 7px;
    cursor: pointer;
}
.railInner{
    position: absolute;
    width: 100%;
    height: 2px;
    transform: translate(0%, -50%);
    border-radius: 7px;
    pointer-events: none;
}