.pllLeadAllocationWrapper {
    padding: 80px 0 0 245px;
    .allSelectboxWrapper {
        display: flex;
        justify-content: space-between;
        box-shadow: 0 10px 10px 0 rgba(0, 55, 255, 0.15);
        padding: 12px 40px;
        position: fixed;
        left: 250px;
        right: 0;
        z-index: 1;
        background-color: #f4f6fc;
        top: 60px;
        align-items: center;
        .allSelectbox {
            display: flex;
            font-size: 14px;
            align-items: center;
            p {
                margin: 0;
                padding: 0;
            }
        }
        .selectLeads {
            min-width: 200px;
            .error{
                border: 1px solid #c80039;
            }
        }
        .rightSelectBoxWrapper{
            display: flex;
            div:first-child{
                margin-right: 10px;
            }
        }
    }
    .leadFilterMainWrapper {
        padding: 80px 40px 0px 40px;
        position: relative;
        .infoText {
            font-size: 16px;
            letter-spacing: 1px;
            position: absolute;
            top: 400px;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            z-index: 1;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .buttonWrapper {
        display: flex;
        justify-content: space-between;
        .submitButton {
            margin: 0 40px 40px 0;
        }
		.submitButtons {
            margin: 0 40px 40px 0;
			display: flex;
			justify-content: space-between;
			.inSubmitBtn {
				margin-right: 10px;
			}
        }
        .loadMore {
            margin-left: 40px;
        }
    }
    
}
