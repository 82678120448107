body {
    background-color: rgba(8, 89, 146, 0.05);
}
.depReportWrapper {
    padding: 0 120px;
    .carNameSection {
        h1 {
            font-size: 36px;
            font-weight: bold;
            color: #424b60;
            font-family: "Open Sans";
            text-align: center;
            margin: 0;
        }
        p {
            font-size: 20px;
            font-weight: 600;
            color: #075992;
            margin: 0;
            padding: 0;
            text-transform: capitalize;
        }
    
    }
    .graphSection {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 24px 34px;
        margin-top: 20px;
        .graphContent {
            flex: 1 1 40%;
            h2 {
                font-size: 20px;
                font-weight: bold;
                color: #424b60;
                text-transform: uppercase;
            }
            .text {
                font-size: 14px;
                color: #2e384d;
                margin: 0;
                padding-top: 50px;
                width: 350px;
            }
            .value {
                font-size: 16px;
                font-weight: 600;
                color: #125f96;
                padding-top: 26px;
                margin: 0;
            }
        }
        .graph {
            flex: 2 1 40%;
        }
    }
    .inspectionReport {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 32px;
        h2 {
            font-size: 20px;
            font-weight: bold;
            color: #424b60;
        }
    }
    .dataList {
        ul {
            margin: 0;
            padding: 0;
           li {
               list-style: none;
               margin-bottom: 12px;
               background-color: #fff;
               padding: 10px 70px;
               cursor: pointer;
               .listHeading {
                    display: grid;
                    grid-template-columns: 30% 25% 25% 20%;
                    position: relative;
                    cursor: pointer;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/accrod_arrow.svg');
                        position: absolute;
                        left: -45px;
                        top: 0;
                        width: 26px;
                        height: 26px;
                        transform: rotate(180deg);
                    }
                }
                .dataContentList {
                    .listHeading {
                        &::before {
                            content: '';
                            transform: rotate(0deg);
                        }
                    }
                    .dataContent {
                        display: none;
                    }
                }
               
            }
        }
        
    }
}
.dataContent{
    font-size: 14px;
    color: #2e384d;
    margin-top: 10px;
    &>div {
        width: 28%;
    }
}
.carPrice {
    background-color: #085992;
    height: 60px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        margin: 0;
    }
}
.footerText {
    display: flex;
    justify-content: space-between;
    padding: 30px 50px 0;
    background-color: #fff;
    .asterisk {
        display: flex;
        align-items: flex-start;
        img {
            margin-right: 10px;
        }
    }
    .dataListWrapper {
        display: flex;
        margin-top: 10px;
        ul {
            margin-right: 30px;
            padding: 0;
            li {
                font-size: 14px;
                line-height: 1.71;
                color: #2e384d;
                list-style: none;
                position: relative;
                padding-left: 20px;
                &::before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: #dedede;
                    position: absolute;
                    left: 0;
                    top: 9px;
                }
            }
        }
    }
    
}
.dataTable {
    background-color: #f2f2f2;
    padding: 0 18px;
    margin-top: 20px;
    tr {
        td {
            height: 44px;
            border-bottom: 1px solid #999;
            padding: 0 20px;
        }
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }
}
.greenSign, .yellowSign, .crossSign {
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: center;
    &::before {
        content: '';
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 4pxx; 
    }
}
.greenSign {
    &::before {
        background-image: url('../../components/images/greenSign.svg');
    }
}
.yellowSign {
    &::before {
        background-image: url('../../components/images/yellowSign.svg');
    }
}
.crossSign {
    &::before {
        background-image: url('../../components/images/cross.svg');
    }
}

.redRating, .yellowRating, .greenRating {
    position: relative;
    width: 60px;
    border-radius: 3px;
    background-image: url(../../components/images/star.svg);
    background-repeat: no-repeat;
    background-position: 6px 4px;
    background-size: 20px;
    padding: 2px 7px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
}
.greenRating {
    background-color: #319937;
}
.yellowRating {
    background-color: #e9ac12;
}
.redRating {
    background-color: #d55046;
}
.engineTransmission {
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: center;
}
.staticHeading {
    display: grid;
    grid-template-columns: 30% 25% 25% 20%;
    position: relative;
    padding: 0 70px 16px;
    font-weight: bold;
}
.alignRight {
    text-align: right;
}
.valueWrapper {
    display: flex;
    justify-content: space-between;
    background-color: rgba(8, 89, 146, 0.11);
    color: #085992;
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    align-items: center;
    padding: 0 70px;
    margin-bottom: 21px;
}
.sendReport, .expectedPrice {
    position: relative;
    padding: 0px 22px 0 60px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #0037ff;
    border-radius: 5px;
    min-height: 48px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 8px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        bottom: 0;
        margin: auto;
    }
}
.sendReport {
    background-color: transparent;
    color: #0037ff;
    &::before {
        content: '';
        background-image: url(../images/sendReport.svg);
    }
    &:hover {
        text-decoration: none;
    }
}
.expectedPrice {
    &::before {
        content: '';
        background-image: url(../images/rupessIcons.svg);
        height: 24px;
    }
    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

.idvInsurance {
    border-radius: 10px;
    border: solid 1px #e0e7ff;
    background-color: #ffffff;
    padding: 30px;
    margin-top: 30px;
    display: flex;
    .idvInsurancText {
        width: 350px;
        h3 {
            font-size: 24px;
            font-weight: bold;
            color: #424b60;
            margin: 0;
            padding: 0;
        }
        .text {
            font-size: 14px;
            color: #2e384d;
            margin: 0;
            padding-top: 13px;
            padding-right: 90px;
        }
        .checkidv {
            font-size: 20px;
            font-weight: bold;
            color: #0037ff;
            text-decoration: none;
            text-transform: uppercase;
            margin-top: 16px;
            display: flex;
        }
    }
    .idvValue {
        padding: 13px;
        background-color: #f4f6fc;
        border-radius: 10px;
        width: 260px;
        height: 133px;
        margin-left: 50px;
        h3 {
            font-size: 20px;
            font-weight: 600;
            color: #2e384d;
            margin: 0;
        }
        table {
            margin-top: 16px;
            width: 100%;
            tr {
                td {
                    font-size: 16px;
                    color: #2e384d;
                    padding: 5px 0;
                }
            }
        }
    }
}
.idvcarValue {
    width: 350px;
    height: 88px;
    border-radius: 15px;
    background-color: #e0e7ff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 15px;
    h3 {
        font-size: 18px;
        font-weight: bold;
        color: #2e384d;
        width: 240px;
        margin: 0;
    }
    .exclamationIcon {
        width: 48px;
        height: 48px;
        box-shadow: 0 5px 10px 0 rgba(0, 55, 255, 0.1);
        background-image: linear-gradient(46deg, #0037ff, #4f75ff 101%);
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        border-radius: 100%;
        text-align: center;
        margin: 0;
        position: relative;
        .idvHover {
            width: 322px;
            border-radius: 20px;
            box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.1);
            border: solid 1px #e0e7ff;
            background-color: #ffffff;
            padding: 10px;
            position: absolute;
            top: -345px;
            left: -164px;
            display: none;
            &::after {
                content: '';
                width: 0;
                height: 0;
                border-left: 26px solid transparent;
                border-right: 26px solid transparent;
                border-top: 26px solid #fff;
                position: absolute;
                bottom: -26px;
            }
            .idvTitle {
                font-size: 20px;
                font-weight: 600;
                color: #002095;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    margin-top: -5px;
                    padding: 0 23px;
                    &::before {
                        content: '>';
                        font-size: 50px;
                        color: #002095;
                    }
                }
            }
            .contentText {
                font-size: 16px;
                color: #2e384d;
                margin: 0;
                padding: 0;
                font-weight: normal;
            }
            .idvPriceShowroom {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .sdvValue, .idvValue {
                    width: 121px;
                    height: 158px;
                    border-radius: 10px;
                    background-color: #f4f6fc;
                    padding: 20px 10px;
                    .sdv {
                        font-size: 16px;
                        text-align: center;
                        color: #2e384d;
                        margin: 0;
                        font-weight: normal;
                        span {
                            font-weight: bold;
                        }
                    }
                    h4 {
                        padding-top: 20px;
                        font-size: 20px;
                        font-weight: bold;
                        text-align: center;
                        color: #00ba67;
                    }
                }
                .idvValue {
                    width: 170px;
                    h4 {
                        color: #ff6359;
                    }
                }
            }
        }
        &:hover .idvHover {
            display: block;
        }
    }
}