.customerWhySell {
    margin-top: 35px;
    h3 {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #2e384d;
    }
    .offersList {
        margin: 56px 0 80px 0;
        ul {
            display: flex;
            justify-content: space-around;
            li {
                list-style: none;
                text-align: center;
                p {
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0.75px;
                    color: #2e384d;
                    margin-top: 14px;
                    padding: 0;
                 }
            }
        }
    }
}
.heading {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #2e384d;
    text-align: center;
    margin: 0px 0 40px 0;
}