.paymentInitiateTokenPopup {
    width: 500px;
    height: 600px;
    .imageWrapper {
        position: relative;
        height: 100%;
        background-color: #c3c3c3;
        margin: auto;
        img {
            max-width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }
}