.targetAchieveBar {
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(0, 55, 255, 0.05);
    border: solid 2px #e0e7ff;
    background-color: #ffffff;
    min-height: 435px;
    padding: 20px;
    .targetAchieveBarHeader {
        display: flex;
        .date {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 600;
            color: #2e384d;
            flex: 0.2;
        }
        .targetSecttion {
            flex:0.6;
            align-items: center;
            justify-content: center;
            text-align: center;
            h2 {
                margin: 0;
                padding: 0;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.5px;
                color: #465166;
            }
            span {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.5px;
                color: #003ea8;
                padding-top: 8px;
                display: block;
            }
        }
    }
    .progressBarWrapper {
        margin-top: 150px;
        .progressbar {
            height: 10px;
            background-color: #e0e7ff;
            border-radius: 10px;
            position: relative;
            .beforeEighty {
                position: relative;
                width: 10%;
                &::before {
                    content: '';
                    background-color: #069372;
                    border-radius: 10px;
                    left: 0;
                    height: 10px;
                    width: 100%;
                    position: absolute;
                }
                .youSection {
                    position: absolute;
                    top: -15px;
                    right: -67px;
                    display: flex;
                    flex-wrap: wrap;
                    width: 96px;
                    align-items: center;
                    justify-content: center;
                    .you {
                        width: 40px;
                        height: 40px;
                        background-color: #fff;
                        border: solid 2px #7cd9b0;
                        color: #000;
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0;
                    }
                    .infoSection {
                        display: none;
                        text-align: center;
                    }
                }
            }
            .beforeEighty.eighty, .beforeEighty.ninety, .beforeEighty.hundred, .beforeEighty.hundredPlus {
                .infoSection {
                    display: block;
                }
                .dataSection {
                    background-color: #fdf0cc;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/tringle.svg');
                    }
                    p {
                        color: #2e384d;
                    }
                    span {
                        color: #ed6c0d;
                    }
                }
            }
            .beforeEighty.ninety, .beforeEighty.hundredPlus {
                .infoSection {
                    width: 140px;
                    min-height: 44px;
                    left: -25%;
                    p {
                        display: none;
                    }
                }
                .dataSection {
                    width: 96px;
                    min-height: 60px;
                    background-color: #e5f8ef;
                    border-radius: 15px;
                    position: relative;
                    margin-top: 50px;
                    padding:2px;
                    text-align: center;
                    &::before {
                        content: '';
                        position: absolute;
                        top: -56px;
                        background-image: url('../../components/images/youTringle.svg');
                        background-repeat: no-repeat;
                        width: 26px;
                        height: 57px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                    p {
                        border-radius: 15px;
                        background-color: #fff;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        color: #2e384d;
                        text-align: center;
                        height: 30px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        margin: 0;
                    }
                    span {
                        font-size: 14px;
                        font-weight: bold;
                        color: #049372;
                        text-align: center;
                        margin: 0;
                        padding-top: 2px;
                    }
                    
                }
            }
            .eighty {
                width: 43%;
            }
            .ninety {
                width: 55%;
            }
            .hundred {
                width: 75%;
            }
            .hundredPlus {
                width: 90%;
            }
            .moreCars30, .moreCars60 {
                color: #003fb8;
                font-size: 12px;
                font-weight: bold;
                margin: 0;
                height: 29px;
                border-radius: 10px;
                border: solid 2px #e0e7ff;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -10px;
                left: 20%;
                padding: 0 8px;
            }
            .moreCars60 {
                left: 60%;
            }
            .dataSection {
                width: 96px;
                min-height: 60px;
                background-color: #e5f8ef;
                border-radius: 15px;
                position: relative;
                margin-top: 50px;
                padding:2px;
                text-align: center;
                &::before {
                    content: '';
                    position: absolute;
                    top: -56px;
                    background-image: url('../../components/images/youTringle.svg');
                    background-repeat: no-repeat;
                    width: 26px;
                    height: 57px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                p {
                    border-radius: 15px;
                    background-color: #fff;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    color: #2e384d;
                    text-align: center;
                    height: 30px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    margin: 0;
                }
                span {
                    font-size: 14px;
                    font-weight: bold;
                    color: #049372;
                    text-align: center;
                    margin: 0;
                    padding-top: 2px;
                }
            }
            .infoSection {
                width: 151px;
                min-height: 103px;
                border-radius: 15px;
                background-color: #e5f7ef;
                position: absolute;
                top: auto;
                left: -30%;
                right: 0;
                bottom: 155px;
                margin: auto;
                padding: 10px;
                p {
                    font-size: 13px;
                    color: #049372;
                    margin: 0;
                    padding: 0;
                }
                .targetAchieved {
                    position: relative;
                    font-weight: bold;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        background-image: url('../../components/images/tokenDone.svg');
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                    }
                }
                h3 {
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    color: #000000;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 10px;
                    margin: auto;
                }
            }
            .defaultEighty, .defaultHundred {
                position: absolute;
                left: 40%;
                top: -15px;
                z-index: 1;
                text-align: center;
                .dataSection {
                    background-color: #fdf0cc;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/tringle.svg');
                    }
                    span {
                        color: #ed6c0d;
                    }
                }
            }
            .defaultHundred {
                left: 75%;
            }
        }
    }
    .viewDetailsWrapper {
        margin-top: 160px;
    }

}
.targetdone {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 5px;
        background-image: url('../../components/images/tokenDone.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        bottom: 0;
        margin: auto;
    }
}