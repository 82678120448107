.areaGraph{
  width: 630px;
  margin: auto;
  position: relative;
  .roadPrice {
    position: absolute;
    top: 0px;
    left: 116px;
    transform: rotate(-90deg);
    font-size: 15px;
    font-weight: bold;
    margin: auto;
    bottom: 0;
  }
  .recharts-wrapper {
    margin: auto;
  }
  svg.recharts-surface tspan {
    font-size: 15px !important;
    color: black !important;
    padding-right: 100px !important;
  }
  .years{
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }
}