
.apntmntLink a {
  text-decoration: none;
  color: #0037ff;
}
.apntmntLink a:hover{
  text-decoration: none;
  color: #0037ff;
}
.apntmntLink p {
  margin: 0;
  padding: 0;
}