.sliderCircle{
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 4px solid rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 16px 0px;
}
.sliderCircle::before, .sliderCircle::after{
    content: "";
    position: absolute;
    left: -16px;
    top: -16px;
    border-radius: 60px;
    height: 40px;
    width: 40px;
    z-index: 1;
    opacity: 0;
    border: 5px solid #f8931f;
    background: transparent;
}
