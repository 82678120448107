.weekFilters {
    margin-left: 10px;
  }
  .weekFilters ul {
    margin: 0;
    padding: 0;
    display: flex;
  }
  .weekFilters ul li {
    font-size: 12px;
    font-weight: bold;
    color: #2e384d;
    background-color: #f0f4ff;
    min-width: 150px;
    min-height: 33px;
    border-radius: 16.5px;
    border: solid 1px rgba(0, 55, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    cursor: pointer;
    &:hover, &.active {
      background-color: #0037ff;
      color: #ffff;
    }
  }
  