.allocateTitle {
    font-size: 14px;
    font-weight: bold;
    color: #2e384d;
    letter-spacing: 1px;
    position: relative;
    padding: 0px;
    margin-left: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    cursor: pointer;

    &:hover, &.titleActive {
        color: #0037ff;
        &::before {
            content: '';
            border: 1px solid #0037ff;
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 70px;
        }
    }
}