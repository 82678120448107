.leadQuoteDetail {
    margin-top: 15px;
    border-radius: 8px;
    border: 1px solid #e0e7ff;
    padding: 15px 0 38px 30px;
    background-color: #fff;
    .textWrapper {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        padding-top: 32px;
        .detailsLeftWrapper {
            flex: 0.6;
            display: flex;
            justify-content: space-between;
            .useQuota {
                margin-right: 40px;
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    color: #333;
                }
            }
        }
        .detailsRightWrapper {
            flex: 0.4;
            position: relative;
            .loader {
                position: absolute;
                top: 12px;
                right: 8px;
                margin: 0;
            }
        }
    }
    .offer {
        font-size: 14px;
        color: #0037ff;
        padding: 3px 0 0 30px;
        cursor: pointer;
        position: relative;
        font-weight: 500;
        margin-top: 10px;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            background-image: url("../images/offer.svg");
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            background-size: 100%;
        }
    }
}


