.dealerOfferPrice {
    min-height: calc(100vh - 66px);
    background-color: #fff;
}
.bookDetailwrapper {
    background: #FFFFFF;
    box-shadow: 0px 0.5px 1px rgba(126, 133, 148, 0.15);
    padding: 12px 20px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
}
.bookDetailwrapper p {
    margin-bottom: 0;
}
.bookDetailwrapper h1 {
    margin: 0 0 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #465166;
}
.bottomText {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #465166;
}