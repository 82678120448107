.popupWrap{
    display: block;
    max-width: 255px;
    margin: 0 auto;
    min-height: 400px;
}
.popupWrap h2{
    padding-bottom: 12px;
    margin: 0;
}
.customerDesc{
    display: block;
    margin: 0;
    padding-bottom: 24px;
    padding-left: 0;
}
.customerDesc li{
    display: inline-block;
    list-style-type: none;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.67);
}
.customerDesc li::after {
    content: "|";
    margin: 0 5px;
}
.customerDesc li:last-child::after {
    content: "";
}
.getQuote{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.getQuote button{
    width: 143px;
    height: 48px;
    display: inline-block;
}
.getQuote p{
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 30px 0 5px;
    font-size: 10px;
    color: #2e384d;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}
.text, .priceText{
    font-size: 12px;
    color: #2e384d;
    letter-spacing: 2px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}

.priceText {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 15px;
}
.closeModal {
    background: none;
    border:none;
    outline: none;
    width: 190px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    color: #2e384d;
}
.closeModal:focus {
    outline: none;
}
.greenText, .orengeText, .redText {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    padding-top: 14px;
}
.greenText {
    color: #44ca6f;
}
.orengeText {
    color: #f77000
}
.redText {
    color: #fa0000;
}
.textPai {
    color: #2e384d;
    font-size: 14px;
    margin: 0;
    padding-top: 17px;
}
.textLabel {
    color: #2e384d;
    font-size: 12px;
    margin: 0px;
}
:global .MuiFormControl-marginDense {
    margin-bottom:0 !important;
}
:global .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #003fb8 !important;
}
.quoteText {
    margin-top: 16px;
    margin-bottom: 24px;
}
.textField {
    margin-top: 40px;
}
.selectOwnership {
    margin-top: 20px;
}
.lowMargin, .negative {
    font-size: 12px;
    position: relative;
    margin: 5px 0 0 0;
    padding-left: 20px;
    display: block;
}
.lowMargin::before, .negative::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
}
.lowMargin {
    color: #f5ae4d;
}
.lowMargin::before {
    background-image: url('../../components/images/lowmargin.svg');
}
.negative {
    color: #e02020;
}
.negative::before {
    background-image: url('../../components/images/negative.svg');
}
.radioButton {
    display: flex;
    align-items: center;
  }
.sumbmitPR{
    margin-top: 30px;
    display: flex;
   justify-content: center;  
}
.errorMsg {
    color: #0037FF;
    font-size: 14px;
    margin: 0;
    padding: 0;
}
.errorMsg span {
    font-weight: 600;
}