.reasonDealLost {
    border-bottom: 1px solid #E0E7FF;
    padding-bottom: 30px;
    margin-bottom: 30px;
    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5625px;
        color: #DE350B;
        margin-bottom: 28px;
    }
    span {
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        color: #B0BAC9;
    }
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #2E384D;
        margin: 3px 0 0 0;
        padding: 0;
    }
}