

.dataList {
	padding: 0 40px;
  }
  .dataList table {
	border-spacing: 0 8px;
	border-collapse: unset;
	position: relative;
	margin-top: 60px;
  }
  .dataList table tr:first-child:hover {
	box-shadow: none;
  }
  .dataList table tr th {
	font-size: 12px;
	color: #2e384d;
	letter-spacing: 2px;
	font-weight: 600;
	text-transform: uppercase;
	cursor: default;
	padding: 0 10px 0 0px;
	z-index: 2;
	margin: 0;
	position: sticky;
	top: 60px;
	min-width: 10ch;
  }
  .dataList table tr th p {
	margin: 0;
  }
  .dataList table tr th .tableHeading {
	white-space: nowrap;
	max-width: 20ch;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
  }
  .dataList table thead tr {
	position: relative;
	height: 44px;
  }
  .dataList table thead tr::after {
	content: "";
	box-shadow: 0px 30px 25px -10px #0037ff29;
	background: #f4f6fc;
	position: fixed;
	left: 3%;
	right: 3%;
	height: 50px;
	top: 60px;
	z-index: 1;
  }
  
  .dataList table tr td {
	color: #2e384d;
	font-size: 12px;
	background: #fff;
	min-height: 48px;
	border: 0;
	letter-spacing: 0.5px;
	padding: 10px 10px 10px 0px;
	border: solid 1px transparent;
	box-shadow: none;
	white-space: nowrap;
	max-width: 20ch;
	/* overflow: hidden;		 */
	text-overflow: ellipsis;
  }
  .dataList table tr td span {
	white-space: nowrap;
	max-width: 20ch;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
  }

  