.calLogTextWrapper {
    flex: 1;
    padding-left: 15px;
    .logtitleSection {
        padding-bottom: 10px;
        display: flex;
        .logTitle, .logTime {
            margin: 0;
            padding: 0;
            letter-spacing: 1.33px;
            font-weight: 600;
            font-size: 16px;
            color: #2e384d;
            text-transform: uppercase;
            margin-right: 20px;
        }
        .logTime {
            opacity: 0.7;
        }
    }
    .callLogStaticData {
        display: flex;
        align-items: center;
        .text {
            font-size: 12px;
            opacity: 0.5;
            color: #2e384d;
            padding-right: 15px;
            margin: 0;
        }
        img {
            padding-right: 15px;
        }
    }
}
