.evaluatorStoreTabs {
    margin-top: 30px;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        li {
            list-style: none;
            background-color: #fff;
            border: 1px solid #e0e7ff;
            border-radius: 8px;
            padding: 8px 0px;
            margin: 5px 10px;
            font-weight: 400;
            letter-spacing: .5px;
            font-size: 14px;
            width: 208px;
            font-weight: bold;
            text-align: center;
            span {
                display: flex;
                justify-content: center;
            }
        }
    }
}