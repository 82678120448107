.infoWrapper {
    position: absolute;
    top: 10px;
    right: 45px;
    z-index: 2;
}
.info {
    width: 18px;
    height: 18px;
    border: solid 1px rgba(0, 55, 255, 0.08);
    background-color: #ffffff;
    border-radius: 100px;
    color: #0037ff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 2px;
    cursor: pointer;
}
.info i{
    font-style: normal;
    transform: rotate(180deg);
}
.infoTextWarpper {
    position: absolute;
    z-index:  9999 !important;
    width: 160px;
    bottom: 30px;
    left: -117px;
    padding: 5px 10px;
    border-radius: 8px;
    border: solid 1px rgba(0, 55, 255, 0.08);
    background-color: #ffffff;
    display: none;
}
.infoText {
    color: #2e384d;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: normal;
    text-transform: none;
    position: relative;
    font-weight: normal;
}
.infoText::after {
    content: '';
    position: absolute;
    bottom: -17px;
    right: 0px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
}
.info:hover .infoTextWarpper {
    display: block;
}