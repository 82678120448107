.congratulationsSelfAppointment {
    padding: 80px 15px 0;
    height: 100vh;
    .mainImage {
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .checkinText {
        font-size: 18px;
        color: #465166;
        font-weight: 500;
        text-align: center;
        padding-top: 20px;
        margin-bottom: 20px;
    }
    .subText {
        font-size: 14px;
        color: #465166;
        text-align: center;
        margin: 0;
    }
    .appid {
        border: 1px solid #dde5eb;
        box-shadow: 0 4px 10px rgba(0, 89, 163, 0.1);
        min-height: 130px;
        border-radius: 10px;
        margin-top: 30px;
        .id {
            margin-top: -4px;
            span {
                background-color: #0059a3;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-top-left-radius: 10px;
                padding: 2px 15px;
                color: #fff;
                font-size: 12px;
                margin: 0;
            }
            
        }
        .storeList {
            margin-top: 12px;
            padding: 0 20px;
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    font-size: 12px;
                    color: #465166;
                    display: flex;
                    align-items: center;
                    padding-bottom: 12px;
                    p {
                        margin: 0;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}