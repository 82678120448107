.bikeInitiatePaymentPopup {
    min-width: 400px;

    .carDataList {
        padding: 15px 0 0 0;
        margin-bottom: 20px;
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.58px;
                color: #2e384d;
                opacity: 0.51;
                position: relative;
                padding: 0 10px 0 0;
                margin-right: 6px;
                text-transform: capitalize;
                &::after {
                    content: '|';
                    position: absolute;
                    top: 0;
                    right: -1px;
                }
                &:last-child {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
    .inputWrapper {
        margin: 0 0 40px 0;
    }
    .textHeading {
        font-size: 14px;
        color: #000;
        margin: 0;
        padding: 0;
    }
    .subText {
        display: block;
        font-size: 8px;
        color: #979797;
        margin-bottom: 15px;
    }
    .error{
        color: red;
    }
}
.inputWrapper :global .input-text_inputShift__K7ipV input {
    text-transform: uppercase;
}