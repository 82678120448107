.wrapper{
    height: 800px;
    overflow-y: scroll;
    background: radial-gradient(93.63% 93.63% at 50.02% 6.37%, #0071BC 0%, #113167 50%, #001B4B 75%, #000035 100%);
    border: 1px solid rgba(28, 134, 198, 0.12);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07);
    // border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.congratulations{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01rem;
    color: #FFFFFF;
    background: rgba(243, 247, 249, 0.2);
    border: 1px solid rgba(221, 229, 235, 0.1);
    border-radius: 32px;
    padding: 4px 8px 4px 4px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.congratulations img {
    display: block;
    margin-right: 8px;
}
.price{
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 6px;
}
.thankyou {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    opacity: 0.7;
}