.title {
    padding-top:20px;
    margin: 0;
  }
  .headingSearchWrapper {
    padding: 0px 40px 10px 40px;
    display: flex;
    justify-content: space-between;
    background: #f4f6fc;
    box-shadow: 0 10px 10px 0px rgba(0, 55, 255, 0.15);
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 4;
  }
  .negotiationList {
    margin-top: 130px
  }
  .searchSection {
    margin-top: 15px;
    display: flex;
  }
  .lostHeading {
    background-color: #f4f6fc;
    position: fixed;
    top: 0px;
    z-index: 1;
    left: 20px;
    right: 0;
    padding-top: 85px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .pageDirection {
    font-size: 10px;
    color: #465166;
    margin: 0;
    padding-left: 5px;
    text-transform: capitalize;
  }
  .lostHeading :global .h2_heading__3PuHp {
    margin: 0;
    padding-left: 10px;
  }