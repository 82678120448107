.storeList {
    padding-bottom: 32px;
    min-height: 300px;
    max-height: 300px;
    overflow: auto;
    ul {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        margin:0;
        padding: 0;
        li {
            background-color: #f4f6fc;
            border:1px solid #e0e7ff;
            border-radius: 8px;
            padding: 8px 10px;
            margin: 5px 5px;
            font-weight: normal;
            letter-spacing: 0.5px;
            font-size: 10px;
            width: 150px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover, &.active {
                border-color: #0037ff;
                color: #0037ff;
            }
        }
    }
    .storeWrapper {
        // display: flex;
        justify-content: space-between;
        margin: 30px 20px 0 0;
        .checkboxWrapper {
            margin: 10px 0 5px 10px;
            display: flex;
            align-items: center;
        }
        span.storeTitle {
            font-weight: 600;
            color: #2e384d;
            font-size: 12px;
            display: block !important;
            margin-left: 10px !important;
            padding: 0 !important;
            text-align: left;
        }
    }
    .searchWrapper {
        display: flex;
        justify-content: space-between;
    }
    .selectAll {
        display: flex;
        margin: 10px;
        span.text {
            padding-top: 6px !important;
        }
    }
}
  .listSaveButton {
      margin-top: 10px;
      position: absolute;
      bottom: 16px;
      right: 20px;
      button{
        line-height: 28px;
      }
  }
  .clearfix{
      clear: both;
  }
.textMe{
    margin: auto;
}