.negotiationWrapper {
    padding: 0 40px;
}

.cardWrapper {
    border-radius: 8px;
    border: solid 1px rgba(0, 55, 255, 0.08);
    background-color: #ffffff;
    height: auto;
    display: block;
    padding: 15px 10px;
    overflow: hidden;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    position: relative;
}

.cardWrapper_hypoDsi{
  background: linear-gradient(270deg, #FAC22A 0%, #F8BD1C 48%, #FFE8AA 80%, #F9BC16 100%);
}

.priceNegotiaton {
    border-left: 1px solid #e0e7ff;
    border-right: 1px solid #e0e7ff;
    // display: flex; 
    justify-content: center;
    align-items: center;
    width: 22%;
    padding: 0 10px;
}

.priceNegotiaton>div {
    width: 100%;
}

.priceOffersWrapper {
    text-align: center;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    border-right: 1px solid #e0e7ff;
}

.quotesWrap {
    text-align: center;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.quotesWrap :global .ra-dealer-chat_headerchat__25Rbu {
    display: none;
}

.imageandDetailsWrapper {
    display: flex;
    align-items: center;
    width: 40%;
    position: relative;
}

.carImageDetail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    align-items: flex-start;

}

.carTags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    width: 100%;
}

.homeInspection,
.sellOnline,
.sohiText {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #2E384D;
    padding: 3px 10px;
    margin: 0;
    background-color: #CCF1E1;
    border-radius: 16px;
    margin: 5px;
}

.sohiWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.leadMon {
    background: #00BA67;
    border-radius: 71px;
    color: #fff;
    text-align: center;
    width: 70px;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
}

.sellOnline {
    background-color: #FDF0CC;
}

.sohiText {
    word-spacing: 12px;
    position: relative;
    background-color: #CCE9FF;
    padding: 3px 12px !important;
    max-width: 70px !important;
    min-width: 70px;
}

.sohiText::after {
    content: '';
    background-image: url('../images/sohiIcon.svg');
    background-repeat: no-repeat;
    width: 11px;
    height: 6px;
    position: absolute;
    top: 0;
    // right: 7%;
    bottom: 0;
    left: 45%;
    margin: auto;
}

.repeatUser {
    display: block;
    background: linear-gradient(270deg, #FAC22A 0%, #F8BD1C 48%, #FFE8AA 80%, #F9BC16 100%);
    border-radius: 71px;
    text-align: center;
    width: 90px;
    height: 20px;
    font-weight: 700;
    font-size: 12px;
    color: black;
    margin: 5px;
}

.hypoAutoApproved {
    background: #D97AE1;
    border-radius: 71px;
    color: #fff;
    text-align: center;
    // width: 70px;
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    margin: 5px;
    padding: 0px 8px;
    text-wrap: nowrap;
    min-width: 70px;
}

.auctionLabel {
    background: yellowgreen;
    border-radius: 71px;
    color: #fff;
    text-align: center;
    // width: 70px;
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    margin: 5px;
    padding: 0px 8px;
    text-wrap: nowrap;
    min-width: 70px;
}

.retargetLabel {
    background: rgb(205, 50, 94);
    border-radius: 71px;
    color: #fff;
    text-align: center;
    // width: 70px;
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    margin: 5px;
    padding: 0px 8px;
    text-wrap: nowrap;
    min-width: 70px;
}

.directocb {
    display: block;
    background: rgb(210, 130, 153);
    border-radius: 71px;
    color: #fff;
    text-align: center;
    // width: 70px;
    height: 20px;
    font-weight: 400;
    font-size: 12px;
    margin: 5px;
    padding: 0px 8px;
    text-wrap: nowrap;
    min-width: 70px;
}

.fixWrapper {
    position: relative;
}

.fixWrapper::after {
    content: '';
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0.6;
    cursor: not-allowed;
}

.fixWrapper :global .transparentBtn.ctaRight {
    position: absolute;
    z-index: 2;
    width: auto;
    left: 60%;
    bottom: 28px;
}

.fixWrapper :global .quote_cta__3j7U0 {
    height: 40px;
}

.fixWrapper :global .quote_cta__3j7U0 .primaryCta {
    position: absolute;
    z-index: 2;
    margin: auto;
    width: 90%;
}

.carDetail {
    flex: 1;
}

.doneToken {
    background-color: #000b23d4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doneToken span {
    background-color: #fff;
    border: solid 2px #00ba67;
    border-radius: 10px;
    height: 60px;
    font-size: 24px;
    font-weight: 600;
    color: #00ba67;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    padding: 0 30px;
}

.doneToken span img {
    margin-right: 15px;
}

.qualityCallWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
        display: block;
    }

    .callConnected,
    .notCalled,
    .callnotConnected,
    .missedCall {
        font-size: 10px;
        letter-spacing: 0.42px;
        margin: 0;
        padding-left: 35px;
        position: relative;

        &::before {
            content: '';
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            bottom: 0;
            margin: auto;
        }
    }

    .callConnected {
        color: #00ba67;

        &::before {
            background-image: url('../../components/images/callLogGreen.svg');
        }
    }

    .notCalled {
        color: #0037ff;

        &::before {
            background-image: url('../../components/images/callLogNewBlue.svg');
        }
    }

    .callnotConnected {
        color: #e02020;

        &::before {
            background-image: url('../../components/images/callLogRed.svg');
        }
    }

    .missedCall {
        color: #e02020;

        &::before {
            background-image: url('../../components/images/missedCall.svg');
        }
    }

    .qualityCall {
        margin: 0;
        padding: 7px 10px;
        background-color: #f3f5ff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        color: #00ba67;
        position: relative;
        padding-left: 38px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            bottom: 0;
            margin: auto;
            background-repeat: no-repeat;
            background-image: url('../../components/images/tokenDone.svg');
            width: 20px;
            height: 20px;
        }
    }
}
.watermark_div{
  position: absolute;
}
.watermark{
  position: absolute;
  z-index: 0;
  opacity: .1;
  transform: rotate(-45deg);
  font-size: 20px;
}