.tableHeading {
  white-space: nowrap;		
  max-width: 25ch;		
  overflow: hidden;		
  text-overflow: ellipsis;
  margin: 0;
}
.ascending, .descending {
  width: 11px;
  height: 6px;
  background-image: url('../../images/arrow.svg');
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}
.descending {
  transform: rotate(180deg);
}
.m0 {
  margin: 0;
}