.customerJourneyCard {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    .card {
        border-radius: 17px;
        box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
        background-color: #ffffff;
        width: 32%;
        min-height: 200px;
        position: relative;
        img  {
            position: absolute;
            top: 10px;
            left: 10px;
        }
        h3 {
            color: #0091ff;
            font-size: 50px;
            font-weight: bold;
            font-family: "Open Sans";
            text-align: center;
            padding: 44px 0 22px 0;
        }
        p {
            font-size: 16px;
            font-weight: normal;
            text-align: center;
            color: #2e384d;
            margin: 0;
            padding: 0;
            width: 190px;
            margin: auto;
        }
        .orangeColor{
            color: #f7b500;
        }
        .auctionRunning {
            font-size: 20px;
            margin-top: 10px;
        }
        .chart {
            width: 400px;
            margin: auto;
            // height: 160px;
            // margin: -15px auto 0;
        }
    }
}
:global .recharts-sector {
    position: relative;
    &::before {
        content: '';
        width: 4px;
        height: 4px;
        background-color: #000;
        top: 0;
        right: 0;
        position: absolute;
    }
    &::after {
        content: '';
        width: 4px;
        height: 4px;
        background-color: #000;
        top: 0;
        left: 0;
        position: absolute;
    }
}
.topLabel {
    line-height: 30px;
    height: 30px;
    tspan {
        font-size: 20px !important;
        color: #0059a3 !important;
        line-height: 30px;
        height: 30px;
    }
}
.minutes {
    font-size: 12px !important;
    margin-top: 10px;
    line-height: 30px;
    height: 30px;
    color: #0059a36b !important;
}
.mt10 {
    margin-top: 10px !important;
}
.graph {
    width: 125px;
    height: 125px;
    margin: 20px auto 0;
    border: 8px solid #ed6c0d;
    border-radius: 100%;
    .runningTime {
        font-size: 24px !important;
        font-weight: bold !important;
        text-align: center !important;
        color: #0059a3 !important;
        margin: 26px 0 3px 0;
    }
    .timeRemaining {
        opacity: 0.7;
        font-size: 12px;
        color: #0059a3;
        width: 100px;
        margin: auto;
        text-align: center;

    }
}