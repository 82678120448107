.welcomeSelfAppointment {
    padding: 80px 15px 0;
    height: 100vh;
    .mainImage {
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .checkinText {
        font-size: 16px;
        color: #0059a3;
        font-weight: 500;
        text-align: center;
        padding-top: 20px;
        margin: 0;
    }
    .welcomeSubText {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        color: #0059a3;
        text-align: center;
        margin: 0;
    }
    .phoneNo {
        font-size: 16px;
        color: #465166;
        font-weight: 500;
        margin: 0;
        line-height: 20px;
        width: 200px;
        padding-top: 30px;
    }
    .phoneNumber {
        margin-top: 25px;
        .textDanger {
            color: #dc3545;
            font-size: 12px;
            font-weight: normal;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
    .ctaWrapper {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.phoneNumber :global .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ed6a00 !important;
}
.phoneNumber :global .MuiFormLabel-root.Mui-focused {
    color: #ed6a00 !important;
}