.inspectionWrap {
  display: flex;
  margin-top: 22px;
}
.inspReportPopup {
  width: 450px; 
}
.carDetailsReport {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
}
.carDetailsReport .modelDetails, .carDetailsReport .appointmentid {
  font-size: 16px;
  font-weight: 600;
  color: #2e384d;
  margin: 0;
  padding: 0;
}
.carDetailsReport .appointmentid {
  font-weight: normal;
  padding-top: 5px;
}
.carInfo {
  flex: 0.9;
}
.suspended, .auctionOver, .auctionRuning {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 0;
}
.suspended {
  color: #ff6359;
}
.auctionOver {
  color: #f5ae4d;
}
.auctionRuning {
  color: #44ca6f;
}
.reportsTab {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      list-style: none;
      font-size: 18px;
      font-weight: 600;
      color: #2e384d;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-right: 80px;
      padding-bottom: 5px;
      &:hover,  &.active {
        color: #0037ff !important;
        border-bottom: 2px solid #0037ff !important;
        cursor: pointer;
      }
    }
  }
}