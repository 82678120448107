.documentUpload {
    padding: 80px 40px 0 40px;
    .documentHeading {
        display: flex;
        align-items: center;
        .back {
            margin-right: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        small {
            font-size: 10px;
            color: #465166;
            span {
                color: #0037ff;
            }
        }
    }
    .documentTitle {
        display: flex;
        align-items: center;
        text-align: center;
        width: 920px;
        justify-content: space-around;
        margin-top: 50px;
        position: relative;
        &::before {
            content: '';
            border-top: 1px solid #0037ff;
            position: absolute;
            top: 20px;
            left: 25%;
            right: 0;
            width: 50%;
            z-index: -1;
        }
        p {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.56px;
            color: #2e384d;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 150px;
            span {
                width: 40px;
                height: 40px;
                background-color: #0037ff;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0.4px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
        }
    }
    .documentskycSection {
        // display: flex;
        // flex-wrap: wrap;
        margin-top: 25px;
        .documentKycWrapper, .bankDetailsWrapper, .vahanWrapper, .rejectionReason {
            border-radius: 8px;
            border: solid 1px #e0e7ff;
            background-color: #ffffff;
            min-height: 100px;
            padding: 30px;
            margin-bottom: 20px;
            h3 {
                font-size: 16px;
                color: #000;
                margin: 0;
                padding: 0 0 20px 0;
            }
            .secondaryWrapper {
                display: flex;
                align-items: center;
                margin-top: -10px;
                h4 {
                    margin: 0;
                    padding: 0 20px 0 0;
                    font-size: 14px;
                    color: #000;
                }
                p {
                    font-size: 12px;
                    margin: 0;
                    color:  #00BA67;
                    font-weight: bold;
                }
            }
            .smallText {
                color: #979797;
                font-size: 8px;
                padding-bottom: 15px;
                display: block;
                margin-top: -4px;
            }
        }
        .vahanWrapper {
            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
                margin: 0 0 30px 0;
                padding: 0;
                span {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    color: #09C262;
                    padding-left: 10px;
                }
            }
        }
        .rejectionReason {
            ul {
                margin: 0 0 0 16px;
                padding: 0;
                li {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #DE350B;
                    padding: 5px 0;
                }
            }
        }
        .prWrapper {
            margin-top: 15px;
            display: grid;
            grid-template-columns: 150px 50%;
            p {
                margin: 0;
                padding: 0;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                opacity: 0.6;
            }
            span {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                display: block;
            }
        }
        .documentKyc, .bankDetails, .documentKyc50, .documentKycUpload {
            display: flex;
            .withoutkyc, .panCard {
                width: 100%;
                margin-right: 15px;
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }
            }
            .panCard :global .form-control{
                text-transform: uppercase;
            }
            .kyc, .kycUpload {
                padding-bottom: 15px;
                margin-bottom: 15px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 0 20px 0 0;
                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    color: #2E384D;
                    margin: 0;
                }
                .title {
                    font-size: 16px;
                    color: #2e384d;
                    margin: 0;
                    padding: 0;
                }
                
                .uploadedImage {
                    background-color: #e0e7ff;
                    width: 74px;
                    height: 60px;
                    border-radius: 3px;
                    position: relative;
                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                    }
                    span.close {
                        background-color: #0037ff;
                        width: 24px;
                        height: 24px;
                        border-radius: 100%;
                        color: #fff;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        z-index: 1;
                        right: -12px;
                        top: -12px;
                        cursor: pointer;
                    }
                }
            }
            .kycUpload {
                width: 23%;
                margin-top: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
            .chequeWrapper {
                display: flex;
                align-items: center;
                margin-top: 40px;
                p {
                    margin: 0;
                    margin: 0;
                    font-size: 16px;
                    color: #2E384D;
                }
                .imageUpload {
                    border: 1px dashed #0037ff;
                    padding: 5px 15px;
                    border-radius: 5px;
                    margin-left: 30px;
                    input {
                        display: none;
                    }
                    span {
                        font-size: 12px;
                        font-weight: 600;
                        color: #0037ff;
                        padding-left: 10px;
                        cursor: pointer;
                    }
                }
            }
            .kyc {
                justify-content: unset !important;
                align-items: center;
                margin-bottom: 15px;
            }
        }
        .documentKyc50 {
            width: 50%;
            margin-bottom: 20px;
        }
        .documentKycUpload {
            display: block;
        }
        
    }
    .ctaWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 36px;
        padding-bottom: 40px;
        flex: 100%;
        h4 {
            font-size: 14px;
            font-weight: 300;
            color: #000000;
        }
        button {
            width: 190px;
            height: 47px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .error{
        color: red;
    }
    .holdbacksChargesContainer{
        width: 100%;
        border-radius: 8px;
        border: solid 1px #e0e7ff;
        background-color: #ffffff;
        min-height: 300px;
        margin-top: 36px;
        .titleSubtile {
            margin: 22px 0 36px 36px;
            h3 {
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0.5625px;
                color: #2E384D;
            }
            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #205C8C;
                position: relative;
                padding-left: 30px;
                margin-top: 16px;
                &::before {
                    content: '';
                    background-image: url('../../components/images/info.svg');
                    background-repeat: no-repeat;
                    width: 18px;
                    height: 18px;   
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;                 
                }
            }
        }
        .blueCard{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            background: #0059A3;
            padding: 0 65px;
            span {
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 1.28571px;
                color: #FFFFFF;
            }
        }
        .lightBlueCard{
            background: #F4F6FC;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            margin: 0 35px;
            span {
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 1.28571px;
                color: #205C8C;
            }
        }
        .heading{
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 1.28571px;
            color: #205C8C;
            margin-top: 16px;
            padding-left: 68px;
            position: relative;
            height: 24px;
            &::after {
                content: '';
                height: 2px;
                background-color: #205C8C;
                width: 80%;
                position: absolute;
                top: 50%;
                right: 0;
                bottom: 0;
                margin: auto;
            }
            span {
                background-color: #fff;
                padding-right: 15px;
                position: absolute;
                left: 65px;
                z-index: 1;
            }
        }
        .inputWrapperContainer{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-right: 36px;
            margin-top: 30px;
            .inputWrapper{
                display: flex;
                width: 50%;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 36px;
                label {
                    display: none !important;
                }
                :global .input-text_perfect__O94xZ {
                    &::after {
                        content: '';
                        background-image: unset;
                    }
                }
            }
            .fieldName{
                font-weight: bold;
                font-size: 18px;
                color: #2E384D;
                text-transform: capitalize;
                margin-right: 24px;
                p {
                    margin: 0;
                }
            }
        }
        .deliverycard {
            margin-top: 36px;
            padding-bottom: 36px;
        }
    }
    .documentUploadSection {
        width: 100%;
        border-radius: 8px;
        border: solid 1px #e0e7ff;
        background-color: #ffffff;
        margin-top: 36px;
        h4 {
            font-size: 20px;
            color: #2E384D;
            margin: 0 0 0 0px;
            padding: 0 0 0 54px;
            position: relative;
            height: 67px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &::before {
                content: '';
                background-image: url('../../components/images/document.svg');
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                left: 24px;
                bottom: 0;
                margin: auto;
            }
        }
        .documentSubSection {
            h3 {
                font-size: 18px;
                color: #2E384D;
                margin: 0 0 0 0px;
                padding: 0 0 0 54px;
                position: relative;
                border-bottom: 2px solid #0037FF;
                height: 67px;
                display: flex;
                align-items: center;
                cursor: pointer;
                font-weight: bold;
                line-height: 23px;
                &::after {
                    content: '';
                    background-image: url('../../components/images/back-arrow.svg');
                    width: 14px;
                    height: 24px;
                    position: absolute;
                    top: 0;
                    right: 24px;
                    bottom: 0;
                    margin: auto;
                    transform: rotate(90deg);
                    cursor: pointer;
                }
            } 
            .customerForm {
                display: none;
                .uploadForm {
                    display: flex;
                    align-items: center;
                    margin: 30px 0;
                    h5 {
                        font-weight: 500;
                        font-size: 18px;
                        color: #465166;
                        margin: 0 20px 0 0;
                        padding: 0;
                        width: 250px;
                        text-align: right;
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        li {
                            list-style: none;
                            margin-right: 10px;
                        }
                    }
                }
            }
            &.active {
                h3 {
                    &::after {
                        content: '';
                        transform: rotate(-90deg);
                    }
                }
                .customerForm {
                    display: block;
                }
            }  
        }
        
    }
    .imageUpload, .upload {
        display: flex;
        align-items: center;
        margin-top: 16px;
        background-color: #E0E7FF;
        height: 40px;
        width: 111px;
        border-radius: 3px;
        position: relative;
        margin: 0 15px 0 0;
        label {
            height: 40px;
            width: 111px;
            display: flex;
        }
        img {
            cursor: pointer;
            max-width: 100%;
            max-height: 90%;
            margin: auto;
            text-align: center;
            justify-content: center;
            display: flex;
        }
        input {
            display: none;
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #0037ff;
            cursor: pointer; 
            position: absolute;
            bottom: -20px;
            left: auto;
            right: auto;
            margin: auto;
            text-align: center;
            display: block;
            width: 100%;
        }
    }
    .upload {
        height: 40px;
        width: 111px;
        background-color: #E0E7FF;
        border-radius: 3px;
        position: relative;
        margin: 0;
        label {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: -20px;
        }
        span {
            padding: 0;
        }
    }
    
}
.withoutkyc :global .input-text_perfect__O94xZ {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        background-image: url('../../components/images/rightRounded.svg');
        width: 20px;
        height: 20px;
    }
}
.kycUploadWrapper {
    .kycUpload {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 10px;
        h5 {
            font-size: 16px;
            color: #2E384D;
            margin: 0;
            padding: 0;
        }
        .kyc {
            margin-right: 15px;
        }
    }
}
.hypoLoan {
    margin-top: 60px;
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #000;
        width: 100%;
    }
    .RadioButtonWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
        label {
            font-size: 14px;
            margin-right: 30px !important;
        }
        .radioWrapper {
            margin-top: 10px;
            display: flex;
        }
    }
    .loanBankName {
        width: 309px;
        margin-top: 20px;
        & > div {
            margin-top: 15px;
        }
    }
    .nocWrapper {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}
.regNumber {
    padding: 0 10px;
    border: 1px solid hsl(0,0%,80%);
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    position: relative;
    background: transparent;
    color: #2e384d;
    font-weight: 500;
    box-shadow: none;
    font-size: 14px;
    background-color: #e9ecef;
    p {
        margin: 0;
        padding: 0;
    }
    span {
        color: #9aa1b5;
        font-size: 12px;
        top: -7px;
        left: 10px;
        line-height: 13px;
        height: 14px;
        width: auto;
        display: inline-block;
        border-radius: 0;
        z-index: 1;
        background: #fff;
        position: absolute;
    }
}