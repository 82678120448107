.notificationsWrapper {
    margin-left: 10px;
    position: relative;
    img {
        cursor: pointer;
    }
    .notificationsCountWrapper {
        position: relative;
        .notificationsCount {
            position: absolute;
            background-color: #E41717;
            min-width: 15px;
            min-height: 15px;
            border-radius: 100%;
            color: #fff;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -3px;
            right: -3px;
            padding: 2px;
        }
    }
    .notificationsList {
        display: none;
    }
    &:hover {
        .notificationsList {
            display: block;
        }
    }
}