.negoLeadFilters {
    // width: 100%;
    // border-top: 2px solid #E0E7FF;
    // padding: 8px 0 0 0;
    // margin-top: 14px;
    display: flex;
    justify-content: flex-end;
    &> div {
        width: 250px;
        margin-left: 10px;
    }
}