.pllAllocationFilters {
    margin-left: 10px;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        .textChild {
            color: #2e384d;
            font-size: 12px;
            font-weight: 600;
            list-style: none;
            letter-spacing: 1px;
            border: 1px solid rgba(0,55,255,.1);
            min-width: 140px;
            height: 32px;
            text-align: center;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            padding: 0 10px;
            cursor: pointer;
            position: relative;
            &:hover, &.active {
                color: #0037ff;
                border: 1px solid #0037ff;
            }
        }
        .selectChild {
            margin: 0 5px;
            padding: 0 10px;
            list-style:none;
            align-items: center;
            height: 32px;
            text-align: center;
            &:hover, &.active {
                color: #0037ff;
                border: 1px solid #0037ff;
                border-radius: 16px;
            }
            .noborderselect {
                border: none;
                outline: none;
                background-color: transparent;
                justify-content: center;
                margin-top: 7px;
                text-align: center;
            }
        }
    }
    
}