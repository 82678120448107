.headingSearchWrapper {
  padding: 0 40px 18px 40px;
  display: flex;
  justify-content: space-between;
  background: #f4f6fc;
  /* box-shadow: 0 0px 25px #0037ff7d; */
}
.onpageSerachWrapper {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  /* z-index: 9; */
}
.ppDataFilter {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
.onRightFilter {
  margin-right: 20px;
  width: 200px;
  height: 38px;
}
.title {
  margin: 0;
  display: flex;
  align-items: center;
}
.visitedAction {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.visitedAction .link {
  font-size: 12px;
  color: #120df9;
  cursor: pointer;
  font-weight: bold;
}
.followUpHistoryWrapper {
  display: flex;
}
.followupWrapper {
  width: 330px;
}
.followuphistory {
  width: 720px;
}
.appointmentList {
  padding: 0 40px;
}
.appointmentList table {
  border-spacing: 0 8px;
  border-collapse: unset;
  position: relative;
  margin-top: 120px;
}
.appointmentList table tr:first-child:hover {
  box-shadow: none;
}
.appointmentList table tr th {
  font-size: 12px;
  color: #2e384d;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: default;
  padding: 0 10px 0 0px;
  z-index: 2;
  margin: 0;
  position: sticky;
  top: 128px;
  min-width: 10ch;
}
.appointmentList table tr th p {
  margin: 0;
}
.appointmentList table tr th .tableHeading {
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.appointmentList table thead tr {
  position: relative;
  height: 44px;
}
.appointmentList table thead tr::after {
  content: "";
  box-shadow: 0px 30px 25px -10px #0037ff29;
  background: #f4f6fc;
  position: fixed;
  left: 105px;
  right: -40px;
  height: 50px;
  top: 124px;
  z-index: 1;
}

.appointmentList table tr td {
  color: #2e384d;
  font-size: 12px;
  background: #fff;
  min-height: 48px;
  border: 0;
  letter-spacing: 0.5px;
  padding: 10px 10px 10px 0px;
  border: solid 1px transparent;
  box-shadow: none;
  white-space: nowrap;
  max-width: 20ch;
  /* overflow: hidden;		 */
  text-overflow: ellipsis;
}
.appointmentList table tr td span {
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.appointmentList table tr td .modleName {
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.warppedText {
  margin: 0;
  padding: 0;
  width: 90%;
  font-size: 12px;
  white-space: normal;
  display: flex;
  justify-content: space-between;
}
.appointmentList table tr:hover td:first-child {
  border-left: solid 1px #0037ff;
}

.appointmentList table tr:hover td:last-child {
  border-right: solid 1px #0037ff;
}
.appointmentList table tr:hover td {
  border-bottom: solid 1px #0037ff;
  border-top: solid 1px #0037ff;
}
.appointmentList table tr:hover {
  box-shadow: 0px 4px 8px 0 rgba(0, 55, 255, 0.1);
}
.appointmentList table th:first-child {
  padding-left: 20px;
}
.appointmentList table td:first-child {
  padding-left: 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: relative;
}
.appointmentList table td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.appointmentList table .checkin {
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 8px;
  border: solid 1px rgba(0, 55, 255, 0.1);
  color: #0037ff;
  background: transparent;
  height: 32px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.2s;
}
.appointmentList table .checkin:hover {
  border: solid 1px #999;
}
.appointmentList table .checkin:focus {
  outline: none;
}
.appointmentList table .editPopup {
  margin: 0;
  position: relative;
}
.appointmentList table .editPopup::after {
  content: "";
  border-bottom: 1px solid #e8e9ec;
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 200px;
}
.appointmentList table .editPopup a,
.appointmentList table .editPopup a:hover {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #2e384d;
}
.appointmentList table .editPopup a span {
  flex: 0.2 0 auto;
}
.appointmentList table .dataId {
  text-decoration: none;
  color: #0037ff;
}
.appointmentList table .phoneNo {
  font-weight: bold;
}
.appointmentList .loadmore {
  font-size: 12px;
  color: #2e384d;
  text-align: center;
}
:global
  .layout_openMenu
  .appointment-page_appointmentList
  table
  thead
  tr::after {
  left: 225px !important;
}
.appointmentList :global .model-variant-year_variant__36d8Q {
  white-space: nowrap;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.hiBox {
  /* background-color: #fff; */
  margin: 0 30px 0 40px;
  border-radius: 10px;
}
.hiTitle {
  margin-top: 20px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  width: 60%;
}
.confirmleads {
  padding-top: 10px;
  background-color: #f4f6fc;
}
.fixedheadingSearchWrapper {
  position: fixed;
  top: 60px;
  left: 105px;
  right: 0;
  z-index: 3;
}
:global
  .layout_openMenu__11afW
  .appointment-page_fixedheadingSearchWrapper__12UWa {
  left: 220px;
  right: 0;
}
.titleSection {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.loadmore {
  display: flex;
  align-items: center;
  justify-content: center;
}
.appointmentList table tbody tr td :global .ra-dealer-chat_headerchat__25Rbu {
  display: none;
}

.scheduleLoadmore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  position: absolute;
  left: 45%;

}

.sendCta {
    background-color: #0037FF;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    padding: 4px 20px;
    margin-top: 10px;
    cursor: pointer;
}
.sendCta:focus {
    outline: none;
}
.sendCta:disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.allocate {
    font-weight: bold;
    color: #0037ff;
    font-size: 14px;
    cursor: pointer;
    display: block !important;
}
