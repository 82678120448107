.tab, .choose {
    padding: 12px 16px;
    min-height: 44px;
    background-color: #ffffff;
    border: 1px solid #DDE5EB;
    box-sizing: border-box;
    border-radius: 12px; 
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #465166;
    cursor: pointer;
}
.tab:hover {
  border-radius: 5px;
} 
.active {
    border-color: #0059A3;
    font-weight: 600;
    color: #0059A3;
}
.answerText {
    margin: 5px;
    line-height: 18px;
    text-align: center;
    word-wrap:unset;
}
.subtext {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    color: rgba(70, 81, 102, 0.7);
}
.choose {
    padding: 12px 12px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}