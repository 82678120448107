.txt {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #2e384d;
  margin: 10px 0 0 0;
  padding-bottom: 8px;
}
.errorText{
  color: red;
  font-size: 12px;
  font-weight: normal;
}
.ctaButton {
  display: flex;
  justify-content: space-around;
  margin-top: 17px;
  position: relative
}
.fixWrapper::before {
  content: '';
  position: absolute;
  background-color: #fff;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
:global body.ReactModal__Body--open {
  overflow: hidden !important;
}
.quoteNew {
  height: 100px;
}
.inputWrap{
  position: relative;
}
.inputWrap label {
  padding-left: 10px !important;
}
.inputWrap::before {
  content: "₹";
  position: absolute;
  left: 10px;
  top: 7px;
  font-size: 18px;
  color: rgba(46, 56, 77, 0.6);
  z-index: 1;
}
.inputWrap input{
  padding-left: 25px;
}

.inputWrap :global .MuiOutlinedInput-root:hover 
:global .MuiOutlinedInput-notchedOutline {
  border-color:  #0037ff !important;
}