/* Unlock C24 Quote Popup Starts here */
.popupWrap{
    display: block;
    margin: 0 auto;
    width: 270px;
}
.popupWrap h2{
    padding-bottom: 24px;
    margin: 0;
}
.customerDesc{
    display: block;
    margin: 0;
    padding-bottom: 24px;
    padding-left: 0;
}
.customerDesc li{
    display: inline-block;
    list-style-type: none;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.67);
}
.customerDesc li::after {
    content: "|";
    margin: 0 5px;
}
.customerDesc li:last-child::after {
    content: "";
}
.otpDetail{
    font-size: 14px;
    color: #2e384d;
    margin: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e7ff;
}
.inputPhone{
    display: block;
    margin-top: 53px;
    position: relative;
}
.inputPhone::before{
    content: "+91 -";
    position: absolute;
    left: 14px;
    top: 9px;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.6);
    z-index: 9;
}
.inputPhone input{
    padding-left: 55px;
}
.inputPhone label{
    left: 55px;
    line-height: 36px;
}
.inputPhone input:focus~label{
    left: 0;
}
.saveText{
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #0037ff;
    position: absolute;
    top: 63px;
    right: 14px;
    margin: 0;
    z-index: 9;
}
.otpWrap{
    padding: 32px 0 16px;
}
.enterOTP{
    font-size: 12px;
    font-weight: 600;
    color: #2e384d;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.sendOTP{
    font-size: 12px;
    color: #0037ff;
    letter-spacing: 1px;
    cursor: pointer;
}
.inputOtp{
    width: 100%;
    padding-bottom: 14px;
}
.inputOtp p{
    border-radius: 8px;
    border: solid 1px rgba(0, 55, 255, 0.2);
    background: #e0e7ff;
    width: 40px;
    margin: 0;
    line-height: 45px;
    font-size: 30px;
    color: rgba(46, 56, 77, 0.7);
    height: 40px;
    text-align: center;
}
.inputOtp span{
    padding: 7px;
}
.inputOtp input{
    height: 40px;
    font-size: 14px;
    color: #2e384d;
    border-radius: 8px;
    border: solid 1px #0037ff;
    letter-spacing: 6px;
    padding: 0 20px;
}
.inputOtp input:focus{
    outline: none;
}
.getQuote {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.getQuote button{
    height: 48px;
}
.cancelCta {
    background: none;
    outline: none;
    border: none;
    color: #b0bac9;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}
.cancelCta:focus {
    outline: none;
}
.getQuote p{
    font-size: 14px;
    color: #b0bac9;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 28px;
}
.inputOtp label{
    text-align: left;
    padding: 0 20px;
}
.otpText{
    font-size: 16px;
    color: #465166;
    margin-bottom: 24px;
}
.inputOtp input:placeholder-shown{
    font-size: 0;
}
.inputOtp input:placeholder-shown:focus{
    font-size: 14px;
}
/* Unlock C24 Quote Popup Ends here */

/* Change number css starts here*/
.changenumberWrap{
    display: block;
    padding-top: 32px;
}
.changenumberWrap p{
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #2e384d;
    display: block;
    text-transform: uppercase;
    margin: 0;
    line-height: 26px;
    
}
.phoneNumberChange button{
    color: #0037ff;
    cursor: pointer;
    font-size: 12px;
    text-transform: capitalize;
    background: none;
    border: none;
    outline: none;
}
.phoneNumberChange {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}
.resendOtp {
    display: flex;
    justify-content: flex-end;
}
.errorText{
    color: #c80039;
    font-size: 12px;
    line-height: 6px;
}
:global .quote-popup_inputOtp__1othz input {
    border: 1px solid #ced4da;
    border-radius: 4px !important;
}
:global .input-text_inputShift__K7ipV label.input-text_active__SEvRo {
    left: 0;
}