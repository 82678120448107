.tabs {
    margin: 22px 15px 15px 0;
    width: 80%;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        li {
            list-style: none;
            border: solid 1px rgba(0, 55, 255, 0.1);
            border-radius: 17px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;   
            width: 25%; 
            background: #fff;
            font-size: 12px;
            font-weight: 600;
            color: #2e384d;
            letter-spacing: 1px;
            text-align: center;
            cursor: pointer;
            &.active, &:hover {
                border-color: #0037ff;
                color: #0037ff;  
                text-decoration: none;
            }
        }
    }
}