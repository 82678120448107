.progressWrap{
    padding: 20px 20px 20px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 89, 163, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
   //xs z-index: 999;
}
.flexItems, .arrowWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftColumn {
    min-width: 162px;
}
.count{
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
    display: block;
    color: #465166;
}
.meter { 
    height: 4px;
    position: relative;
    background: rgb(179, 229, 252, 0.39);
    border-radius: 4px;
    overflow: hidden;
}
.meter span {
    display: block;
    height: 100%;
}
.width{
    width: 100%;
}
.progress {
    background: #0059A3;
    border-radius: 4px;
}

.arrowWrap {
    width: 34px;
    height: 34px;
    background: #BEBEBE;
    border-radius: 8px;
    justify-content: center;
}
.active, .next {
    background: rgba(255, 137, 29, 0.1);
}
.next {
    transform: rotate(180deg);
}
.arrowWrap + .arrowWrap {
    margin-left: 12px;
}
.progressWrap button {
    margin-top: 10px;
}
.buttonWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    bottom: 0;
}
.buttonWrap button {
    border-radius: 12px;
    height: 48px;
    line-height: 48px;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
    box-shadow: none;
    width: 100%;
    font-weight: 600;
}
.buttonWrap button:disabled {
    background: #BEBEBE;
    color: #fff;
}