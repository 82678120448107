.logTypeRetail, .logTypePil {
    letter-spacing: 1px;
    color: #ffffff;
    font-size: 14px;
    border-radius: 8px;
    min-width: 100px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logTypeRetail {
    background-color: #00ccb5;
} 
.logTypePil {
    background-color: #5900cc;
}