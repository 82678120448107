.topPerformersWrapper {
    min-height: 430px;
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(0, 55, 255, 0.05);
    border: solid 2px #e0e7ff;
    background-color: #ffffff;
    h3 {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: #000000;
        margin: 0;
        padding: 20px 0;
        text-align: center;
    }
    .performersHeading {
        margin: 0;
        height: 32.4px;
        background-color: #e0e7ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.5px;
            color: #000000;
        }
    }
    .topPerformersList, .youStatus {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            padding: 15px 15px;
            display: flex;
            align-items: center;
            
            .cont, .target {
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.5px;
                color: #000000;
                width: 15%;
            }
            .target {
                font-size: 14px;
            }
            .name {
                font-size: 14px;
                letter-spacing: 0.5px;
                color: #000000;
                width: 60%;
            }
        }
    }
    .youStatus {
        border-top: solid 2px #e0e7ff;
        li {
            .name {
                font-weight: bold;
                font-size: 18px;
            }
        }
    }
}