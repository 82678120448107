.filter {
    display: flex;
    justify-content: space-between;
    border: 0px solid #DFDFDF;
    border-radius: 8px;
    position: relative;
}
.sortHeader {
    margin: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    span {
        text-transform: uppercase;
    }
}
.sortInfo {
    color: #7E8594;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.01em;
}

.dropDown.active  {
    display: block;
}
.dropDown{
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; 
    position: absolute;
    top: 90px;
    right: 20px;
    box-shadow: 0 6px 12px 0 rgba(0, 55, 255, 0.1);
    z-index: 99;
    transition: all 0.2s;
    display: none;
}
.dropDown ul {
    margin: 0;
    padding: 0;
}
.dropDown ul li {
    list-style: none;
    margin: 0;
    padding:0;
    border-bottom: 1px solid #e0e7ff;
    text-align: right;
    height: 40px;
    line-height: 40px;
    margin-right: 25px;
    color: #4a4a4a;
    font-size: 12px;
    padding-left:55px;
    position: relative;
    &:hover, &.active {
        color: #0037ff;
    }
}
.dropDown ul li:last-child {border:none}


