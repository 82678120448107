.listingCarPopup {
    width: 350px;
    .customerDesc{
        display: block;
        margin: 0;
        padding-bottom: 24px;
        padding-left: 0;
    }
    .customerDesc li{
        display: inline-block;
        list-style-type: none;
        font-size: 14px;
        color: rgba(46, 56, 77, 0.67);
    }
    .customerDesc li::after {
        content: "|";
        margin: 0 5px;
    }
    .customerDesc li:last-child::after {
        content: "";
    }
    .clousrePrice{
        font-size: 12px;
        color: #2e384d;
        letter-spacing: 2px;
        font-weight: 600;
        margin: 0;
        text-transform: uppercase;
        padding-top: 30px;
        padding-bottom: 0px;
    }
    .ctaButton {
        margin-top: 80px;
        display: flex;
        justify-content: space-around;
    }
    .sliderWrapper {
        margin: 0 auto 80px;
    }
}