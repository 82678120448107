.ccConfirmLeads {
    margin-left: 30px;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            font-size: 12px;
            color: #2e384d;
            margin-right: 10px;
            padding: 0 15px;
            height: 33px;
            display: flex;
            align-items: center;
            background-color: #f0f4ff;
            border: 1px solid rgba(0, 55, 255, 0.1);
            border-radius: 16px;
            cursor: pointer;
            &.active {
                background-color: #0037ff;
                color: #fff;
            }
        }
    }
}