.customerPartnersviewed {
    width: 210px;
    height: 340px;
    border-radius: 8px;
    box-shadow: 2px 5px 8px 2px rgba(231, 226, 226, 0.5);
    background-color: #ffffff;  
    margin-bottom: 15px; 
    margin-right: 30px;
    .customerPartnersCars {
        width: 210px;
        height: 172px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }
    .carsData {
        padding: 23px 12px;
        h3 {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #2e384d;
        }
        .carDataList {
            padding: 15px 0 0 0;
            ul {
                display: flex;
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.58px;
                    color: #2e384d;
                    opacity: 0.51;
                    position: relative;
                    padding: 0 10px 0 0;
                    margin-right: 6px;
                    text-transform: capitalize;
                    &::after {
                        content: '|';
                        position: absolute;
                        top: 0;
                        right: -1px;
                    }
                    &:last-child {
                        &::after {
                            content: '';
                        }
                    }
                }
            }
        }
        .viewCar {
            display: flex;
            align-items: center;
            height: 33px;
            object-fit: contain;
            border-radius: 3px;
            background-color: #ebf2ff;
            padding: 0 7px;
            margin-top: 20px;
            p {
                margin: 0;
                padding-left: 10px;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.58px;
                color: #2e384d;

            }
        }
    }
}