.reviewInspectionReportPopup {
    width: 600px;
    min-height: 300px;
    .txt {
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2e384d;
        margin-top: 10px;
    }
    .lowerCharge {
        border-radius: 10px;
        background-color: #fad2d2;
        font-size: 20px;
        font-weight: bold;
        color: #e02020;
        margin: 0 0 0 0;
        padding: 10px 55px;
        text-align: center;
    }
    .lists {
        margin-top: 15px;
        height: 250px;
        overflow: auto;
        ul {
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: 50% 50%;
            li {
                list-style: none;
                font-size: 16px;
                color: #d9424a;
                padding: 5px 0;
            }
        }
    }
    .ctaWrapper {
        margin: 20px 0 0 0;
        display: flex;
        justify-content: space-evenly;
    }
    .infotext {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #2e384d;
    }
    .tabs {
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #0037ff;
            li {
                list-style: none;
                height: 40px;
                background-color: #e0e7ff;
                color: #2e384d;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 49.5%;
                font-size: 16px;
                font-weight: 600;
            }
            li.active {
                background-color: #0037ff;
                color: #ffffff;
            }
        }
    }
    .documentsMainWrapper {
        .dataList {
            margin-top: 30px;
            border-bottom: 1px solid #e0e7ff;
            padding-bottom: 20px;
            &:last-child {
                border-bottom: none;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                li {
                    list-style: none;
                    width: 33%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    label {
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 1.56px;
                        color: #2e384d;
                    }
                    h3, h4 {
                        font-size: 18px;
                        font-weight: 600;
                        color: #2e384d;
                    }
                    h4 {
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 1.56px;
                    }
                    .greenText {
                        font-size: 12px;
                        color: #00ba67;
                    }
                    .redText {
                        font-size: 12px;
                        color: #e02020;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    .dateText {
                        font-size: 12px;
                        font-weight: 600;
                        color: #737a88;
                    }
                    .insuranceType {
                        width: 90%;
                    }
                }
            }
        }
    }
    .reviewInfo {
        margin: 0;
        padding: 0;
        text-align: center;
        span {
            position: relative;
            font-size: 12px;
            color: #2e384d;
            width: 46%;
            margin: auto;
            text-align: center;
            display: inline-block;
            padding-left: 20px;
            &::before {
                content: '';
                width: 30px;
                height: 30px;
                background-image: url('../../components/images/reviewInfoIcons.svg');
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    .carCondition {
        max-height: 400px;
        overflow: auto;
        .achieveingTarget {
            height: 37px;
            background-color: #fad1d2;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.5px;
            color: #e02020;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
        .engineSmokeSection {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e0e7ff;
            padding-bottom: 5px;
            .engineSmokeText {
                width: 28%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2e384d;
                }
                .lowerText {
                    font-size: 12px;
                    font-weight: bold;
                    color: #e02020;
                }
                .reported {
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    color: #000000;
                }
            }
            .engineSmokeVideo {
                width: 50%;
            }
        }
        .panelsSection {
            display: flex;
            margin-top: 10px;
            border-bottom: 1px solid #e0e7ff;
            padding-bottom: 5px;
            .targetPrice {
                width: 30%;
                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2e384d;
                }
                p {
                    font-size: 12px;
                    font-weight: bold;
                    color: #e02020;
                    margin: 0;
                    padding: 0;
                }
            }
            .panelsList {
                width: 70%;
                ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    li {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        width: 25%;
                        text-align: center;
                        span {
                            font-size: 16px;
                            font-weight: bold;
                            color: #d9424a;
                            width: 100%;
                        }
                        p {
                            font-size: 14px;
                            color: #2e384d;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
        .carPullingMainWrapper {
            display: flex;
            margin-top: 10px;
            align-items: center;
            .carPulling {
                width: 30%;
                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2e384d;
                }
                p {
                    font-size: 12px;
                    font-weight: bold;
                    color: #e02020;
                }
            }
            .carPullingText {
                font-size: 12px;
                // letter-spacing: 0.5px;
                color: #e02020;
                font-weight: bold;
                width: 28%;
                padding: 0;
                margin: 0 0 0 10%;
            }
        }
    }
}