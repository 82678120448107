.sellerFeedback {
    transition: all 0.2s;
    transition: all 0.5s ease;
    position: relative;
    width: 100%;
    h5 {
        border: 1px solid #0037FF;
        border-radius: 3px;
        height: 32px;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin: 0;
        position: relative;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            top: 6px;
            right: 10px;
            background-image: url('../../components/images/back-arrow.svg');
            background-size: 70%;
            background-repeat: no-repeat;
            width: 14px;
            height: 17px;
            transform: rotate(-180deg);
            transition: all 0.5s ease;
        }
    }
    .feedBackWrapper {
        box-shadow: 0 0px 1px 0 #999;
        background-color: #fff;
        padding: 8px;
        position: absolute;
        display: none;
        top: -80px;
        left: 0;
        right: 0;
        p {
            padding: 5px 0;
            font-size: 12px;
            color: #465166;
            span {
                font-weight: 700;
            }
        }
        .close {
            position: absolute;
            top: 2px;
            right: 2px;
            cursor: pointer;
        }
    }
    &.active {
        h5 {
            &::after {
                content: '';
                background-image: url('../../components/images/back-arrow.svg');
                transform: rotate(90deg);
            }
        }
        .feedBackWrapper {
            display: block;
        }
    }
}