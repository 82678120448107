.documentStatusPopup {
    min-width: 700px;
    .documentStatusCard {
        margin-top: 20px;
        height: 500px;
        overflow: auto;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #DFDFDF;
            margin-bottom: 10px;
            span {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                color: #7E8594;
            }
            li {
                list-style: none;
                font-size: 14px;
                color: #2e384d;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 5px;
                margin: 10px;
                p {
                    margin: 0;
                    padding: 0;
                }
                .recieved, .pending {
                    font-size: 12px;
                    font-weight: 600;
                    position: relative;
                    padding-left: 30px;
                    line-height: 20px;
                    &::before {
                        content: '';
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        width: 20px;
                        height: 20px;
                    }
                }
                .recieved {
                    color: #00ba67;
                    &::before {
                        background-image: url('../../components/images/okTick.svg');
                    }
                }
                .pending {
                    // color: #e02020;
                    &::before {
                        background-image: url('../../components/images/timeCircle.svg');
                    }
                }
            }
        }
    }
}