.parkAndSellReauction{
    padding-top: 25px;
    .rangeContainer{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #465166;
        margin: 0px 0px 26px;
    }
    .userDetails{
        font-size: 14px;
        color: #2E384D;
        opacity: 0.67;
        margin: 10px 0px 28px;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            li {
                list-style: none;
                font-size: 12px;
                letter-spacing: 0.5px;
                color: #2e384d;
                padding-right: 13px;
                position: relative;
                &::after {
                    content: '|';
                    position: absolute;
                    right: 5px;
                    font-size: 16px;
                    top: -5px;
                }
                &:last-child {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
    .title{
        font-weight: 600;
        font-size: 18px;
        color: #2E384D;
    }
    .cepTitle{
        font-weight: 600;
        font-size: 12px;
        color: #2E384D;
        margin-bottom: 8px;
    }
}
.priceRangeSlider {
    margin-top: 30px;
}
.priceRangeSlider :global .style_downpaymentWrapper__3d_0Z {
    margin: 0;
    .maxMinLabel{
        display: none;
    }
}
.ctaWrapper {
    display: flex;
    justify-content: space-evenly;
}