.notFound{
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.heading{
    margin: 26px 0 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;  
    color: #465166;
}
.message{
    padding: 10px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #465166;
    .p {
        margin-top: 10px;
        margin-bottom: 5px;
    }
}
.buttonWrap {
    margin-top: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 10px;
}
.buttonWrap button {
    width: 100%;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 12px;
    height: 40px;
}