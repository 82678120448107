.header {
    padding: 10px; 
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    position: fixed;
    background-color: #f4f6fc;
    .headerRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.dropDown.active  {
    display: block;
}
.dropDown{
    background: #fff;
    position: absolute;
    top: 50px;
    z-index: 99;
    width: 100%;
    min-height: calc(100vh - 50px);
}
.dropDown ul {
    margin: 0;
    padding: 0;
}
.dropDown ul li {
    list-style: none;
    margin: 0;
    padding:0;
    border-bottom: 1px solid #e0e7ff;
}
.dropDown ul li:last-child {border:none}
.dropDown ul li a {
    text-decoration: none;
    color: #4a4a4a;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    position: relative;
}
.dropDown ul li a:hover {
    color: #0037ff;
}

.active {
    .userInfo {
        background: #FAFAFA;
        border-radius: 12px;
        padding: 20px;
        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #002441;
        }
        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #465166;
        }
    }
    .myLeads {
        margin-top: 10px;
        background: #FAFAFA;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        .leads {
            display: flex;
            flex-direction: column;
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.01em;
                background-color: #FAFAFA;
                color: #002441;
                text-align: center;
            }
        }
        span {
            background: #0C72C6;
            color: #ffffff;
            border-radius: 4px;
            width: 70px;
            height: 30px;
            text-align: center;
        }
    }
}
.logout {
    margin-top: 10px;
    background: #FAFAFA;
    border-radius: 12px;
    padding: 20px;
    img {
        margin-right: 20px;
    } 
    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }
}