.textareaWrap{
    position: relative;
}
.textareaWrap label{
  position: absolute;
  top: 0;
  left: 10px;
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  line-height: 40px;
  color: #9aa1b5;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  font-size: 14px;
  padding: 0 10px;
  z-index: 0;
}
.textareaWrap label:after {
  position: absolute;
  content: '';
  height: 14px;
  background: #fff;
  display: none;
  left: 15px;
  top: 0px;
  z-index: -1;
}
.textareaWrap label.active {
  padding: 0 10px;
  font-size: 12px;
  top: -7px;
  line-height: 13px;
  height: 14px;
  width: auto;
  border-radius: 0;
  z-index: 1;
  background: #fff;
  margin: 0 10px;
  color:#0037ff !important;
}
.textareaWrap .error{
  color: #c80039;
  font-size: 12px;
  line-height: 6px;
}
:global .form-control:focus{
  box-shadow: none !important;
  border:2px solid  #0037ff !important;
}
:global .form-control:focus label{
  color: #0037ff !important;
}
:global textarea {
  min-height: 100px !important;
}
:global textarea.form-control {
  resize: none;
}