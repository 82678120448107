.searchWrapper {
    display: flex;
}
.search {
    height: 32px;
    border-radius: 3px;
    border: solid 1px #e0e7ff;
    background-color: #ffffff;
    padding: 2px 10px;
    position: relative;
    min-width: 211px;
    display: flex;
}
.search input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 12px;
    color: #2e384d;
}
.search button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.search button:focus {
    outline: none
}
/* .search:focus-within .searchRest {
    opacity: 1;
} */
.searchRest {
    font-size: 12px;
    color: #2e384d;
    padding: 0 10px !important;
    /* opacity: 0; */
}