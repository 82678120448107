.leadProcureCard {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: stretch;
}
.regNumber {
    width: 100%;
    margin-top: 10px;
}
.inputLabel {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #002441;
}
.btnCarPrice {
    margin-top: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 10px;
}
.btnCarPrice button {
    width: 100%;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 12px;
    height: 40px;
}