.wrapper{
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background: #F5F5F5;
}
.wrapper ul {
    padding: 5px 5px;
    list-style-type: none;
}
.wrapper ul li {
    padding: 6px 0px 6px 0px;
} 
.wrapper ul li .priceWrap {
    min-height: 150px;
    background: radial-gradient(93.63% 93.63% at 50.02% 6.37%, #0071BC 0%, #113167 50%, #001B4B 75%, #000035 100%);
    box-shadow: 10px 10px 10px rgba(126, 133, 148, 0.08);
    border-radius: 12px 12px 0px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;

    .gsInfo {
        box-sizing: border-box;
        align-items: flex-start;
        padding: 4px 8px 4px 4px;
        left: calc(50% - 102px/2);
        background: rgba(243, 247, 249, 0.2);
        border: 1px solid rgba(221, 229, 235, 0.1);
        border-radius: 32px;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        span {
            margin-left: 10px;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
        }
    }
}
.wrapper ul li .congratulations{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01rem;
    color: #FFFFFF;
    
    span {
        opacity: 0.7;
    }
}
.wrapper ul li .price{
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
    display: block;
}
.wrapper ul li .serviceCharge {
    min-height: 140px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px rgba(126, 133, 148, 0.08);
    border-radius: 0px 0px 12px 12px;
    margin-top: -10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    order: 1;
    flex-grow: 0;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        p {
            width: calc(100% - 100px);;
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            font-weight: 600;
            color: #465166;
            letter-spacing: 0.01em;
        }
        span {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            text-align: right;
            color: #EB5757;
            font-weight: 700;
            word-wrap: normal;
        }
    }
    .body {
        margin: 10px 5px -15px 10px;
        display: block;
        color: #7E8594;
        font-weight: 400;
    }
   
}
.wrapper ul li .finalPrice {
    background: #01A35B;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    font-size: 12px;
    box-shadow: 0px 4px 12px rgba(126, 133, 148, 0.08);
    margin: 10px;
    .title {
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    .priceTag {
        text-align: right;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
}

.wrapper ul li .dealerDetails{
    margin-top: -10px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    border-radius: 12px;
    img {
        margin: 10px;
    }
    p {
        margin: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
    span {
        margin: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
    }
}
.wrapper ul li .smsinfo{
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.wrapper ul li .smsCounter{
    min-height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 10px 10px 10px rgba(126, 133, 148, 0.08);
    border-radius: 12px;
    margin: 10px;
    
    .smsinfoattempt {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .attempt {
        border-bottom: 1px solid #DDE5EB;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .attemptInfo {
        font-size: 12px;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        color: #465166;
        margin-bottom: 10px;
        letter-spacing: 0.01em;
    }
    .info {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #0059A3;
        margin-top: 20px;
    }
    .buttonWrap {
        background: #FDF1E7;
        border-radius: 12px;
        padding: 10px 36px;
        text-align: center;
        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #EF6E0B;
        }
    }
}

.homeButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 10px;
    span {
        height: 50px;
        background: #EF6E0B;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}