.inspectionWrap {
    display: flex;
    margin-top: 22px;
}
.carButtonGallery {
    width: 560px; 
}

.negotiationGallery {
    width: 450px; 
}
