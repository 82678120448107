.bookAppointmentWrapper {
    min-width: 650px;
    .bookAppointmentForm {
        display: grid;
        grid-template-columns: 50% 50%;
        .leftGridWrapper {
            margin-right: 10px;
            .leftGrid {
                margin: 10px 0;
            }
        }
        .rightGridWrapper {
            margin-left: 10px;
            .rightGrid {
                margin: 10px 0;
            }
            .appointmentTime {
                margin-top: 96px;
            }
        }
        .appointment {
            display: flex;
            margin-top: 20px;
            .appointmentText, .radioText {
                font-size: 12px;
                color: #2e384d;
                margin: 0;
                padding: 0;
                display: flex;
                span {
                    padding-top: 10px;
                }
            }
            .radioText {
                margin-top: -10px;
                margin-left: 15px;
            }
        }
    }
    .checkinButton {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}
.blankbox {
    height: 40px;
}
.blank_bax {
    margin-top: 53px;
}
@media screen and (min-width: 600px) and (max-width: 1024px){
    .bookAppointmentWrapper {
        min-width: 480px;
        .bookAppointmentForm {
            .rightGridWrapper {
                .appointmentTime {
                    margin-top: 132px;
                }
            }
        }
    }
}