.storeChange {
    width: 220px;
    .ctaButton {
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
    }
    .infoText {
        font-size: 12px;
        color: #60646d;
        font-weight: 400;
    }
    .staticText {
        font-size: 14px;
        color: #2e384d;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

