.allocatedbySelf {
    background-color: #f4f6fc;
    border-radius: 20px;
    color: #2e384d;
    font-size: 12px;
    margin: 3px 0 3px 0;
    padding: 3px 5px 3px 30px;
    position: relative;
    &::before {
        content: '';
        background-image: url("../images/correctBlue.svg");
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        background-color: #e0e7ff;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: 3px 5px;
    }
}

.hiDisposition{
    color: #2e384d;
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0px 0px 0px;
}