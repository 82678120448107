ul.subChild {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  li {
    background-color: #f4f6fc;
    border:1px solid #e0e7ff;
    border-radius: 8px;
    padding: 8px 10px;
    margin: 5px 10px;
    font-weight: normal;
    letter-spacing: 0.5px;
    font-size: 10px;
    width: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover, &.active {
      border-color: #0037ff;
      color: #0037ff;
    }

  }
}
