.wrapper {
    width: 100%;
    margin:0 auto 20px;
    padding: 0 50px;
    .dealerWrapper {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .dealerCard {
            width: 20%;
            height: 226px;
            border-radius: 15px;
            box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
            background-color: #ffffff;
            position: relative;
            margin-right: 30px;
            img { 
                position: absolute;
                top: 10px;
                left: 10px;
            }
            h3 {
                color: #0091ff;
                font-size: 60px;
                font-weight: bold;
                font-family: "Open Sans";
                text-align: center;
                padding: 44px 0 22px 0;
            }
            p {
                font-size: 18px;
                font-weight: normal;
                text-align: center;
                color: #2e384d;
                margin: 0;
                padding: 0 20px;
                margin: auto;
                position: absolute;
                bottom: 15px;
            }
        }
        .offerPriceMainWrapper {
            width: 80%;
            min-height: 226px;
            border-radius: 15px;
            box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
            background-color: #ffffff;
        }
        .offerPriceSection {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            .moneyIcon {
                position: absolute;
                width: 48px;
                height: 48px;
                margin: 10px;
                top: 0;
                left: 0;

                .GreenIcon, .BlueIcon, .OrangeIcon, .BlackIcon, .acceptedIcon{
                    position: relative;
                    &::before {
                        content: '';
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        top: 0;
                        background-repeat: no-repeat;
                    }
                }
                .GreenIcon {
                    &::before {
                        background-image: url('../images/greenIcon.svg');
                    }
                }
                .BlueIcon {
                    &::before {
                        background-image: url('../images/blueIcon.svg');
                    }
                }
                .OrangeIcon {
                    &::before {
                        background-image: url('../images/orangeIcon.svg');
                    }
                }
                .BlackIcon {
                    &::before {
                        background-image: url('../images/blackIcon.svg');
                    }
                }
                .acceptedIcon {
                    &::before {
                        background-image: url('../images/greenIcon.svg');
                    }
                }
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
            }
            .offersContent {
                margin: 10px 0 0 70px;
                width: 50%;
                .offerTitleRed, .offerTitleGreen, .offerTitleBlack, .offerTitleBlue, .offerTitleYellow  {
                    font-size: 24px;
                    font-weight: 600;
                    color: #ff766e;
                    font-family: Open Sans;
                    margin: 0;
                    padding-bottom: 5px;
                }
                .offerPriceRed, .offerPriceGreen, .offerPriceBlack, .offerPriceBlue, .offerPriceYellow {
                    font-size: 48px;
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    color: #ff766e;
                }
                .offerTitleGreen, .offerPriceGreen {
                    color: #00ba67;
                }
                .offerTitleBlack, .offerPriceBlack {
                    color: #2e384d;
                }
                .offerTitleBlue, .offerPriceBlue {
                    color: #0091ff;
                }
                .offerTitleYellow, .offerPriceYellow {
                    color: #f7b500;
                }
                .predictedPrice {
                    font-size: 16px;
                    color: #2e384d;
                    margin: 0;
                    opacity: 0.7;
                    padding-bottom: 10px;
                }
                .text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #2e384d;
                    margin: 0;
                }
                .services {
                    display: flex;
                    padding-top: 5px;
                    p {
                        margin: 0;
                        padding-right: 15px;
                        opacity: 0.7;
                        font-size: 16px;
                        color: #e02020;

                    }
                }
            }
            .priceSlideWrapper {
                display: flex;
                position: absolute;
                right: 0;
                border: 0;
                top: 0;
                height: 100%;
                width: 40%;
                .deactiveArrow {
                    background-color: #f4f6fc;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    width: 20px;
                    position: absolute;
                    right: 0;
                    height: 100%;
                    z-index: 1;
                    transition: width .5s;
                    img {
                        transform: rotate(90deg);
                        width: 17px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        cursor: pointer;
                    }
                }
                .priceTextAmountWrapper {
                    width: 85%;
                    margin: auto;
                }
            }
            .priceSlideWrapper.active {
                .deactiveArrow {
                    width: 100%;
                    transition: width .5s;
                    img  {
                        transform: rotate(-90deg);
                        left: 10px;
                        right: auto;
                        cursor: pointer;
                    }
                }
            }
            .priceSliderContent {
                display: none;
                position: absolute;
                right: 0px;
                z-index: 2;
                width: 90%;
                .textRed {
                    font-size: 12px;
                    color: #e02020;
                    padding-top: 10px;
                    text-align: center;
                    font-weight: 600;
                }
            }
            .priceSliderContent.active {
                display: block;
            }
            
        }
    }
    
}
.logoHeader {
    height: 30px;
    box-shadow: 0 10px 10px 0 rgba(0,55,255,.15);
    display: flex;
    align-items: center;
    img {
        width: 50px;
    }
}
.offerPriceWrapper {
    background-color: #fff;
    margin-top: 20px;
    padding: 30px 0 20px 0;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
    background-color: #ffffff;
    .title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #2e384d;
        padding-top: 15px;
        text-align: center;
        margin: 0;
    }
    .price {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.54px;
        color: #205c8c;
        text-align: center;
        margin-top: 5px;
        height: 40px;
        span {
            position: relative;
            &::before {
                content: '';
                background-image: url('../../components/images/ruppes.svg');
                position: absolute;
                left: -18px;
                top: 5px;
                width: 21px;
                height: 23px;
                background-size: 50%;
                background-repeat: no-repeat;
            }
            &::after {
                content: '';
                background-image: url('../../components/images/shape.svg');
                position: absolute;
                right: -60px;
                top: 0;
                width: 45px;
                height: 32px;
                background-size: 60%;
                background-repeat: no-repeat;
            }
        }
    }
    .offersSection {
        margin: 15px 70px 14px;
        display: flex;
        justify-content: space-between;
    }
}
.similarCars {
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    padding: 15px 35px;
    .customerYourcars {
        width: 246px;
    }
}
.priceWrapper {
    margin-top: -20px;
    .priceSlider {
        position: relative;
        .priceTextAmountWrapper {
            position: absolute;
            top: -40px;
            right: 15%;
            width: 250px;
        }
    }
}

.greenText, .orengeText, .redText {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    flex: 0.6;
    text-align: left;
}
.greenText {
    color: #44ca6f;
}
.orengeText {
    color: #f77000
}
.redText {
    color: #fa0000;
}
.noData {
    font-size: 14px;
    padding-top: 50px;
    display: block;
    text-align: center;
}
.optimumAmount {
    position: absolute;
    top: 10px;
    color: #585858;
    font-size: 12px;
    font-weight: bold;
    left: 0;
    right: 0;
    text-align: center;
}
.ctaWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
}
.tabing {
    margin-left: -11%;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            font-size: 12px;
            color: #2e384d;
            text-transform: capitalize;
            background-color: #e0e7ff;
            font-weight: 600;
            text-align: center;
            color: #2e384d;
            outline: none;
            width: 100%;
            cursor: pointer;
            line-height: 24px;
            &:last-child {
                border-top-right-radius: 15px;
            }
            &.active {
                background: #0037ff;
                color: #fff;
            }
        }
    }
}


.blueCta, .blueCtaActive, .listCarCta, .listCarCtaActive {
    text-transform: capitalize;
    background-color: #e0e7ff;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #2e384d;
    outline: none;
    width: 100%;
    cursor: pointer;
}
.blueCta:focus, .blueCtaActive:focus, .listCarCta:focus {
    border: none;
    outline: none;
}
.blueCtaActive, .listCarCtaActive {
    background: #0037ff;
    color: #fff;
}
.listCarCta, .listCarCtaActive {
    border-top-right-radius: 15px;
}
.textLabel {
    color: #2e384d;
    font-size: 12px;
    margin: 0px;
}
.listCarPrice {
    margin-top: -10px;
    :global .customer-offer-list-car_clousrePrice__1UGKL {
        padding-top: 0 !important;
    }
    :global .customer-offer-list-car_sliderWrapper__32y0h {
        margin-bottom: 0 !important;
    }
    :global .customer-offer-list-car_ctaButton__17TDq {
        margin-top: 10px !important;
    }
    :global .customer-offer-list-car_listingCarPopup__DjE5c {
        margin: auto;
        width: 90%;
    }
    :global .psi-slider_psiSlider__1gSFD {
        margin-top: 15px !important;
    }
}
.priceSliderContent :global .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    background-color: #f4f6fc !important;
    z-index: 2 !important;
} 
.generateCta {
    margin-top: 10px;
    text-align: center;
}
.ocbRuningWrapper {
    display: flex;
    .ocbruning, .ocbPrice {
        color : #ed6c0d;
        font-size: 24px;
        margin: 0;
    }
    .ocbPrice {
        font-weight: bold;
        padding-left: 20px;
    }
}
.ocbTimer, .expires {
    text-align: left;
    margin: 0;
    font-size: 18px;
    color: #2e384d;
}
.ocbRuningExpired {
    display: flex;
    .ocbruning, .ocbPrice {
        color : #ff766e;
        font-size: 24px;
        margin: 0;
    }
    .ocbPrice {
        font-weight: bold;
        padding-left: 20px;
    }
}
.errorText {
    font-weight: bold;
    font-size: 12px;
    color: #c80039;
    text-align: center;
    margin: 0;
    padding-top: 10px;
}

.marketPrice {
    background-color: #F2F7FB;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 32, 149, 0.05);
    border-radius: 8px;
    width: 93%;
    margin: 60px auto 0;
    padding-bottom: 20px;
    .marketPriceSection {
        min-height: 99px;
        background-color: rgba(0, 89, 163, 0.1);
        padding-left: 50px;
        position: relative;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        align-items: center;
        &::before {
            content: '';
            background-image: url('../images/marketPrice.svg');
            background-repeat: no-repeat;
            width: 48px;
            height: 48px;
            position: absolute;
            top: 0;
            left: 50px;
            bottom: 0;
            margin: auto;
        }
        .price {
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 72px;
            color: #2E384D;
            margin: 0;
            padding: 0;
        }
    }
    .marketPriceText {
        margin-left: 62px;
        h4, h5 {
            margin: 0;
            padding: 0;
        }
        h4 {
            font-size: 24px;
            line-height: 36px;
            color: #2E384D;
        }
        h5 {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #2E384D;
            opacity: 0.5;
        }
    }
    .yoursCarSimlerCars {
        margin-top: 24px;
        padding-left: 50px;
        display: flex;
        .yourCarsWrapper {
            h4 {
                font-size: 16px;
                line-height: 25px;
                color: #465166;
                margin: 0;
                padding: 0;
            }
            .yourCars {
                background-color: #DAE7F2;
                border-radius: 10px;
                width: 330px;
                margin-top: 15px;
                display: flex;
                .imgWrapper {
                    background-image: url('../../components/images/ImageBg.jpg');
                    background-repeat: no-repeat;
                    width: 158px;
                    height: 165px;
                    position: relative;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        max-width: 100%;
                    }
                }
                .carsSectionDetails {
                    margin-left: 10px;
                    padding: 10px 0;
                    width: 44%;
                    h4 {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #2E384D;
                        margin: 0;
                        padding: 0;
                    }
                    .varient {
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 14px;
                        color: #2E384D;
                        padding-top: 6px;
                    }
                    .kmModel {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #2E384D;
                        margin: 0;
                        padding: 15px 0 0 0;
                    }
                    .ratingSection {
                        margin-top: 10px;
                        ul {
                            margin: 0;
                            padding: 0;
                            li {
                                list-style: none;
                                display: flex;
                                justify-content: space-between;
                                margin: 5px 0;
                                h4 {
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #2E384D;
                                    margin: 0;
                                    padding: 0;
                                }
                                .rating {
                                    background-color: #fff;
                                    border-radius: 3px;
                                    padding: 3px 5px;
                                    display: flex;
                                    align-items: center;
                                    .highRating, .below5 {
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 19px;
                                        color: #219F28;
                                        padding-left: 5px;
                                    }
                                    .below5 {
                                        color: #F38701;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
    .feedback {
        display: flex;
        margin-top: 28px;
        padding-left: 50px;
        p {
            margin: 0;
            padding: 0;
            font-weight: normal;
            font-size: 24px;
            line-height: 36px;
            color: #465166;
        }
        img {
            padding-left: 30px;
            display: block;
        }
    }
}
.reviewPriceBreakdown {
    display: flex;
    justify-content: space-between;
    width: 93%;
    margin: 60px auto 0;
    padding-bottom: 20px;
    .customerReviewsWrapper {
        width: 48%;
        h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #465166;
            position: relative;
            margin: 0;
            padding: 0;
            &::before {
                content: '';
                background: linear-gradient(225deg, #F69B00 0%, #ED6A00 100%);
                border-radius: 2px;
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 36px;
                height: 4px;
            }
        }
        .reviewWrapper {
            padding: 20px;
            margin-top: 30px;
            background-color: #F2F7FB;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 20px rgba(0, 32, 149, 0.05);
            border-radius: 8px;
            .topReview {
                background: #FFFFFF;
                box-shadow: 0px 5px 10px rgba(0, 89, 163, 0.05);
                border-radius: 10px;
                padding: 13px;
                display: flex;
                justify-content: space-between;
                .ratingSection {
                    border-right: 2px solid #2E384D;
                    padding-right: 20px;
                    width: 34%;
                    h2 {
                        font-weight: 600;
                        font-size: 48px;
                        line-height: 78px;
                        color: #2E384D;
                        text-align: center;
                    }
                    .starRating {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 20px;
                        img {
                            display: block;
                            margin-right: 10px;
                        }
                    } 
                }
                .ourCustomers {
                    width: 60%;
                    .title {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 28px;
                        color: #465166;
                        margin: 10px 0 0 0;
                        padding: 0;
                        text-align: center;
                    }
                    ul {
                        margin: 20px 0 0 0;
                        padding: 0;
                        width: 70%;
                        margin: auto;
                        li {
                            list-style: none;
                            display: flex;
                            justify-content: space-between;
                            margin: 10px 0;
                            span {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                color: #7C8493;
                            }
                        }
                    }
                }
            }
        }
        .reviewList {
            margin: 35px 0 0 0;
            max-height: 400px;
            overflow: auto;
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    background-color: #FBFBFB;
                    box-shadow: 0px 5px 10px rgba(0, 89, 163, 0.15);
                    border-radius: 5px;
                    min-height: 50px;
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                    margin: 20px 0;
                    .name {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #465166;
                        width: 30%;
                    }
                    .highRating {
                        // background-color: #219F28; 
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        padding: 5px;
                        width: 10%;
                        span {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 19px;
                            color: #FFFFFF;
                            padding-left: 5px;
                        }
                    }
                    .noRating {
                        .ratingText {
                            color:#2e384d;
                            font-weight: bold;
                            margin-left: 0;
                        }
                        img {
                            display: none;
                        }
                    }
                    .profileRed {
                        background-color: #FD3708;
                    }
                    .profileGreen {
                        background-color: #05C410;
                    }
                    .profileYellow {
                        background-color: #f2c60c
                    }
                    .msg {
                        margin: 0;
                        padding: 0 0 0 24px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #465166;
                        mix-blend-mode: normal;
                        opacity: 0.7;
                        width: 60%;
                    }
                }
            }
        }
    }
    .priceBreakdown {
        width: 48%;
        h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #465166;
            position: relative;
            margin: 0;
            padding: 0;
            &::before {
                content: '';
                background: linear-gradient(225deg, #F69B00 0%, #ED6A00 100%);
                border-radius: 2px;
                position: absolute;
                bottom: -8px;
                left: 0;
                width: 36px;
                height: 4px;
            }
        }
        .priceBreakdownTable {
            margin-top: 30px;
        }
    }
}