.profileWrapper {
    padding: 80px 40px 0 40px;
    .profileHeading {
        display: flex;
        align-items: center;
        .back {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    .profileMainWrapper {
        border-radius: 8px;
        border: solid 1px rgba(0, 55, 255, 0.08);
        background-color: #ffffff;
        margin-top: 40px;
        padding-bottom: 40px;
        .profileContent {
            display: grid;
            grid-template-columns: 260px 70%;
            padding:60px 0 0 124px;
            .profilePic {
                background-color: #f4f6fc;
                width: 168px;
                height: 168px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    margin: 0;
                    padding: 0;
                    color: #0037ff;
                    font-size: 60px;
                    font-weight: 600;
                    letter-spacing: 2.5px;
                }
            }
            .userNameWrapper {
                display: flex;
                align-items: center;

                .userName {
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 2px;
                    color: #2e384d;
                }
            }
            .profileLeftSection {
                padding-left: 40px;
                margin-top: 50px;
                .proEmail, .proPhone, .proStore, .proRating, .proRole {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.75px;
                    color: #2e384d;
                    margin-bottom: 30px;
                    position: relative;
                    padding-left: 40px;
                }
                .proEmail::before , .proPhone::before , .proStore::before , .proRating::before , .proRole::before  {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    background-repeat: no-repeat;
                }
                .proEmail::before {
                    top: 5px;
                    width: 25px;
                    height: 19px;
                    background-image: url('../../components/images/profileEmail.svg');
                }
                .proPhone::before {
                    top: 5px;
                    width: 24px;
                    height: 24px;
                    background-image: url('../../components/images/profilePhone.svg');
                }
                .proStore::before {
                    width: 29px;
                    height: 24px;
                    background-image: url('../../components/images/profileStore.svg');
                }
                .proRating::before {
                    width: 25px;
                    height: 25px;
                    background-image: url('../../components/images/profileRate.svg');
                }
                .proRole::before {
                    top: 5px;
                    width: 20px;
                    height: 20px;
                    background-image: url('../../components/images/profileIcon.svg');
                }
            }
            .profileInfo {
                margin-top: 50px;
                p, .text {
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0.75px;
                    color: #2e384d;
                    margin-bottom: 30px;
                }
                .text {
                    display: flex;
                    flex-flow: wrap;
                    :global .input-text_inputShift__K7ipV {
                        width: 150px;
                    }
                }
                .textDanger {
                    color: #dc3545;
                    font-size: 12px;
                    font-weight: normal;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }
                .profileRating {
                    width: 60px;
                    height: 26px;
                    border-radius: 3px;
                    margin-bottom: 30px;
                    img {
                        padding: 3px;
                    }
                    .ratingText{
                        color: white;
                        margin-left: 5px;
                        font-size: 14px;
                    }

                }
                .noRating {
                    .ratingText {
                        color:#2e384d;
                        font-weight: bold;
                        margin-left: 0;
                    }
                    img {
                        display: none;
                    }
                }
                .profileRed {
                    background-color: #FD3708;
                }
                .profileGreen {
                    background-color: #05C410;
                }
                .profileYellow {
                    background-color: #f2c60c
                }
            }
        }
    }
}
