.negoPriceOffers {
    width: 100%;
    padding: 0 15px;
    .timeinfo {
        text-align: right;
        font-size: x-small;
        margin-top: 40px;
        margin-bottom: 5px;
        font-weight: 600;
        span {
            color: red;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        text-align: left;
        li {
            list-style: none;
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 2px;
            color: #979797;
            padding: 5px 0 5px 24px;
            display: flex;
            justify-content: space-between;
            position: relative;
            &::before {
                content: "";
                border: 3px solid #979797;
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 100%;
                left: 0px;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 1;
            }
            &::after {
                content: "";
                border: 0.5px dashed #00BA67;
                height: 80%;
                position: absolute;
                top: 63%;
                left: 5px;
                margin: 0;
            }
            &:last-child::after {
                content: '';
                border: none;
            }
            &.active, &.doneState {
                color: #00BA67;
                &::before {
                    content: "";
                    border: none;
                    background-image: url('./images/activeSign.svg');
                    background-size: 100%;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    top: 0;
                    left: 0px;
                    bottom: 0;
                    margin: auto;
                }
            }
            &.doneState {
                &::before {
                    content: "";
                    background-image: url('./images/activeState.svg');
                    width: 10px;
                    height: 10px;
                    left: 0px;
                }
            }
            &.processState {
                color: #ED6C0D;
                font-weight: 700;
                font-size: 14px;
                &::before {
                    content: "";
                    border: 6px solid #ED6C0D;
                    width: 18px;
                    height: 18px;
                    left: -3px;
                }
            }
            &.pendingState {
                color: #979797;
            }
            .infoWrapper {
                img {
                    cursor: pointer;
                }
                .infoContent {
                    background-color: #fff;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    padding: 16px;
                    position: absolute;
                    top: 34px;
                    right: -10px;
                    z-index: 2;
                    display: none;
                    p { 
                        margin: 0;
                        padding: 0 0 5px 0;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                        color: #585858;
                        span {
                            font-weight: 600;
                        }
                    }
                }
                &:hover {
                    .infoContent {
                        display: block;
                    }
                }
            }
        }
    }
}