.raJourney{
    border-top: 1px solid #E0E7FF;
    padding: 12px 5px;
    flex: 1;
}
.tokenHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tokenHeading h5{
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    margin: 0;
}
.tokenHeading span {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 2px;
}
.document {
    display: flex;
    margin: 10px 0 0 0;
    padding: 0;
}
.document li {
    list-style: none;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #979797;
    border: 1px solid #979797;
    border-radius: 8px;
    padding: 2px 3px;
    margin: 0 2px;
}
.document li.green {
    color: #00BA67;
    border: 1px solid #00BA67;
}
.document li.red {
    color: #E02020;
    border: 1px solid #E02020;
}
.moreCta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
}
.moreCta button {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #0037FF;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.moreCta button:focus {
    outline: none;
    border: none;
}
.viewDocumentsCta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
}
.viewDocumentsCta button {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #0037FF;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border: 1px solid #979797;
}
.info {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #2E384D;
    margin: 0;
    padding: 0 0 0 20px;
    position: relative;
}
.info::before {
    content: '';
    background-image: url('./images/info.svg');
    width: 15px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 0;
}
