.rejected {
    background-color: #ff6359;
    color: #fff;
    border-radius: 8px;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 110px;
}
.logo {
    width: 30px;
    height: 30px;
}

.lpRejected {
    color: #ff6359;
    border-radius: 8px;
    letter-spacing: 0.5px;
    font-size: 12px;
    display: flex;
}
.lpRejected  a{
    padding-left: 15px;
}
.lastPayment {
    display: flex;
}
.lastPayment span {
    white-space: nowrap;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    min-width: 20ch;
}