.factorsWrapper {
    background-color: #e0e7ff;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    min-height: 172px;
    .content {
        font-size: 24px;
        font-weight: bold;
        color: #0059a3;
        margin: 0;
        padding: 20px;
        width: 233px;
        display: flex;
        align-items: center;
    }
    .marketWrapper {
        display: flex;
        justify-content: space-between;
        .marketBox {
            width: 350px;
            padding: 20px;
            h3 {
                font-size: 18px;
                font-weight: bold;
                color: #2e384d;
                text-align: center;
                margin-bottom: 23px;
            }
            .exclamationDataWrapper {
                display: flex;
                justify-content: space-between;
                .exclamationWrapper {
                    text-align: center;
                    width: 50%;
                    .exclamation, .exclamationGreen {
                        width: 22px;
                        height: 22px;
                        color: #fff;
                        background-color: #d55046;
                        display: block;
                        margin: auto;
                        font-weight: bold;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                    }
                    .exclamationGreen {
                        background-color: transparent;
                        position: relative;
                        &::before {
                            content: '';
                            background-image: url("../../components/images/tokenDone.svg");
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            z-index: 1;
                        }
                        
                    }
                    p {
                        margin: 10px 0 0 0;
                        padding: 0;
                        font-size: 16px;
                        line-height: 1.5;
                        text-align: center;
                        color: #2e384d;
                    }
                }
            }
        }
        .marketBoxFixed {
            width: 250px;
            .exclamationDataWrapper {
                .exclamationWrapper {
                    width: 100%;
                }
            }
        }
    }
}