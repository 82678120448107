.customerConsentCharges {
    background-color: #fff;
    padding: 100px 0 220px 0;
    max-width: 768px;
    margin: 0 auto;
    height: 100vh;
    overflow: auto;
    .customerConsentChargesHeader {
        background-color: #FFFFFF;
        box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.193537);
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        position: fixed;
        max-width: 768px;
        margin: 0 auto;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        p {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            color: #465166;
            margin: 0;
            padding: 0;
        }
        .callSupport {
            display: flex;
            flex-wrap: wrap;
            width: 50px;
            align-items: center;
            justify-content: center;
            a {
                text-decoration: none;
            }
            img {
                cursor: pointer;
                display: block;
                width: 45px;
                height: 45px;
            }
            span {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: #2E384D;
                display: block;
                margin: -10px 0 0 0;
            }
        }
    }
    .priceTitle {
        background-color: #EBF4F9;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #465166;
        }
    }
    .clickStripWrapper {
        width: 100%;
        .clickStrip {
            padding: 10px 20px 10px 20px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            .clickArrow {
                position: relative;
                &::after {
                    content: '';
                    background-image: url('./images/disclosureIcon.svg');
                    width: 8px;
                    height: 13px;
                    position: absolute;
                    top: 0;
                    right: -20px;
                    bottom: 0;
                    margin: auto;
                }
            }
            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #205C8C;
                .smallSize {
                    font-size: 12px;
                }
            }
        }
        .subData, .subDataDiscount {
            width: 100%;
            display: none;
            ul {
                margin: 0;
                padding: 0 20px 0 30px;
                li {
                    list-style: none; 
                    flex-wrap: wrap;
                    width: 100%;
                    padding: 16px 0;
                    border-bottom: 0.5px solid #0059a329;
                    &:last-child {border-bottom: none;}
                    .conditinalLabel {
                        display: flex;
                        justify-content: space-between;
                        position: relative;
                        padding: 0 0 0px 20px;
                        &::before {
                            content: '';
                            background-image: url('../../components/images/chargesBullet.svg');
                            background-repeat: no-repeat;
                            width: 6px;
                            height: 6px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                    .fixedLabel {
                        display: flex;
                        justify-content: space-between;
                    }
                    .consentTextWrapper {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                    }
                    span {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 18px;
                        color: #465166;
                    }
                    
                }
            }
        }
        .textThrough{
            text-decoration: line-through;
        }
        .subDataDiscount {
            ul {
                li {
                    border-bottom: 1px solid #c9e7ff;
                    flex-wrap: wrap;
                    padding: 14px 0;
                    span.textThrough{
                        text-decoration: line-through;
                    }
                    .tncInfo {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 18px;
                            color: #465166;
                        }
                    }
                }
            }
        }
    }
    .clickStripWrapper.active {
        .clickArrow {
            position: relative;
            &::after {
                content: '';
                transform: rotate(90deg);
            }
        }
        .subData, .subDataDiscount {
            display: block;
        }
    }
    .tncWrapper {
        margin-top: 20px;
        padding: 0 20px;
        h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #465166;
            margin: 0;
            padding: 0;
        }
        ul {
            padding-left: 10px;
        }
        ul li {
            margin: 10px 0 0 0;
            padding: 0 0 0px 20px;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: #465166;
            position: relative;
            list-style-type: none;
            &::before {
                content: '';
                background-image: url('./images/dot.svg');
                background-repeat: no-repeat;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
    }
    .checkboxWrapper {
        display: flex;
        margin-top: 20px;
        padding: 0 20px;
        p {
            font-size: 12px;
            line-height: 18px;
            color: #465166;
            margin: 0;
            padding: 0;
        }
        a {
            color: #ed6d0d;
            font-weight: 500;
        }
    }
    .chargesCtaWrapper {
        background: #FFFFFF;
        box-shadow: 0px -15px 10px rgba(0, 0, 0, 0.10);
        margin: 20px 0px 0 0px;
        padding: 0 20px 10px 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0px;
        max-width: 768px;
        margin: auto;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 5px 0;
                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #465166;
                }
            }
            li {
                span .smallSize {
                    font-size: 12px;
                }
            }
        }
        .proceedBtn {
            background: linear-gradient(180deg, #F8931F 0%, #FF6359 100%);
            box-shadow: 0px 5px 10px rgba(237, 108, 13, 0.5);
            border-radius: 5px;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            margin-top: 20px;
        }

        .proceedBtnDisabled {
            background: #f0f4f7;
            border-radius: 5px;
            color: rgb(190, 188, 188);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            margin-top: 20px;
        }   

        small {
            font-weight: 300;
            font-size: 10px;
            line-height: 15px;
            color: #465166;
            padding: 10px 0 0 0;
            display: block;
        }
    }
}
.checkboxWrapper :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"] + label:before, .consentTextWrapper :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"] + label:before {
    background-image: url('../../components/images/rightCheckboxBlank.svg');
    width: 18px;
    height: 18px;
    background-size: 100%;
}
.checkboxWrapper :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"]:checked + label:before, .consentTextWrapper :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"]:checked + label:before {
    background-image: url('../../components/images/rightCheckbox.svg'), linear-gradient(225deg, #F69B00 0%, #ED6A00 100%);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    background-size: 100%;
}