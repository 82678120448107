.depreciationInspectionReports {
    margin: 30px 0 0 30px;
    table {
        background-color: #e0e7ff;
        thead {
            tr {
                th {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
            
        }
        tr {
            td, th {
                font-size: 14px;
                color: #2e384d;
                padding: 5px 0px;
                border-bottom: 1px solid #fff;
            }
            td:first-child {
                padding-left: 10px;
            }
        }
    }
}