.pllStoreList {
    width: 250px;
    position: fixed;
    left: 0px;
    top: 60px;
    bottom: 0;
    background-color: #003fb8;
    .storename {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #fff;
        padding: 0 10px;
        margin: 40px 0 0 0;
        .unalloctaed {
            width: 70px;
            text-align: center;
        }
    }
    .storeListWrapper{
        overflow: auto;
        overflow-y: scroll;
        position: fixed;
        top: 210px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 250px;
        flex-wrap: wrap;
        .cityStoreList {
            margin-bottom: 25px;
            .cityName {
                padding: 8px;
                // background-color: #0f48d7;
                // color: #fff;
                font-size: 16px;
                border:none;
                outline: none;
            }
            .storeList {
                display: flex;
                color: #fff;
                font-size: 14px;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                margin: 15px 0;
                p {
                    margin: 0;
                    padding: 0;
                    width: 150px;
                }
                .unAllocatedPllLeadCount, .unAllocatedPllLeadCountZero {
                    color: #fff;
                    border-radius: 50%;
                    min-width: 26px;
                    min-height: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .unAllocatedPllLeadCount {
                    background-color: #fff;
                    color: #003fb8;
                }
                
            }
        }
        
    }
    
}
.cityName :global .react-select__placeholder{
    color: #fff;
}
.cityName :global .react-select__control--is-focused {
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: #0f48d7;
}
.cityName :global .css-tlfecz-indicatorContainer, .cityName :global .css-tlfecz-indicatorContainer:hover {
    color: #fff;
}
.cityName :global .react-select__control--is-focused .react-select__placeholder{
    color: #333;
}
.cityName :global .css-yk16xz-control {
    background-color: #0f48d7;
    border: none;
    border-radius: 0;
}
.cityName :global .css-yk16xz-control:focus {
    background-color: #0f48d7;
    border: none;
    border-radius: 0;
}
.cityName :global .react-select__menu {
    border-radius: 0;
}
.cityName :global .css-1uccc91-singleValue {
    color: #fff;
}