.titleWrapper {
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.cjtextWrapper {
    margin-top: -18px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 108px;
    padding: 10px;
    .cjList {
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            li {
                list-style: none;
                margin: 0 20px;
                padding-top: 10px;
                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    color: #2e384d;
                    margin: 0;
                    padding: 0;
                }
                p {
                    margin: 0;
                    padding: 0;
                    color: #2e384d;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            .textblue {
                color: #0091ff;
            }
            .textgreen {
                color: #00ba67;
            }
            .textred {
                color: #e02020;
            }
        }
    }
    .notcj {
        background-color: rgba(224, 32, 32, 0.1);
        width: 191px;
        height: 88px;
        text-align: center;
        border-radius: 3px;
        p {
            color: #e02020;
            font-size: 36px;
            font-weight: bold;
            margin: 0;
            padding-top: 5px;
            display: flex;
            text-align: center;
            justify-content: center;
            line-height: 40px;
            height: 40px;
            span {
                padding: 2px 0 0 5px;
            }
        }
        span {
            font-size: 14px;
            font-weight: 600;
            color: #2e384d;
            margin: 0;
            padding-top: 10px;
            display: block;
        }
    }
    
    .viewpopupWrapper {
        display: flex;
        .viewCta {
            display: flex;
            align-items: center;
            margin-right: 20px;
        }
    }
}
