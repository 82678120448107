.selfCheckinWrapper {
    background-color: #fff;
    .selfCheckinHeader {
        height: 56px;
        box-shadow: 0px 5px 6px rgba(0, 89, 163, 0.1);
        display: flex;
        align-items: center;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0;
        z-index: 1;
        padding: 0 15px;
        background-color: #fff;
    }
    
}