.calLogTextWrapper {
    flex: 0.9;
}
.leadDetailsWrapper {
    margin-top: 30px;
    /* flex: 0.6; */
    margin-right: 34px;
    width: 60%;
    overflow: auto;
}
.leadDetailsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}
.leadDetailsTitle :global .blueButton {
    margin: 0;
}
.scrollbarChild{
    display: flex;
    position: fixed;
    top: 250px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    height: auto;
    margin: 30px 0 10px 40px;
    overflow: auto;
}
.scrollWrapper {
    width: 100%;
}
/* .fixedWrap {
    min-height: 580px;
    max-height: 580px;
    overflow-y: auto;
} */
/* .scrollbarChild {
    height: 200px;
} */
/* :global .scrollbar-container {
    height: 340px !important;
    padding-right: 20px;
} */