.navBar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    padding: 10px 0px;
    margin-bottom: 10px;
    z-index: 9;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    overflow: hidden;

    img {
        margin-left: 20px;
    }

    span {
        margin-left: 40px;
        font-size: 16px;
        font-weight: 500;
    }
}

.carInspection {
    margin-top: 50px;

    span {
        padding: 19px;
        font-size: 16px;
        font-weight: 700;
    }

    .nonServicable {
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        padding-left: 19px;
        padding-right: 19px;

        img {
            margin-top: -20px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: -10px;
        }

        p {
            margin-top: 10px;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #EB5757;
        }
    }

    .dealerLocationDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        padding: 20px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border: 1px solid #DDE5EB;
        border-radius: 8px;

        img {
            margin-right: 24px;
            margin-bottom: 35px;
        }
    }

    .customLocationDetails {
        display: flex;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #EF6E0B;
        border-radius: 12px;
        align-items: center;

        img {
            margin-left: 20px;
        }

        p {
            font-size: 14px;
            text-align: center;
            color: #465166;
        }

        .defaultText {
            margin-left: 10px;
            margin-top: 15px;
            font-weight: 600;
            color: #EF6E0B;
        }

        span {
            color: #ED6B00;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .active {
        border: 1px solid #0059A3;
        border-radius: 8px;
        background: #F1F8FF;
    }

    .date {
        padding: 9px;

        span {
            padding: 10px;
            font-size: 14px;
            font-weight: 600;
        }

        .dateCardWrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            color: #465166;

            .dateCard {
                // width: 28%;
                // height: 60px;
                background-color: #ffffff;
                min-width: 20%;
                min-height: 60px;
                padding: 15px 5px;
                margin: 8px;
                text-align: center;
                font-size: 12px;
                border: 1px solid #DDE5EB;
                border-radius: 16px;
                cursor: pointer;
                display: flex;
                // font-family: 'Poppins';
                flex-direction: column;
                line-height: 18px;
                position: relative;


                span {
                    text-align: center;
                    padding: 2px;
                }

                .day {
                    font-size: 10px;
                    margin-top: 2px;

                }

                .date {
                    font-size: 12px;
                }
            }

            .dateTag {
                margin-top: -15px;
                margin-bottom: 0px;
                font-weight: 600;
                font-size: 6px;
                background: #0059A3;
                border-radius: 8px;
                text-align: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: #FFFFFF;
                position: absolute;
                padding: 1px 10px;
                left: 12%;
                top: 9%;
            }

            .dateTagToday {
                padding: 1px 17px !important;
            }

            .inactive {
                color: #BEBEBE;
                cursor: none;
            }

            .disabled {
                background: #BEBEBE;

            }

            .selected {
                color: #0059A3;
                border: 1px solid #0059A3;
                border-radius: 8px;
            }
        }
    }

    .selectedSlot {
        margin-bottom: 70px;
        .slotTitle {
            padding: 18px;
            font-size: 14px;
            font-weight: 600;
        }

        .slotRange {
            padding: 9px;
            font-size: 12px;
            font-weight: 400;
        }

        .slotWrapper {
            color: #465166;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .slotCard {
            background: #FFFFFF;
            border: 1px solid #DDE5EB;
            border-radius: 8px;
            width: 40%;
            min-height: 40px;
            padding: 16px 0px;
            margin: 9px 15px;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #465166;
            cursor: pointer;

            span {
                text-align: center;
            }
        }

        .inactive {
            color: #BEBEBE;
            cursor: none;
        }

        .selected {
            color: #0059A3;
            border: 1px solid #0059A3;
            border-radius: 8px;
        }
    }

    .FormData {
        display: flex;
        flex-direction: column;
        margin-top: -70px;
        margin-bottom: 70px;

        span {
            padding: 19px;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #002441;
        }

        .inputPhone {
            margin-left: 19px;
            margin-right: 19px;
            padding: 10px;
            position: relative;
            background: #FFFFFF;
            border: 1px solid #979797;
            border-radius: 8px;
        }

        .inputPhone::before {
            content: "+91 - ";
            left: 10px;
            top: 13px;
            font-size: 14px;
            color: rgba(46, 56, 77, 0.6);
            z-index: 9;
        }

        .inputPhone input {
            border: none;
            outline: none;
        }

        .inputPhone input:focus-within {
            left: 0;
        }

        .inputContactName {
            margin-top: 15px;
            margin-left: 19px;
            margin-right: 19px;
            padding: 10px;
            position: relative;
            background: #FFFFFF;
            border: 1px solid #979797;
            border-radius: 8px;

            input {
                width: 100%;
                border: none;
                outline: none;
            }
        }
    }

    .formNonServicable {
        margin-bottom: 200px;
    }

    .ProceedBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 10px;

        .locationInfo {
            display: flex;
            flex-direction: row;

            img {
                margin-top: -20px;
                margin-left: 10px;
                margin-right: 10px;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.01em;
                color: #465166;
            }
        }

        .btnProcced {
            text-transform: uppercase;
            height: 50px;
            background: #DFDFDF;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #FFFFFF;
            cursor: none;
        }

        .active {
            background: #EF6E0B;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}

.enterAddress {
    margin-top: 50px;

    span {
        padding: 19px;
        font-size: 16px;
        font-weight: 700;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #465166;
    }

    .inputControl {
        margin-top: 15px;
        margin-left: 19px;
        margin-right: 19px;
        padding: 10px;
        position: relative;
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 8px;

        input {
            width: 100%;
            border: none;
            outline: none;
        }
    }

    .ConfirmBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 10px;

        .btnConfirm {
            text-transform: uppercase;
            height: 50px;
            background: #EF6E0B;
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #BEBEBE;
            cursor: none;
        }

        .active {
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}

.searchLocation {
    margin-top: 50px;
    margin-bottom: 20px;

    span {
        padding: 10px;
        font-size: 16px;
        font-weight: 700;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #465166;
    }
}