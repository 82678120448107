.leaddata .heading {
    padding-left: 80px;
}

.leaddata {
    border-left:1px solid #e0e7ff;
    margin-left: 40px;
    min-height: 350px;
    margin-right: -50px;
}
.leaddata table {
    border-spacing: 0 0px;
    border-collapse: unset;
    margin-top: 28px;
}
.leaddata table tr th {
    font-size: 12px;
    color: #2e384d;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    min-width: 10ch;
    line-height: 40px;
    position: sticky;
    top: 28px;
    background-color: #fff;
}
.leaddata table tr th p{
    margin: 0;
    padding: 0;
}
.leaddata table tr td {
    color: #2e384d;
    font-size: 12px;
    border: 0;
    letter-spacing: 0.5px;
    padding: 8px 5px;
    line-height: 18px;
}
.leaddata table tr td:first-child, .leaddata table tr th:first-child  {
    padding-left: 80px;
}
.leaddata table tr:nth-child(odd) {background: #e0e7ff}

.noResult{
    background: #e0e7ff;
    display: block;
    padding: 16px;
    text-align: center;
    color: #2e384d;
    font-size: 12px;
    height: 48px;
    border: 0;
    letter-spacing: 0.5px;
}
/* .history {
    display:block;
    height:320px;
    overflow:auto;
} */

.leaddata tbody {
    display: block;
    max-height: 320px;
    overflow-y: auto;
  }
  
.leaddata thead, .leaddata
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }