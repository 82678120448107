.errorContainer{
    text-align: center;
    margin-top: 40px;
    .header{
        font-weight: bold;
        font-size: 18px;
        color: #2E384D;
        margin: 36px 0px 12px 0px;
    }
    .subHeader{
        font-size: 14px;
        text-align: center;
        color: #465166;
        opacity: 0.7;
    }
    .refreshCta {
        background: linear-gradient(225deg, #F8931F 0%, #FF6359 100%);
        box-shadow: 0px 5px 10px rgba(237, 108, 13, 0.31);
        border-radius: 20px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: none;
        width: 208px;
        margin: auto;
        &:focus{
            border: none;
            outline: none;
        }
    }
}