.leadSearchMainWrapper {
    margin-top: 50px;
    .leadSearchWrapper {
        width: 380px;
        margin: auto;
        .searchHeading {
            color: #2e384d;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 2px;
            padding-bottom: 9px;
            margin: 0;
            // text-transform: uppercase;
        }
        .leadSearchWrapper {
            border: 1px solid #e0e7ff;
            border-radius: 3px;
            height: 38px;
            background-color: #fff;
            padding: 10px;
            display: flex;
            align-items: center;
            input {
                width: 100%;
                border: 0;
                outline: 0;
                color: #2e384d;
                font-size: 10px;
                font-weight: 600;
                opacity: 0.7;
            }
            button {
                border: none;
                outline: none;
                background: none;
                cursor: pointer;
                img {
                    outline: none;
                    border: none;
                }
            }
        }
        .errorText{
            color: #c80039;
            font-size: 12px;
            line-height: 6px;
        }
    }
}
