.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 5%;
    margin: auto;
}