.tableHeading {
    white-space: nowrap;		
    max-width: 25ch;		
    overflow: hidden;		
    text-overflow: ellipsis;
    margin: 0;
  }
  .ascending, .descending {
    width: 11px;
    height: 6px;
    background-image: url('../../images/arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
  }
  .descending {
    transform: rotate(180deg);
  }
  .callResultWrapper {
    min-width: 150px;
  }
  .callResultWrapper :global .css-yk16xz-control {
    border: 1px solid #c1c1c1;
  }
  .callResultWrapper :global .css-1wa3eu0-placeholder, :global .css-2613qy-menu, :global .css-1uccc91-singleValue {
    font-size: 12px !important;
    color: #2e384d !important;
    letter-spacing: normal !important;
  }