.incentiveViewDetails {
    transition: all 0.2s;
    .viewDetails {
        display: flex;
        justify-content: space-between;
        p {
            margin: 0;
            padding: 0 0 0 36px;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.5px;
            color: #000000;
            width: 38%;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-image: url('../../components/images/newInfoIcon.svg');
                width: 30px;
                height: 30px;
                bottom: 0;
                margin: auto;
            }
        }
        h4 {
            font-size: 14px;
            font-weight: bold;
            color: #0037ff;
            cursor: pointer;
            position: relative;
            margin: 0;
            padding-right: 35px;
            transition: all 0.5s ease;
            &::after {
                content: '';
                background-image: url('../../components/images/blueArrow.svg');
                background-repeat: no-repeat;
                width: 24px;
                height: 24px;
                position: absolute;
                top: -5px;
                right: 0;
                transform: rotate(180deg);
            }
        }
    }
    .viewDetailsCardMinWrapper {
        display: none;
        .viewDetailsCardWrapper {
            display: flex;
            justify-content: space-around;
            margin-top: 26px;
            transition: all 0.2s;
            .detailsCard {
                width: 31%;
                min-height: 129px;
                border-radius: 15px;
                box-shadow: 0 0 30px 0 rgba(0, 55, 255, 0.05);
                border: solid 2px #e0e7ff;
                background-color: #ffffff;
                h5 {
                    background-color: #dfe6ff;
                    font-size: 12px;
                    font-weight: bold;
                    color: #000000;
                    min-height: 34px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                .cardDataWrapper {
                    display: flex;
                    justify-content: space-evenly;
                    transition: all 0.2s;
                    .subCard {
                        width: 30%;
                        text-align: center;
                        p {
                            margin: 0;
                            padding: 0;
                            font-size: 20px;
                            font-weight: bold;
                            color: #e02020;
                        }
                        span {
                            font-size: 12px;
                            color: #000000;
                            display: block;
                            line-height: 18px;
                            margin-top: 5px;
                        }
                    }
                    .text {
                        font-size: 12px;
                        font-weight: bold;
                        color: #0037ff;
                        width: 50%;
                        text-align: center;
                        margin: 0;
                        padding-top: 15px;
                    }
                }
            }
        }
    }
}
.incentiveViewDetails.active  {
    .viewDetails {
        h4 {
            &::after {
                content: '';
                transform: rotate(0deg);
            }
        }
    }
    .viewDetailsCardMinWrapper {
        display: block;
    }
}
