.title {
  padding-top:20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.title p{
  margin: 0;
  padding-left: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.headingSearchWrapper {
  padding: 0 40px 10px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #f4f6fc;
  box-shadow: 0 10px 10px 0px rgba(0, 55, 255, 0.15);
}

.searchSection {
  margin-top: 15px;
  display: flex;
}
.calenderWrapper {
  position: relative;
}
.calander {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: #fff;
}
.calanderFormWrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #2e384d;
}
.calanderForm {
  position: relative;
  margin: 0;
  padding-left: 30px;
  border: 1px solid #e0e7ff;
  border-radius: 3px;
  height: 32px;
  width: 184px;
  margin: 0 10px;
  font-size: 12px;
  color: #2e384d;
  display: flex;
  align-items: center;

  
}
.calanderForm::before {
  background-image: url('../../components/images/calendar.svg');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
}
.calander :global .DayPicker-Months {
  flex-wrap: nowrap !important;
}
.filtertoggleWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  border-top: 2px solid #E0E7FF;
  padding: 8px 0 0 0;
  margin-top: 14px;
}