body {
    background-color: rgba(8, 89, 146, 0.05);
}
.depReportWrapper {
    padding: 122px 120px 0;
    position: relative;
    &::before {
        content: '';
        background-image: url('../../components/images/border.svg');
        background-repeat: repeat-x;
        height: 34px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .carNameSection {
        display: flex;
        justify-content: space-between;
        h1 {
            font-size: 24px;
            font-weight: bold;
            color: #424b60;
        }
        p {
            font-size: 20px;
            font-weight: 600;
            color: #075992;
            margin: 0;
            padding: 0;
            text-transform: capitalize;
        }
    
    }
    .graphSection {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 24px 34px;
        margin-top: 64px;
        .graphContent {
            flex: 1 1 40%;
            h2 {
                font-size: 20px;
                font-weight: bold;
                color: #424b60;
                text-transform: uppercase;
            }
            .text {
                font-size: 14px;
                color: #2e384d;
                margin: 0;
                padding-top: 50px;
                width: 350px;
            }
            .value {
                font-size: 16px;
                font-weight: 600;
                color: #125f96;
                padding-top: 26px;
                margin: 0;
            }
        }
        .graph {
            flex: 2 1 40%;
        }
    }
    .inspectionReport {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 82px;
        margin-bottom: 32px;
        h2 {
            font-size: 20px;
            font-weight: bold;
            color: #424b60;
        }
    }
    .dataList {
        ul {
            margin: 0;
            padding: 0;
           li {
               list-style: none;
               margin-bottom: 12px;
               background-color: #fff;
               padding: 10px 70px;
               cursor: pointer;
               .listHeading {
                    display: grid;
                    grid-template-columns: 30% 25% 25% 20%;
                    position: relative;
                    cursor: pointer;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/accrod_arrow.svg');
                        position: absolute;
                        left: -45px;
                        top: 0;
                        width: 26px;
                        height: 26px;
                        transform: rotate(180deg);
                    }
                }
                .dataContentList {
                    .listHeading {
                        &::before {
                            content: '';
                            transform: rotate(0deg);
                        }
                    }
                    .dataContent {
                        display: none;
                    }
                }
               
            }
        }
        
    }
}
.dataContent{
    font-size: 14px;
    color: #2e384d;
    margin-top: 10px;
    &>div {
        width: 28%;
    }
}
.carPrice {
    background-color: #085992;
    height: 83px;
    padding: 0 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
    }
}
.footerText {
    display: flex;
    justify-content: space-between;
    padding: 0 115px;
    margin: 20px 0;
    .asterisk {
        display: flex;
        align-items: flex-start;
        img {
            margin-right: 10px;
        }
    }
    ul {
        li {
            font-size: 14px;
            line-height: 1.71;
            color: #2e384d;
            list-style: none;
            position: relative;
            padding-left: 20px;
            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: #dedede;
                position: absolute;
                left: 0;
                top: 9px;
            }
        }
    }
}
.dataTable {
    background-color: #f2f2f2;
    padding: 0 18px;
    margin-top: 20px;
    tr {
        td {
            height: 44px;
            border-bottom: 1px solid #999;
            padding: 0 20px;
        }
        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }
}
.greenSign, .yellowSign, .crossSign {
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: center;
    &::before {
        content: '';
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 4pxx; 
    }
}
.greenSign {
    &::before {
        background-image: url('../../components/images/greenSign.svg');
    }
}
.yellowSign {
    &::before {
        background-image: url('../../components/images/yellowSign.svg');
    }
}
.crossSign {
    &::before {
        background-image: url('../../components/images/cross.svg');
    }
}

.redRating, .yellowRating, .greenRating {
    position: relative;
    width: 60px;
    border-radius: 3px;
    background-image: url(../../components/images/star.svg);
    background-repeat: no-repeat;
    background-position: 6px 4px;
    background-size: 20px;
    padding: 2px 7px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
}
.greenRating {
    background-color: #319937;
}
.yellowRating {
    background-color: #e9ac12;
}
.redRating {
    background-color: #d55046;
}
.engineTransmission {
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: center;
}
.staticHeading {
    display: grid;
    grid-template-columns: 30% 25% 25% 20%;
    position: relative;
    padding: 0 70px 16px;
    font-weight: bold;
}
.alignRight {
    text-align: right;
}
.valueWrapper {
    display: flex;
    justify-content: space-between;
    background-color: rgba(8, 89, 146, 0.11);
    color: #085992;
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    align-items: center;
    padding: 0 70px;
    margin-bottom: 21px;
}