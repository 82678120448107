.incentiveWrapper {
    padding: 80px 40px 0 40px;
    .incentiveHeading {
        display: flex;
        align-items: center;
        .back {
            margin-right: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    .contentWrapper {
        margin-top: 20px;
        display: flex;
        .targetAchievedSection {
            margin-right: 28px;
            width: 75%;
        }
        .topPerformers {
            width: 25%;
        }
    }
}
.oops {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 450px;
    height: 300px;
    text-align: center;
    h3 {
        font-size: 18px;
        font-weight: bold;
        color: #465166;
        margin-top: 27px;
    }
    p {
        opacity: 0.5;
        font-size: 16px;
        color: #000000;
        
    }
}