.searchRa {
    display: flex;
    margin: 0 10px;
    align-items: center;
    height: 60px;
    .inputBox {
      width: 300px;
      margin-right: 10px;
    }
  }
  .searchLeads {
    margin: 10px;
    min-height: 260px;
    max-height: 260px;
    overflow: auto;
    table {
      font-size: 12px;    
      border-spacing: 0 8px;
      border-collapse: unset;
      thead {
        tr {
          background-color: transparent;
          th {
            padding: 5px 10px;
            &.cityList {
              width: 300px;
              flex-wrap: wrap;
              text-align: center;
            }
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background: #e0e7ff;
            td {
              padding: 5px 10px;
              &.cityList {
                width: 300px;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    } 
  }
  .searchAlert{
    color: #2e374d !important;
    font-size: 10px !important;
    text-align: left;
  }
  .searchAlert::after{
    content: none !important  ;
  }