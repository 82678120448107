.leadCheckinMenu {
    margin-left: 20px;
    a {
        font-size: 14px;
        font-weight: bold;
        color: #2e384d;
        letter-spacing: 1px;
        position: relative;
        padding: 0px;
        margin:0;
        height: 60px;
        display: flex;
        align-items: center;
        text-decoration: none !important;
        &:hover {
            color:#0037ff;
            &::before {
                content: '';
                border: 1px solid #0037ff;
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 70px;
            }
        }
        &.titleActive {
            color:#0037ff;
            &:hover {
                &::before {
                    content: '';
                    border: 1px solid #0037ff;
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 70px;
                }
            }
            &::before {
                content: '';
                border: 1px solid #0037ff;
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 70px;
            }
        }
    }
} 