.leadCheckinWrapper {
    padding: 50px 40px 0 40px;
    .leadCheckInFixedWrapper {
      // position: fixed;
      // top: 60px;
      // z-index: 2;
      // left: 0;
      // right: 0;
      // margin: 0px 0 0px 0;
      // height: 100px;
      background-color: #f4f6fc;
      
    }
    .leadSelectWrapper {
      padding-bottom: 30px;
      .selectLead {
        width: 380px;
        margin: auto;
      }
    }
    .leadCheckInTable {
        table {
            border-spacing: 0 8px;
            border-collapse: unset;
            position: relative;
            width: 100%;
            margin-top: 0px;
            tr {
                th {
                    height: 40px;
                    font-size: 12px;
                    color: #2e384d;
                    letter-spacing: 2px;
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: default;
                    padding: 0px 5px 10px 5px;
                    z-index: 1;
                    margin: 0;
                    position: relative;
                  &:first-child {
                    padding-left:20px
                  }
                  p {
                    margin: 0;
                    padding: 0;
                    position: fixed;
                    top: 280px;
                  }
                }
                td {
                    color: #2e384d;
                    font-size: 12px;
                    background: #fff;
                    height: 48px;
                    border: 0;
                    letter-spacing: 0.5px;
                    padding: 0 5px;
                    border: solid 1px transparent;
                    box-shadow: none;
                    white-space: nowrap;		
                    max-width: 20ch;		
                    overflow: hidden;		
                    text-overflow: ellipsis;
                    &:first-child{
                      padding-left: 20px;
                      border-top-left-radius: 6px;
                      border-bottom-left-radius: 6px;
                    }
                    &:last-child{
                      border-top-right-radius: 6px;
                      border-bottom-right-radius: 6px;
                    }
                  }
                &:hover {
                  box-shadow: 0px 4px 8px 0 rgba(0, 55, 255, 0.1);
                  td:first-child {
                    border-left: solid 1px #0037ff;
                  } 
                  td:last-child {
                    border-right: solid 1px #0037ff;
                  }
                  td {
                    border-bottom: solid 1px #0037ff;
                    border-top: solid 1px #0037ff;
                  }
                }
              
                &:hover:first-child {
                  box-shadow: none;
                }  
            }
        }
    }
  .backButton{
    margin-left: 30px;
    margin-top: 10px;
  }
  .appointmentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 220px;
  }
}
.searchCta {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
}
.searchTable {
  margin-top: 30px;
}

@media screen and (min-width: 600px){
  .leadCheckinWrapper {
    .leadCheckInTable {
      overflow-y: scroll;
      table {
        margin-top: 0px;
        thead {
          position: relative;
          tr {
            th {
              p{
                max-width: none;
                position: static;
              }
            }
          }
          // &::after {
          //   content: '';
          //   position: fixed;
          //   left: 40px;
          //   right: 40px;
          //   background-color: #f4f6fc;
          //   height: 40px;
          //   top: 270px;
          // }
        }
        tbody{
          tr {
            td {
              max-width: none;
              &:last-child {
                padding-right: 10px;
                p{
                  max-width: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1025px){
  .leadCheckinWrapper {
    .leadCheckInTable {
      overflow-y: scroll;
      table {
        thead {
          z-index: 1;
          tr {
            th {
              p{
                max-width: none;
              }
            }
          }
        }
        tbody{
          tr {
            td {
              &:last-child {
                p{
                  max-width: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
