.callLogCard {
    border-radius: 8px;
    border: solid 1px #e0e7ff;
    background-color: #ffffff;
    display: flex;
    min-height: 100px;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin-bottom: 12px;
}