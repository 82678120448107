.raJourneyDocumentationPopup {
    min-width: 500px;
}
.tokenwrapper {
    display: flex;
    align-items: center;
}
.tokenwrapper h4 {
    background-color: #979797;
    height: 18px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 10px;
    border-radius: 10px;
}
.tokenwrapper h4.token {
    background-color: #F5AE4D;
    color: #FFFFFF;
}
.assistance {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #2E384D; 
    margin: 0 0 0 40px;
    padding: 0 0 0 22px;
    position: relative;
}
.assistance::before {
    content: '';
    background-image: url('./images/info.svg');
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0;
    left: 0;
}
.documentsWrapperList ul {
    margin: 25px 0 0 0;
    padding: 0;
}
.documentsWrapperList ul li {
    display: flex;
    padding-bottom: 20px;
}
.documentsWrapperList ul li h3 {
    width: 40%;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #585858;
    margin: 0;
    padding: 0;
}
.documentsWrapperList ul li label {
    list-style: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #585858;
    position: relative;
    padding-bottom: 20px;
    margin: 0;
    width: 40%;
}
.documentsWrapperList ul li span {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #979797;
    padding-left: 20px;
    margin: 0;
    width: 60%;
    position: relative;
}
.documentsWrapper ul li span.pending {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #979797;
    padding-left: 20px;
    margin: 0;
}
.documentsWrapperList ul li span.pending::before, .documentsWrapperList ul li span.done::before, .documentsWrapperList ul li span.error::before  {
    content: '';
    background-image: url('./images/pending.svg');
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0;
    left: 0;
}
.documentsWrapperList ul li span.done {
    color: #00BA67;
    padding-left: 20px;
}
.documentsWrapperList ul li span.error {
    color: #E02020;
    padding-left: 20px;
}
.documentsWrapperList ul li span.done::before {
    content: '';
    background-image: url('./images/done.svg');
    width: 12px;
    height: 12px;
}
.documentsWrapperList ul li span.error::before {
    content: '';
    background-image: url('./images/error.svg');
    width: 12px;
    height: 12px;
}
.bankinfo {
    margin-top: 5px;
}
.bankinfo p {
    margin: 0 !important;
    padding: 0 0 0px 0 !important;
    font-size: 12px;
    color: #000 !important;
    font-weight: 600;
    line-height: 20px;
} 
.bankinfo span {
    padding: 0 !important;
    margin: 0 !important;
    font-weight: normal;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}
.infoIcon {
    cursor: pointer;
}