.saveCta, .cancelCta {
    border:none;
    outline: none;
    border-radius: 4.5px;
    width: 190px;
    height: 47px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}
.saveCta {
    box-shadow: 0 6px 12px 0 rgba(0, 55, 255, 0.2);
    background-color: #0037ff;
    color:#fff;
}
.saveCta:hover {
    box-shadow: 0 0px 1px 0 rgba(0, 55, 255, 0.2);
}
.cancelCta {
    color:#2e384d;
    background: none;
}
.saveCta:focus, .cancelCta:focus {
    outline: none;
}  
.blueButton:disabled, .greenButton:disabled, .smallCta:disabled, .saveCta:disabled {
    background-color: #b0bac9;
    color: #fff;
    cursor: not-allowed;
}
.blueButton:disabled:hover, .greenButton:disabled:hover, .smallCta:disabled:hover, .saveCta:disabled:hover {
    background-color: #b0bac9;
}
.blueButton, .greenButton, .greyButton, .primaryCta, .selfCheckinCta, .greyTextCta, .generateCta {
    border-radius: 8px;
    height: 40px;
    padding: 0 10px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    background: #0037ff;
    outline: none;
    transition: all 0.2s;
    min-width: 120px;
} 
.blueButton:active{
    background-color: rgba(0, 55, 255, 0.80);
    transition: all 0.2s;
}
.blueButton:focus, .greenButton:focus, .greyButton:focus, .primaryCta:focus, .selfCheckinCta:focus, .greyTextCta:focus, .generateCta:focus {
    outline: none;
}
.greenButton, .greenButton:active {
    background-color: #52e281;
}
.greyButton {
    background-color: #d8d8d8;
    color: #2e384d;
}
.primaryCta {
    width: 100%;
}
.secondaryCta {
    border: none;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #0037ff;
    cursor: pointer;
    text-transform: uppercase;
    padding: 0 10px; 
    background-color: transparent;
}
.secondaryCta:focus {
    outline: none;
    border: none;
}
.secondaryCta:disabled, .primaryCta:disabled, .resceduleCta:disabled{
    cursor: auto;
    opacity: 0.5;
}
.allocate::before:disabled {
    opacity: 0.5;
}
.transparentBtn{
    border-radius: 4.5px;
    border: none;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: #0037ff;
    border: 1px solid #0037ff;
    cursor: pointer;
    text-transform: uppercase;
    background: #fff;
    padding: 0 10px;
}
.transparentBtn:active{
    color: rgba(0, 55, 255, 0.70);
    transition: all 0.2s;
    outline: 0;
}
.transparentBtn:focus{
    outline: 0;
}
.lockImg{
    background: #0037ff url(./images/lock-white.svg) no-repeat 8px 8px;
    padding-left: 35px;
}
.smallCta {
    letter-spacing: 0.5px;
    text-align: center;
    border-radius: 8px;
    border: solid 1px rgba(0, 55, 255, 0.1);
    color: #0037ff;
    background: transparent;
    height: 32px;
    min-width: 80px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: bold;
    padding: 0 10px;
  }
  .smallCtaActive {
    letter-spacing: 0.5px;
    text-align: center;
    border-radius: 8px;
    color: #fff;
    background-color: #0037ff;
    height: 32px;
    min-width: 80px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: bold;
    border: none;
    outline: none;
  }
  .resceduleCta {
    letter-spacing: 0.5px;
    text-align: left;
    color: #0037ff;
    background: transparent;
    height: 32px;
    min-width: 80px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: bold;
    border: none;
    outline: none;
    font-size: 12px;
  }
   .smallCta:hover {
        background-color: #0037ff;
        color: #fff;
  }
  .smallCta:focus, .smallCtaActive:focus, .resceduleCta:focus {
    outline: none;
    border: none;
  }

.disabled {
    cursor: not-allowed;
}
.priceButton {
    font-size: 24px;
    width: 100%;
    cursor: default;
}

.ctaLeft {
    width: 46%;
    float: left;
}
.ctaRight {
    width: 50%;
    float: right;
}
.viewPdf {
    background-color: #085992;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}
.viewPdf:focus {
    outline: none;
    border: none;
}

.activeCta{
    border-radius: 8px;
    height: 34px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    background: #0037ff;
    outline: none;
    transition: all 0.2s;
    cursor: pointer;
}
.deactiveCta {
    border:none;
    outline: none;
    border-radius: 4.5px;
    height: 34px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
}
.selfCheckinCta {
    border-radius: 5px;
    box-shadow: 0 5px 10px 0 rgba(237, 108, 13, 0.3);
    background-image: linear-gradient(257deg, #f8931f, #ff6359);
    padding: 0 16px;
}
.allocate, .greenCta {
    position: relative;
    padding-left: 30px;
    height: 40px;
}
.allocate::before, .greenCta::before {
    content: '';
    background-image: url('../../images/clockBlue.svg');
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    left: 0px;
    bottom: 0;
    width: 24px;
    height: 24px;
}
.greenCta {
    color: #00ba67;
    margin: 0;
    padding: 0 0 0 30px;
    border: 0;
    background-color: transparent;
    font-weight: 500;
}
.greenCta:focus {
    outline: none;
    border: none;
}
.greenCta::before {
    content: '';
    background-image: url('../../images/clockGreen.svg');
    top: 10px;
    left: 0;
}
.greyTextCta {
    background-color: transparent;
    color: #c5c5c5;
}
.bookHi {
    font-size: 12px;
    font-weight: normal;
    font-weight: bold;
}
.addressCancelCta {
    width: auto;
    font-size: 14px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.addressCancelCta:focus {
    outline: none;
    border: none;
}
.ml-10{
    margin-left: 10px;
}
.generateCta {
    height: 20px;
    font-size: 12px;
    padding: 0 10px;
    min-width: auto;
    background-color: #0037FF;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 0px 12px;
    cursor: pointer;
}
.underline {
    text-decoration: underline;
}
.c24CtaRight {
    float: right;
    width: 50%;
    margin-right: -25px;
}
.generateCta:focus {
    border: none;
    outline: none;
}
