.statusPending{
  background: #ff6359;
}
.statusProgress{
  background: #44ca6f;
}
.statusPending, .statusProgress {
  color: #fff;
  padding: 6px 5px;
  font-size: 12px;
  border-radius: 8px;
  display: block;
  width: 100px;
  text-align: center;
  font-weight: bold;
}