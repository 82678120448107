.reauctionCta {
    display: flex;
}
.closeModal {
    background: none;
    border:none;
    outline: none;
    width: 190px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    color: #2e384d;
}
.closeModal:focus {
    outline: none;
}
.reAuction p {
    font-size: 12px;
    margin: 15px 0;
    padding: 0;
}
.ctaWrapper {
    margin: 50px 0 0 0;
}