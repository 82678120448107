.critical {
   background-color: #ff6359;
   color: #fff;
   border-radius: 8px;
   letter-spacing: 0.5px;
   font-size: 12px;
   font-weight: bold;
   display: flex !important;
   justify-content: center;
   align-items: center;
   height: 30px;
   width: 110px;
 }