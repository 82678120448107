.DiffReportPopup{
    max-height: 600px;
}
.DiffReportPopup .title{
    color: #002441;
    font-size: 24px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 40px;
}

.DiffReportPopup .priceSpan{
    display: flex;
}

.DiffReportPopup .priceLabel{
    color: #465166;
    margin-bottom: 40px;

    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    text-wrap: nowrap;
    white-space: nowrap;
}

.DiffReportPopup .priceLabel:last-child{
    margin-left: 40px;
}

.diffReportTable{
    background-color: #0F5288;
    color: #FFFFFF;
    padding: 20px;
    /* display: unset; */
    text-align: center;
    position: sticky;
    top: 0;
}

.diffReportTable th
{
    padding: 15px 12px;
    text-align: center;
    text-wrap: nowrap;
    white-space: nowrap;
    width: 30%;
}

.diffReportTable th:first-child{
    text-align: left;
}

.DiffReportPopup .tablePrice{
    text-align: center;
    background-color: #F1F8FF;
    padding: 15px !important;
}

.DiffReportPopup .priceWrapper{
    text-align: center;
    background-color: #F1F8FF;
    padding: 20px !important;
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
}


.tablePrice td{
    padding: 15px 10px;
    margin-top: 180px;
    width: 25%;
    text-align: center;
}


.tablePrice td:first-child {
    text-align: left;
}
.DiffReportPopup .criteria{
    color: #002441;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    text-transform: capitalize;
}

.DiffReportPopup .price{
    color: #EB5757;
    font-size: 14px;
    font-weight: 700;
}

.DiffReportPopup .subPart{
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.DiffReportPopup .subPart td{
    padding: 15px 12px;
    text-align: center;
    width: 25%;
}

.DiffReportPopup .subPart td:first-child{
    text-align: left !important;
}

.DiffReportPopup .subCriteria{
    font-weight: 600;
    color: #002441;
    text-align: left;
    text-transform: capitalize;
}

.DiffReportPopup .totalDifference{
    color: #0059A3 !important;
    font-size: 14px;
    font-weight: 700;
}

.DiffReportPopup .tableWrapper{
    max-height: 500px;
    overflow: auto;
}
