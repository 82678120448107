.emailWrapper {
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
}

.emailWrapper .email {
    font-size:14px;
    color: #b0bac9;
    display: flex;
    margin: 0 10px 0 0;
    position: relative;
}
.emailWrapper .emailArrow {
    display: flex;
}
.emailWrapper .emailArrow p {
    margin:0;
    transition: all 0.5s ease
}
.emailWrapper.active .emailArrow p {
    transform: rotate(-180deg);
}
.emailWrapper .store {
    font-size: 12px;
    color: #4a4a4a;
    font-weight: bold;
    margin: 0;
}
.dropDown.active  {
    display: block;
}
.dropDown{
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; 
    position: absolute;
    top: 42px;
    box-shadow: 0 6px 12px 0 rgba(0, 55, 255, 0.1);
    width: 100%;
    z-index: 1;
    transition: all 0.2s;
    display: none;
}
.dropDown ul {
    margin: 0;
    padding: 0;
}
.dropDown ul li {
    list-style: none;
    margin: 0;
    padding:0;
    border-bottom: 1px solid #e0e7ff;

}
.dropDown ul li:last-child {border:none}
.dropDown ul li a {
    text-decoration: none;
    color: #4a4a4a;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding-left:55px;
    position: relative;
}
.dropDown ul li a:hover {
    color: #0037ff;
}
.changera::before {
    content: '';
    width: 32px;
    height: 19px;
    background-image: url('../images/changera.svg');
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 10px;
}
.changera::after {
    content: '';
    border-bottom: 1px solid #f4f6fc;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
}
.profile::before {
    content: '';
    width: 18px;
    height: 18px;
    background-image: url('../images/profile.svg');
    background-repeat: no-repeat;
    background-position: -12px -17px;
    position: absolute;
    left: 23px;
    top: 10px;
}
.logout::before {
    content: '';
    width: 18px;
    height: 18px;
    background-image: url('../images/logout.svg');
    background-repeat: no-repeat;
    position: absolute;
    left: 23px;
    top: 10px;
}
@media screen and (min-width: 600px)  {
    .emailWrapper .email {
        white-space: nowrap;
        max-width: 10ch;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@media screen and (min-width: 1025px)  {
    .emailWrapper .email {
        white-space: nowrap;
        max-width: none;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}