.similarCarsWrapper {
    padding: 40px;
    .carsWrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 30px;
        .carsCard {
            border-radius: 8px;
            border: solid 1px rgba(0, 55, 255, 0.08);
            background-color: #ffffff;
            display: flex;
            padding: 15px;
            &:nth-child(odd) {
                margin-bottom: 10px;
            }
            &:nth-child(even) {
                margin-bottom: 10px;
                margin-left: 10px
            }
            .carImage {
                width: 160px;
                height: 170px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 24px;
                img {
                    max-width: 100%;
                    border-radius: 8px;
                }
            }
            
            .price {
                color: #2e384d;
                font-size: 24px;
                font-weight: 600;
                margin: 0 10px 0 20px;
                padding: 0;
                position: relative;
                &::before {
                    content: "₹";
                    position: absolute;
                    top: 0px;
                    color: #2e384d;
                    font-size: 14px;
                    font-weight: normal;
                    margin-left: -16px;
                }
            }
            .similarCarsText {
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        margin: 16px 0;
                        
                        .carIcons {
                            width: 90px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 35px;
                            img {
                                height: auto;
                                display: block;
                                margin: auto;
                            }
                        }
                        p {
                            margin: 0;
                            padding: 0;
                            font-size: 14px;
                            font-weight: 600;
                            letter-spacing: 1px;
                            color: #585858;
                        }
                        p.carIconsText, p.carIconsTextStore {
                            color: #0037ff;
                            width: 90px;
                            text-align: center;
                            letter-spacing: 0.43px;
                            margin-right: 50px;
                        }
                        p.carIconsTextStore {
                            margin-right: 35px;
                        }
                    }
                }
            }
            
        }
        
    }
}