.carDetailsReport {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;
}
.carDetailsReport .modelDetails, .carDetailsReport .appointmentid {
    font-size: 16px;
    font-weight: 600;
    color: #2e384d;
    margin: 0;
    padding: 0;
}
.carDetailsReport .appointmentid {
    font-weight: normal;
    padding-top: 5px;
}
.carInfo {
    flex: 0.9;
  }
.greenText, .orengeText, .redText {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    flex: 0.6;
    text-align: right;
}
.greenText {
    color: #44ca6f;
}
.orengeText {
    color: #f77000
}
.redText {
    color: #fa0000;
}
.priceTextAmount {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #585858;
}
.priceWrapper {
    display: flex;
    justify-content: space-between;
}
.priceSlider {
    margin-top: -30px;
    flex: 0.4;
}