/* .checkboxHolder{
  border-radius: 4.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 55, 255, 0.1);
  background-color: #ffffff;
  margin: 14px 0;
  padding: 0px 17px;
  height: 40px;
} */
/* .checkboxHolder:hover {
  box-shadow: 0 1px 3px 0 #0037ff;
} */
input[type="radio"] {
    display: none
  }
.checkboxHolder label {
    cursor: pointer;
    top: 6px;
    position: relative;
    color: #212529;
    
  }
  input[type="radio"] + label:before,
  input[type="radio"]:checked + label:before {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin-right: 8px;
    position: relative;
    padding: 0;
    content: "";
    display: inline-block;
    top: 5px;
  }

  input[type="radio"] + label:before {
    background-color: #e0e7ff;
  }
  input[type="radio"]:checked + label:before {
    background-color: #0037ff;
    border: 2px solid #e0e7ff;
  }
  input[type="radio"]:focus + label::before {
      outline: transparent;
  }