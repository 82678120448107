.customerYoursCars {
    border-radius: 8px;
    box-shadow: 2px 5px 15px 0 rgba(205, 197, 197, 0.45), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: 210px;
    position: relative;
    &::after {
        content: '';
        border-right: 1px solid #205c8c;
        position: absolute;
        top: -20px;
        right: -40px;
        height: 340px;
        opacity: 0.3;
    }
    .yoursCarsWrapper {
        width: 210px;
        height: 172px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }
    .yoursCarsContent {
        background-color: #205c8c;
        padding: 18px 23px 30px 18px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        .carsPrice {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #ffffff;
            img  {
                width: 11px;
                margin-top: -2px;
            }
        }
        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.08px;
            color: #ffffff;
          }
          .Your-Car-Driven-65 .text-style-1 {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.24px;
        }
    }

}
.heading {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #2e384d;
    text-align: center;
    margin: 0px 0 20px 0;
    text-transform: uppercase;
}