.quote_container_data__label{
  font-size: 11px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #9EA7BB;
  margin-bottom: 4px;
}

.quote_container_data__value{
  font-size: 11px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin: 0px !important;
}

.seller_intent_modal_div{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 592px;
  min-height: 300px;
  max-height: 550px;
}
.sim_header{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #465166;
}
.sim_description{
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #465166;
}
.sim_description span{
  font-weight: 700;
  text-decoration: underline;
}

.sim_input{
  padding-bottom: 20px;
  border-bottom: 2px solid #E6EEF6;
  margin-bottom: 10px;
}
.sim_input_radio{
  display: flex;
}
.sim_input_label{
  font-size: 13px;
  font-weight: 400;
  line-height: var(--fontlineheightsm);
  text-align: left;
  color: #465166;
  margin-left: 5px;
  cursor: pointer;
}
.sim_content{
  display: flex;
  flex-direction: column;
}
.sim_content_header{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #465166;
}

.sim_pitch{
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  padding: 8px 14px;
  gap: 8px;
  border-radius: 12px;
}
.sim_pitch_header{
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  color: #465166;
  margin: 0 !important;
}
.sim_pitch_data{
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14.52px;
  color: #465166;
  margin: 0 !important;
}
.sim_pitch_data span{
  font-weight: 700;
  text-decoration: underline;
}

.sim_pitch_pitch{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: red;
  margin: 0 !important;
}

.sim_content{
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.sim_content_header{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.sim_content_header_title{
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  margin: 0;
}
.sim_content_header_icon{
  margin: 0;
  cursor: pointer;
  border: 2px solid #465166;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sim_content_header_data{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sim_content_header_data_grid{
  display: flex;
  flex-direction: column;
  border: 1px solid #E6EEF6
}
.sim_content_header_data_grid_headers{
  display: flex;
  background-color: #F8FBFF;
  border-bottom: 1px solid #E6EEF6;
  border-radius: 8px 8px 0px 0px;
}
.sim_content_header_data_grid_headers_items{
  width: 25%;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px; 
  padding: 16px 12px;
  box-sizing: content-box; 
}
.sim_content_header_data_grid_td{
  display: flex;
}
.sim_content_header_data_grid_td_value{
  width: 25%;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.73px;
  padding: 16px 12px;
  box-sizing: content-box; 

}
.sim_content_header_data_documents{
  display: flex;
  flex-direction: column;
}
.sim_content_header_data_documents_header{
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  color: #465166;
  margin: 5px 0px; 
}
.sim_content_header_data_documents_listing{
    display: flex;
    flex-direction: column;
    padding: 0px !important;
}
.sim_content_header_data_documents_list_items{
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #465166;
  list-style-position: inside;
}

.sim_actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.sim_actions_submit{
  background-color: #0037ff;
  color: white;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.sim_actions_submit_disabled{
  background-color: #BEBEBE;
  color: white;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: not-allowed;
}

.sim_actions_cancel{
  color: #0037ff;
  border: 1px solid #0037ff;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.sf_intent_change{
  color: #0037ff;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  margin-left: 5px;
  cursor: pointer;
}

.hypo_dsi_falg{
    width: 140px;
    height: 22px;
    padding: 2px 8px;
    border-radius: 16px;
    background: #FEF3F2;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #B42318;
    position: absolute;
    right: 50px;
}


