.customerConsentChargesCongratulations {
    background-color: #fff;
    padding: 0px 0 10px 0;
    .customerConsentChargesHeader {
        background-color: #FFFFFF;
        box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.193537);
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #465166;
            margin: 0;
            padding: 0;
        }
    }
    .congratulationsImgSection {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex: 100%;
        img {
            display: block;
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #465166;
            margin: 16px 0 0 0;
            padding: 0;
            width: 100%;
            text-align: center;
        }
        p {
            margin: 8px 0 0 0;
            padding: 0;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #465166;
            opacity: 0.7;
            span {
                font-weight: bold;
            }
        }
    }
    .retailWrapper {
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 89, 163, 0.1);
        border-radius: 5px;
        margin: 20px 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .retailTextWrapper {
            display: flex;
            align-items: center;
            .retailName {
                margin-left: 10px;
                p {
                    margin: 0;
                    padding: 0;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: #465166;
                }
                span {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 22px;
                    color: #465166;
                }
            }
        }
        .callIcon {
            margin-top: 10px;
        }
    }
    .retailStatus {
        margin-top: 20px;
        padding: 0 20px;
        h4 {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #465166;
            margin: 0;
            padding: 0;
        }
        .statusWrapper {
            .status {
                position: relative;
                padding-left: 17px;
                // border-left: 1px solid #F17E01;
                // &::before {
                //     content: '';
                //     border-left: 1px solid #F17E01;
                //     position: absolute;
                //     top: 10px;
                //     left: 0;
                //     height: 93%;
                //     bottom: auto;
                // }
                h3 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #465166;
                    margin: 22px 0 0 0;
                    padding: 0;
                    position: relative;
                    &::before {
                        content: '';
                        background-image: url('./images/status.svg');
                        background-repeat: no-repeat;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 0px;
                        left: -28px;
                        margin: auto;
                    }
                }
                h3.border1 {
                    display: block;
                    &::after{
                        content: '';
                        border-left: 1px solid #F17E01;
                        position: absolute;
                        top: 12px;
                        left: -16px;
                        height: 170%;
                        bottom: 0;
                    }
                }
                h3.border2 {
                    &::after{
                        content: '';
                        border-left: 1px solid #F17E01;
                        position: absolute;
                        top: -340%;
                        left: -16px;
                        height: 366%;
                        bottom: 0;
                    }
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: #465166;
                    margin: 0;
                    padding: 13px 0 0 0;
                    span {
                        display: block;
                    }
                }
            }
        }
    }
    button {
        background: linear-gradient(180deg, #F8931F 0%, #FF6359 100%);
        box-shadow: 0px 5px 10px rgba(237, 108, 13, 0.5);
        border-radius: 5px;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        width: 90%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin: 20px auto 0;
    }
}
.statusWrapper .status h3.border1 > h3.border2:after {
    content: 'he' !important;
    border-left: 0px !important;
}