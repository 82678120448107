.reasonConfirmPopup {
    width: 300px;
    
    .text {
        font-size: 14px;
        line-height: 20px;
        margin: 20px 0 30px 0;
    }
    .ctaWrapper {
        margin: 30px auto 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}