.cjOfferPriceInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(126, 133, 148, 0.08);
    border-radius: 20px;
    
    .imageSpace {
        margin: 49px 0 39px;
    }
    .heading{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #465166;
    }
    .info {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #0059A3;
    }
}

.buttonWrap {
    margin-top: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 10px;
}

.buttonWrap button {
    width: 100%;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 12px;
    height: 40px;
}