.leadCard {
    margin:10px;
    // width: 90%;
    box-shadow: 0px 5px 10px rgba(0, 63, 184, 0.1);
    border-radius: 8px;
    background: #F1F8FF;
}
.leadCard ul {
    margin: 5px;
    padding: 5px;
}

.leadCard ul li {
    font-size: 14px;
    letter-spacing: 1px;
    color: #585858;
    padding: 6px 0 6px 0px;
    display: flex;
    justify-content: space-between;
}

.leadCard ul li p {
    margin: 0;
    padding: 0;
}
.leadCard ul li .detailsIcon {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carDetail ul li .detailsIcon img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.leadCard ul li .detailsInfo {
    margin: 0;
    padding: 0;
}

.actionWrapper {
    display: flex;
    justify-content: space-between;
    background: #F1F8FF;
}
.actionHeader {
    color: #465166;
    .accepted {
        color: #00BA67;
    }
    .rejected {
        color: #EB5757;
    }
}
.action {
    color: #EF6E0B;
    span {
        font-weight: 600;
        text-transform: uppercase;
    }
}
.leadTitle {
    font-weight: bold;
    font-style: normal;
}
.rejectionReasonText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}
.rejectionReasonText img {
   display: block;
   margin-left: 20px; 
}
.rejectionReasonText p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #7E8594;
    margin: 0 0 4px 0 !important;
}
.rejectionReasonText h3, .rejectionReason h4 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #465166;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    resize: horizontal;
}
.rejectionReason {
    width: 100%;
}
.rejectionReason h4 {
    display: none;
}
.rejectionReason.active img {
    transform: rotate(-180deg);
}
.rejectionReason.active h4 {
    display: block;
}