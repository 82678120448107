.checkInPopup {
  max-width: 400px;
}
.checkinText{
  padding-top: 11px;
  padding-bottom: 20px;
}
.txt, .userDetail {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #2e384d;
  margin: 0;
  padding-bottom: 8px;
}
.userDetail {
  font-weight: bold;
}

.checkbox {
  font-size: 14px;
  color: #2e384d; 
  display: flex;
  align-items: center;
}
.checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
.checkbox span {
  line-height: 16px;
  padding-left:10px;
}
.checkinForm {
  margin: 10px 0 15px;
}
.ctaButton {
  display: flex;
  justify-content: space-around;
  margin-top:12px;
}
.primaryEmail span {
  font-size: 12px;
  color: #b0bac9;
}
.primaryEmail p {
  font-size: 14px;
  color: #2e384d;
  letter-spacing: 0.5px;
  padding-bottom: 24px;
  margin: 0;
}
.primaryEmail {
  margin-bottom: 10px;
}
.registrationNo {
  margin: 10px 0;
}
.text {
  margin: 0;
  padding: 15px 0 0 0;
  font-size: 14px;
  line-height: 22px;
  color: #FF3F64;
}
.text2 {
  font-size: 14px;
  line-height: 22px;
  color: #465166;
  margin: 0;
  padding: 10px 0 0 0;
}
.preInspection {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #0037FF;
  margin: 25px 0 0 0;
  padding: 0;
}
.preInspection span {
  position: relative;
  padding-right: 14px;
  cursor: pointer;
}
.preInspection span::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-image: url('../../components/images/preInspectionArrow.svg');
  background-repeat: no-repeat;
  width: 8px;
  height: 13px;  
}