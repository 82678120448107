.carDetail {
    margin-left: 10px;
    width: 90%;
}

.carDetail ul {
    margin: 0;
    padding: 0;
}

.carDetail ul li {
    list-style: none;
    font-size: 14px;
    letter-spacing: 1px;
    color: #585858;
    padding: 6px 0 6px 0px;
    position: relative;
    display: flex;
}

.carDetail ul li p {
    margin: 0;
    padding: 0;
}

.carDetail ul li .detailsIcon {
    margin: 0;
    padding: 0;
    width: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0037ff;
}

.reportCtaWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.detailsInfo .reportCtaDeactive {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.detailsInfo .reportCtaDeactive a,
.detailsInfo .reportCtaActive a {
    font-size: 12px;
    font-weight: bold;
    color: #0037ff;
    border: 1px solid #0037ff;
    height: 20px;
    border-radius: 5px;
    padding: 0 5px;
}

.detailsInfo .reportCtaDeactive a:hover,
.detailsInfo .reportCtaActive a:hover {
    text-decoration: none;
}

.reportCtaActive a {
    background-color: #0037ff;
    color: #fff !important;
}

.carDetail ul li .detailsIcon img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.carDetail ul li .detailsInfo {
    margin: 0;
    padding: 0;
    width: 90%;
}

.sohiWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.leadMon {
    background: #00BA67;
    border-radius: 71px;
    color: #fff;
    text-align: center;
    width: 102px;
    height: 24px;
}

.carDetail ul li small {
    color: #0037ff;
    font-weight: bold;
    font-size: 14px;
}

.noPadding {
    padding-bottom: 0 !important;
}

.downloadReport {
    padding-left: 25px !important;
}

.downloadReport::before {
    background-image: url('../images/download_icon.svg');
    background-position: 0;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 16px;
    height: 16px;
}

.carDetail ul li span {
    display: inline-block;
}

.emailMaxword {
    white-space: nowrap;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.allocate {
    font-weight: bold;
    color: #0037ff;
    font-size: 14px;
    cursor: pointer;
    display: block !important;
}

.generateCta {
    background-color: #0037FF;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 0px 12px;
    cursor: pointer;
}

.generateCta:focus {
    border: none;
    outline: none;
}

.vnNumberWrapper {
    width: 160px;
}

.carDetail ul li .detailsInfo .homeInspection,
.carDetail ul li .detailsInfo .sellOnline,
.carDetail ul li .detailsInfo .sohiText {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #2E384D;
    padding: 3px 10px;
    margin: 0;
    background-color: #CCF1E1;
    border-radius: 70px;
}

.carDetail ul li .detailsInfo .sellOnline {
    background-color: #FDF0CC;
}

.carDetail ul li .detailsInfo .sohiText {
    word-spacing: 12px;
    position: relative;
    background-color: #CCE9FF;
    max-width: 70px !important;
    min-width: 70px;
}

.carDetail ul li .detailsInfo .sohiText::after {
    content: '';
    background-image: url('../images/sohiIcon.svg');
    background-repeat: no-repeat;
    width: 11px;
    height: 6px;
    position: absolute;
    top: 0;
    /* right: 7%; */
    bottom: 0;
    left: 45%;
    margin: auto;
}

.sohiWrapperContent{
    display: flex;
    gap: 5px;
}

.inspectionBought{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 4px;
    gap: 2px;
}
.inspectionBoughtTier{
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    color: white;   
}
.inspectionBoughtTierIcon{
    font-family: 'Inter';
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    width: 10px;
    height: 10px; 
    font-size: 7px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
}

.inspectionBoughtTierIcon:hover + .inspectionBoughtTooltip{
    display: block;
}

.inspectionBoughtTooltip{
    display: none;
    position: absolute;
    background-color: black;
    color: white;
    width: fit-content;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    padding: 4px 12px !important;
    border-radius: 6px;
    top: -30px;
    transform: translate(-50%, 0px);
    left: calc(100% - 8px);
    z-index: 3;
}

.inspectionBoughtTooltip::after{
    content: '';
    background-color: black;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translate(-50%, 0px) rotate(45deg);
    z-index: 2;
}

.inspectionBought:global(.SILVER){
    background: linear-gradient(290.7deg, #7C7360 0%, #DFDEDC 106.01%);
}

.inspectionBought:global(.GOLD){
    background: linear-gradient(290.7deg, #8C6A27 0%, #D89512 106.01%);
}