:global .MuiFormLabel-root {
    line-height: normal !important;
}
:global .PrivateNotchedOutline-root-59{
    color: #9aa1b5 !important;
}
.textIconWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.textIconWrap h4, .remarks {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.textIconWrap h4{
    margin: 0;
    padding-right: 11px;
    width: 220px;
}
.remarks{
    font-size: 12px;
    color: #2e384d;
    line-height: 21px;
    margin: 0px;
    width: 250px;
}
.inputWrap{
    position: relative;
}
.inputWrap::before {
    content: "₹";
    position: absolute;
    left: 10px;
    top: 7px;
    font-size: 18px;
    color: rgba(46, 56, 77, 0.6);
    z-index: 1;
}
.inputWrap input{
    padding-left: 25px;
}
.expectedSection {
    position: relative;
    margin-top: 32px;
}
.expectedSection:focus-within button{
    color: #0037ff;
}
.expectedSection button{
    background: none;
    border: none ;
    outline: none;
    color: #9aa1b5;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    position: absolute;
    top: 26px;
    right: 0px;
}
:global .MuiFormControl-root {
    width: 100% !important;
}
.expectedSection button:active, .expectedSection button:focus{
    outline: none;
    color: #5165af;
}
:global  .makeStyles-textField-1 {
    width: 100%;
    margin: 0 !important;
}
/* :global .MuiOutlinedInput-root:hover :global .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 55, 255, 0.08) !important;
} */
.errorText{
    color: red;
    font-size: 12px;
    font-weight: normal;
    display: block;
  }
:global .MuiFormControl-marginDense {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.loader {
    position: absolute;
    top: 7px;
    right: 8px;
    margin: 0;
}
.expectedSection .inputWrap label {
    left: 10px;
}
.expectedSection .inputWrap :global label.Mui-focused, :global label.MuiFormLabel-filled {
    left: 0 !important
}
:global .MuiFormControl-root {
    margin-top: 0 !important;
}

.customerPrice {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #585858;
}
.customerPrice span {
    margin: 5px 0;
}
.sellLaterDate {
    margin: 5px 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #585858;
}
.customerPrice span.price {
    position: relative;
    padding-left: 15px;
}
.customerPrice span.price::after {
    content: '₹';
    position: absolute;
    left: 0;
    top: 0;
}

.bestTpFactor {
    position: relative;
    padding-left: 15px;
    color: #06B968  !important;
}
.bestTpFactor::after {
    content: '₹';
    position: absolute;
    left: 0;
    top: 0;
}
.dayTime {
    font-size: 12px;
    width: 100px;
    padding-left: 10px;
    text-align: center;
    margin-top: 5px;
}
.msgText {
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #000;
    font-style: italic;
}
.responseList {
    margin-right: -20px;
    margin-top: 10px;
}
.responseList ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.responseList ul li {
    list-style: none;
    width: 50%;
    margin-bottom: 10px;
}
.responseList ul li p {
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: #2E384D;
    font-weight: bold
}
.responseList ul li span {
    font-size: 10px;
    font-weight: normal;
}
.responseList ul li span h4, .responseList ul li span h5 {
    font-weight: normal;
    font-size: 10px;
    color: #2E384D;
    display: flex;
}
.overText {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.overText span {
    white-space: nowrap;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 24px;
}
.responseList ul li span h5 {
    color: #0037ff;
    text-decoration: underline;
    cursor: pointer;
    padding-right: 25px;
    margin: 0;
}
.responseList ul li span {
    position: relative;
}
.toolTipWrapper {
    position: absolute;
    bottom: 8px;
    background-color: #333;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    right: -150px;
    left: auto;
    display: none;
}
.toolTip {
    width: 200px;
    min-height: 50px;
    max-height: 130px;
    overflow: auto;
}
.toolTipWrapper::after {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #333;
    position: absolute;
    bottom: -15px;
    right: 15px;
}    
.toolTipTextWrapper:hover .toolTipWrapper {
    display: block;
}
.priceAccepted {
    margin: 20px 0 0 0;
    border-bottom: 1px solid #E0E7FF;
    padding-bottom: 20px;
}
.priceAccepted ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.priceAccepted ul li {
    list-style: none;
    color: #2E384D;
    font-size: 10px;
    font-weight: bold;
    width: 40%;
}
.priceAccepted ul li span {
    font-weight: normal;
}
.responseMainWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.responseMainWrapper .response p, .responseMainWrapper .response .responseText, .responseMainWrapper .response .msgText {
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: #2E384D;
    font-weight: bold;
}
.responseMainWrapper .response .responseText {
    font-weight: 600;
    display: block;
}
.responseMainWrapper .response .msgText {
    color: #a3a4a7;
    font-style: normal;
}
.viewDetails {
    color: #0037FF;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.response :global .pending {
    color: #967200 !important;
}
.response :global .rejected {
    color: #DE350B  !important;
}
.response :global .accepted {
    color: #06B968  !important;
}
.editWrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.sendCta {
    background-color: #0037FF;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    padding: 4px 20px;
    margin-top: 10px;
    cursor: pointer;
}
.sendCta:focus {
    outline: none;
}
.sendCta:disabled {
    cursor: not-allowed;
    opacity: 0.4;
}
.whatsappchat {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #00B934;
    border: 1px solid #00B934;
    border-radius: 5px;
    margin: 0px;
    padding: 8px 5px;
    display: inline;
    text-align: right;
}

.rtTargetLabel {
    font-size: 12px;
    font-weight: bold;
    /* line-height: 20px; */
    color: #0037ff;
    width: 24px;
}

.pickyAssistDiv{
  list-style: none;
  font-size: 14px;
  letter-spacing: 1px;
  color: #585858;
  padding: 6px 0 6px 0px;
  position: relative;
  display: flex;
}
.pickyAssistDivIcon{
  margin: 0;
  padding: 0;
  width: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #0037ff;
}
.pickyAssistDivButton{
  font-size: 12px;
  font-weight: bold;
  color: #0037ff;
  border: 1px solid #0037ff;
  height: 20px;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}
.offerAppicableDiv{
  display: flex;
  justify-content: space-between;
}
.offerAppicableDiv_heading{
  width: 150px;
  padding: 2px 4px;
  gap: 10px;
  border-radius: 12px;
  background-color: #4DBB3E;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.offerAppicableDiv_price{
  font-size: 14px;
  font-weight: 500;
  color: #4DBB3E;
  display: flex;
  align-items: center;
  justify-content: center;
}