.freezeHeader {
  top: 60px !important;
  transform: none !important
}
.freezeHeader.sticky {
  transform: none !important;
  z-index: 5 !important;
  padding-right: 0;
  right: 0;
  left: 0;
  width: 100% !important;
  padding-left: 105px;
}
.sticky {
  z-index: 1 !important;
}
.removeTransform, .sticky {
  transform: none !important;
  z-index: 2 !important;
}
.layout_openMenu__11afW .freezeHeader.sticky {
  left: 120px !important;
  padding-right: 120px;
}
