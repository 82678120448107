.wrapper{
    height: calc(100vh - 66px);
    overflow-y: scroll;
    background: #F5F5F5;
    padding: 0 20px;
}
.wrapper ul {
    padding: 0;
    list-style-type: none;
}
.wrapper ul li {
    padding:0;
} 
.wrapper ul li .priceWrap {
    min-height: 60px;
    background: radial-gradient(93.63% 93.63% at 50.02% 6.37%, #0071BC 0%, #113167 50%, #001B4B 75%, #000035 100%);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    margin-top: 32px;
    span {
        font-size: 10px;
        line-height: 18px;
        display: block;
        padding-top: 4px;
        opacity: 0.7;
        color: #FFFFFF;
        margin: 10px;
    }
}
.wrapper ul li .appointment {
    margin-top: 20px;
    span {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #002441;
    }
    
    .address {
        margin-top: 15px;
        border: 1px solid #DDE5EB;
        border-radius: 12px;
        padding: 10px;
        span {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.01em;
            color: #465166;
        }
        p {
            margin-top: 15px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #465166;
        }
    }
    .dealer {
        padding: 10px;
        display: flex;
        flex-direction: row;
        border-radius: 12px;
        background: #FAFAFA;
        border-radius: 14px;
        order: 1;
        flex-grow: 0;
        img {
            position: relative;
            left: 16px;
            margin-right: 20px;
        }
        .info {
            margin-left: 20px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.01em;
        }
    }
}
.wrapper ul li .price{
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
    display: block;
}
.wrapper ul li .buttonWrap{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 8px 20px;
    z-index: 1;
}
.wrapper ul li .buttonWrap button {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    box-shadow: none;
}
.wrapper ul li .buttonWrap button.btnAccept {
    text-align: center;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
}