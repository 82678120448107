.headar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0px 15px;
    margin: 6px 0;
    .logoSection {
        flex: 1 1 60%;
    }
    .logoSection, .waitheaderRight {
        display: flex;
        align-items: center;
    }
    p {
        margin: 0;
        padding: 0;
    }
    .store {
        padding-left: 20px;
        font-size: 22px;
        font-weight: 600;
        color: #0059a3;
    }
    .waitheaderRight {
        font-size: 13px;
        line-height: 24px;
        font-weight: 700;
        justify-content: flex-end;
        flex: 1 2 40%;
        .ourSupport {
            position: relative;
            padding-left: 30px;
            margin-right: 20px;
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                border-radius: 100px;
                background-color: #c80039;
                position: absolute;
                top: 4px;
                left: 0;
            }
        }
        .startingShortly {
            position: relative;
            padding-left: 30px;
            &::before {
                content: '';
                width: 20px;
                height: 20px;
                border-radius: 100px;
                background-color: #f79d00;
                position: absolute;
                top: 4px;
                left: 0;
            } 
        }
    }
}
.waitimeDashboard {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}
.contentWrapper {
    // position: fixed;
    // height: calc(100vh - 52px);
    top: 52px;
    left: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    height: 100vh;
    transition: opacity 2.3s;
    img { 
        max-width: 100%;
        max-height: 100%;
    }   
    table {
        thead {
            tr {
                th {
                    padding: 0px 20px;
                    height: 34px;
                    font-size: 16px;
                    color: #ffffff;
                    font-weight: 900;
                    background-color: #0059a3;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 0 20px;
                    height: 34px;
                    font-size: 16px;
                    color: #465166;
                    font-weight: 700;
                    .count {
                        width: 30px;
                        height: 30px;
                        border-radius: 8px;
                        background: #0059a3;
                        padding: 4px 7px 4px 7px;
                        text-align: center;
                        color: #ffffff;
                        margin: 0 0 0 40px;
                    }
                    .countWrapper {
                        display: flex;
                        align-items: center;
                        position: relative;
                    }
                }
                &:nth-child(even) {
                    background-color: #eaeff3;
                }
                .redCircle, .orangeCircle {
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    display: block;
                    position: absolute;
                    left: 0;
                }
                .redCircle {
                    background-color: #c80039;
                }
                .orangeCircle {
                    background-color: #f79d00
                }
            }
        }
    }
}