.virtualWrapper {
    .lastCallTime {
        font-size: 12px;
        color: #2e384d;
    }
    .callStatusRed, .callStatusGreen, .callStatusBlue {
        position: relative;
        color: #E02020;
        margin: 0;
        padding-left: 30px;
        font-size: 12px;
        &::before {
            content: '';
            position: absolute;
            top: 28%;
            left: 0;
            background-image: url('../images/callLogRed.svg');
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            background-size: 90%;
        }
    }
    .callStatusGreen {
        color: #00BA67;
        &::before {
            background-image: url('../images/callLogGreen.svg');
        }
    }
    .callStatusBlue {
        color: #5900cc;
        &::before {
            background-image: url('../images/callLogBlue.svg');
        }
    }
}
.vrnumber {
    text-overflow: unset !important;
    overflow: auto !important;
    max-width: none !important;
    white-space: normal !important;
}