.activitiesWrapper{
    width: 100%;
}
.activities {
    border-radius: 8px;
    border: solid 1px #e0e7ff;
    background-color: #ffffff;
}
.noCall {
    background-color: #fff;
    border:1px solid #e0e7ff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-weight: 400;
}