.upload {
    display: flex;
    align-items: center;
    height: 40px;
    width: 111px;
    background-color: #E0E7FF;
    border-radius: 3px;
    position: relative;
    margin: 0;
    label {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: -20px;
    }
    img {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
    input {
        display: none;
    }
    span {
        font-size: 12px;
        font-weight: 600;
        color: #0037ff;
        padding-left: 10px;
        cursor: pointer; 
        padding: 0;
    }
}