.sellOnlineOurPrice {
    width: 33.3%;
    .congratulationsFlag {
        background-image: linear-gradient(to left, #f7b500, #ffe8aa);
        min-height: 60px;
        position: relative;
        margin: -14px -33px -18px -18px;
        border-top-right-radius: 5px;
        padding-left: 70px;
        padding-right: 10px;
        &::before {
            content: '';
            background-image: url('../images/gift.svg');
            width: 41px;
            height: 45px;
            position: absolute;
            top: 0;
            left: 16px;
            bottom: 0;
            margin: auto;
        }
        h6 {
            opacity: 0.8;
            font-size: 12px;
            font-weight: 600;
            color: #2e384d;
            margin: 0;
            padding-top: 10px;
            font-style: italic;
            text-align: left;
        }
        h4 {
            font-size: 14px;
            font-weight: bold;
            color: #2e384d;
            margin: 10px 0 0 0;
            padding: 0 0 10px 0;
            line-height: 18px;
            font-style: italic;
            text-align: left;
        }
    }
    .ourPriceSection {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 26px;
        h4 {
            font-size: 12px;
            letter-spacing: 2px;
            color: #ED6C0D;
            padding: 0;
            margin: 0;
            text-transform: uppercase;
        }
        .price {
            margin: 0;
            padding: 0;
            font-size: 30px;
            line-height: 40px;
            color: #2E384D;
            font-weight: 700;
        }
    }
    .priceAcceptedFlagBlue, .priceAcceptedFlagGreen {
        margin: 0px -14px 5px 0;
        display: flex;
        justify-content: flex-end;
        h6{
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            color: #0059A3;
            margin: 0;
            background-color: rgba(0, 89, 163, 0.19);
            border: 1px solid #0059A3;
            border-right: none;
            box-sizing: border-box;
            height: 26px;
            display: flex;
            align-items: center;
            padding: 0 10px 0 30px;
            border-bottom-left-radius: 24px;
            border-top-left-radius: 24px;
            position: relative;
            &::before {
                content: '';
                background: linear-gradient(180deg, #205C8C 0%, #3D89BA 100%);
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                left: 3px;
                border-radius: 100%;
                bottom: 0;
                margin: auto;
            }
            &::after {
                content: '';
                background-image: url('../../components/images/whiteRightSign.svg');
                background-repeat: no-repeat;
                width: 11px;
                height: 9px;
                position: absolute;
                top: 0;
                left: 8px;
                bottom: 0;
                margin: auto;
            }
        }
    }
    .priceAcceptedFlagGreen {
        h6 {
            color: #09C262;
            margin: 0;
            background-color: rgba(9, 194, 98, 0.13);
            border: 1px solid #09C262;
            border-right: none;
            box-sizing: border-box;
            &::before {
                content: '';
                background: linear-gradient(180deg, #219F28 0%, #09C262 100%);
            }
        }
    }
    .ctaWrapper {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .allocateTimeslot {
            margin-left: 20px;
        }
        .cjEmail, .cjDate, .cjTime {
            font-size: 12px;
            color: #465166;
            line-height: 18px;
            position: relative;
            padding-left: 30px;
            display: block;
            margin-bottom: 10px;
            &::before {
                content: '';
                width: 24px;
                height: 24px;
                background-image: url('../../components/images/cjIcon.svg');
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
            }
        }
        .cjDate {
            &::before {
                content: '';
                background-image: url('../../components/images/calender_appointment.svg');
            }
        }
        .cjTime {
            &::before {
                content: '';
                background-image: url('../../components/images/timeIcon.svg');
                background-size: 100%;
            }
        }
    }
    .staticText {
        h3, h4 {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: #585858;
            position: relative;
            padding-left: 30px;
            margin: 0;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background-image: url('../../components/images/greenSign.svg');
                width: 20px;
                height: 20px;
            }
        }
        h4 {
            &::before {
                content: '';
                background-image: url('../../components/images/calender_appointment.svg');
                width: 24px;
                height: 24px;
            }
        }
        p {
            margin: 0;
            padding: 10px 0 10px 30px;
            font-size: 12px;
            color: #585858;
        }
    }
}
.reCancelCtaWrapper {
    display: flex;
}
.reCancelCta  button {
    color: #FF6359;
}