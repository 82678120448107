.leadFilterWrapper{
    padding: 0px 0px 20px 0px;
    .storeList {
        margin-top: 30px;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                background-color: #fff;
                border-radius: 6px;
                border:1px solid #e8e9ec;
                padding: 10px;
                margin: 10px 0;
                p {
                    margin: 0;
                    padding-left: 10px;
                }
                .storeIdWrapper {
                    display: flex;
                    align-items: center;
                }
                .assignWrapper {
                    min-width: 190px;
                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                    }
                    .name {
                        color: #2e384d;
                    }
                    p.active {
                        color: #0037ff;
                    }
					.tempico {
						padding: 8px;
					}
                }
            }
        }
    }
    .submitButton {
        display: flex;
        justify-content: flex-end;
        margin: 0 40px 40px 0;
    }
    
}