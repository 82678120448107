.buyNowPilotPopup {
    min-width: 300px;
    .customerDesc{
        display: block;
        margin: 11px 0 0 0;
        padding: 0;
        li {
            display: inline-block;
            list-style-type: none;
            font-size: 14px;
            color: rgba(46, 56, 77, 0.67);
            &::after {
                content: "|";
                margin: 0 5px;
            }
            &:last-child::after {
                content: "";
            }
        }
    }
    .inputWrap {
        margin: 30px 0 25px 0;
        .msg {
            font-size: 11px;
            color: #465166;
        }
    }
    .info {
        font-size: 14px;
        color: #465166;
        position: relative;
        width: 200px;
        padding-left: 40px;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url('../images/latestInfo.svg');
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
        }
    }
    .ctaWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
    }
}