.customerSimilarCars {
    width: 100%;
    .carsCard {
        width: 210px;
        height: 317px;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(205, 197, 197, 0.45);
        background-color: #ffffff;
        margin-bottom: 5px;
        margin-left: 10px;
        .carsCardImage {
            width: 210px;
            height: 172px;
            display: flex;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }
    }
    .yoursCarsContent {
        padding: 18px 23px 40px 18px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        .carsPrice {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1.54px;
            color: #455373;
            display: flex;
            img {
                margin: 0px 4px 0 0;
                width: 11px;
            }
        }
        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.08px;
            color: #455373;
          }
          .Your-Car-Driven-65 .text-style-1 {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.24px;
        }
    }
    
}
.carouselWrapper {
    width: 850px;
    margin-left: 130px;

    :global .slick-prev, :global .slick-next {
        width: 47px;
        height: 47px;
        box-shadow: 0 1px 2px 0 rgba(199, 189, 189, 0.5);
        background-color: #ffffff;
        border-radius: 100%;
        top: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            color: #000;
        }
    }
    // :global .slick-slide {
    //     width: 210px !important;
    //     margin-right: 80px;
    // }
}
.carouselWrapper :global .slick-prev {
    left: -60px;
    z-index: 1;
}
.carouselWrapper :global .slick-next {
    right: -10px;
    z-index: 1;
}
.carouselWrapper :global .slick-prev:hover, .carouselWrapper :global .slick-prev:focus, .carouselWrapper :global .slick-next:hover, .carouselWrapper :global .slick-next:focus {
    color: #000;
    outline: none;
    background: #fff;
}

.heading {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #2e384d;
    text-align: center;
    margin: 0px 0 20px 0;
}
.staticSlider {
    display: flex;
    justify-content: center;
    .carsCard {
        margin: 0 20px;
    }
}