.leadAppointment {
    border-radius: 8px;
    border: 1px solid #e0e7ff;
    padding: 15px 0 38px 30px;
    background-color: #fff;
    margin-top: 15px;
    .textWrapper {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        padding-top: 32px;
        .detailsLeftWrapper {
            flex: 0.6;
        }
    }
    .detailsRightWrapper {
        flex: 0.4;
    }
    .leadMon {
        background: #00BA67;
        border-radius: 71px;
        color: #fff;
        text-align: center;
        width: 102px;
        height: 24px;
    }
}

.seller_intent_div{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


