
.soAllocateRAPopup {
    width: 400px;
    min-height: 600px;
  }
  .txt {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: rgb(46, 56, 77);
    padding-bottom: 8px;
    margin: 5px 0 0 0;
  }
  .cjTable {
    margin-top: 20px;
        table {
            border-spacing: 0 0px;
            border-collapse: unset;
            thead {
                display: table;
                width: 100%;
                table-layout: fixed;
                tr {
                    th {
                        background-color: #f4f6fc;
                        font-size: 16px;
                        font-weight: 600;
                        color: #2e384d;
                        letter-spacing: 1px;
                        padding: 10px;
                        &:nth-child(1) {
                            width: 70%;
                        }
                        &:nth-child(2) {
                            width: 30%;
                        }
                    }
                }
            }
            tbody {
                display: block;
                max-height: 230px;
                overflow-y: auto;
                tr {
                    td {
                        padding: 10px;
                        font-size: 14px;
                        letter-spacing: 0.58px;
                        color: #000000;
                        &:nth-child(1) {
                            width: 70%;
                        }
                        &:nth-child(2) {
                            width: 30%;
                            text-align: center;
                        }
                      }
                }
            }
        }
  }
  .radioButton {
    display: flex;
    align-items: center;
  }
  .text {
    padding-top: 12px;
    margin: 0;
    span {
        display: block;
    }
  }
  .ctaBox {
    display: flex;
    margin-top: 25px;
  }
  .searchWrapper {
    .search {
        height: 40px;
        border-radius: 3px;
        border: solid 1px #e0e7ff;
        background-color: #ffffff;
        padding: 2px 10px;
        position: relative;
        min-width: 211px;
        display: flex;
        margin-top: 10px;
        input {
            border: none;
            outline: none;
            width: 100%;
            font-size: 12px;
            color: #2e384d;
        }
        button {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            margin: 0;
            padding: 0;
            &:focus {
                outline: none
            }
        }
    }
}
.searchRest {
    font-size: 12px;
    color: #2e384d;
}

.allocatecj {
    display: grid;
    grid-template-columns: 7% 43% 7% 43%;   
    row-gap: 20px;
    column-gap: 10px;
    font-size: 14px; 
    margin-top: 20px;
    align-items: center;
    .mr10 {
        margin-right: 10px;
    }
}