.raLeads {
    padding: 50px 10px 0 10px;
}
.title {
    font-weight: 600;
    font-size: 16px;
    color: #465166;
}
.leadSearch {
    height: 32px;
    border-radius: 3px;
    border: solid 1px rgba(0, 55, 255, 0.2);
    background-color: #ffffff;
    padding: 0px 0 0px 10px;
    position: relative;
    min-width: 280px;
    display: flex;
}

.leadSearch  input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 12px;
    color: #2e384d;
}

.searchIcon {
    background-image: url('../../../components/images/search.svg');
    background-position: 5px 8px;
    background-repeat: no-repeat;
    width: 32px;
    height: 34px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
} 
.searchIcon:focus {
    outline: none;
    border: none;
}

.navHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #F5F5F5;
}

.leadSearchLoader {
    position: absolute;
    top: 2px;
    right: 10px;
}
.loadMore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}