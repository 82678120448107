.customerOfferPricePopup {
    min-width: 550px;
    .customerResponce {
        margin-top: 20px;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            li {
                list-style: none;
                width: 50%;
                color: #656C7B;
                margin: 10px 0;
                p {
                    margin: 5px 0;
                    padding: 0;
                    font-size: 12px;
                }
                span {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
    .noResponse {
        margin-top: 30px;
        display: flex;
        align-items: center;
        img {
            margin-right: 40px;
        }
        p {
            font-size: 18px;
            color: #465166;
            margin: 0 0 5px 0;
            padding: 0;
        }
        span {
            font-size: 14px;
            color: #465166;
        }
    }
}