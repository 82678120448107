.soCancelAppointmentPopup {
    width: 300px;
    min-height: 300px;
    .heading {
        // text-align: center;
    }
    .title {
        margin: 0;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #2e384d;
    }
    .inputWrap {
        margin-top: 30px;
        width: 100%;
        textarea {
            padding: 10px;
            border: 1px solid hsl(0,0%,80%);
            border-radius: 5px;
            position: relative;
            z-index: 1;
            background: transparent;
            color: #2e384d;
            font-weight: 500;
            box-shadow: none;
            font-size: 14px;
            width: 100%;
            z-index: 0;
        }
    }
    .ctaWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        button {
            background-color: #FF6359;
        }
    }
    .customerDesc{
        display: block;
        margin: 0;
        // padding-bottom: 24px;
        padding-left: 0;
    }
    .customerDesc li{
        display: inline-block;
        list-style-type: none;
        font-size: 14px;
        color: rgba(46, 56, 77, 0.67);
    }
    .customerDesc li::after {
        content: "|";
        margin: 0 5px;
    }
    .customerDesc li:last-child::after {
        content: "";
    }
}
.inputWrap :global .react-select__menu .css-26l3qy-menu {
    height: 200px !important;
}
.inputWrap :global .react-select__menu-list {
    height: 200px !important;
    overflow: scroll !important;
}