.loginAttendancePopup {
    width: 350px;
    
    .headerText {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
    }
    .text {
        font-size: 14px;
        line-height: 20px;
        margin: 20px 0 30px 0;
    }
    .ctaWrapper {
        margin: 30px;
        display: flex;
        justify-content: space-around;
    }
    .highlight {
        color: red;
    }
}