.customerAppointmentPopup {
    min-width: 400px;
    .customerDesc{
        display: block;
        margin: 10px 0 0 0;
        padding-bottom: 24px;
        padding-left: 0;
    }
    .customerDesc li{
        display: inline-block;
        list-style-type: none;
        font-size: 14px;
        color: rgba(46, 56, 77, 0.67);
    }
    .customerDesc li::after {
        content: "|";
        margin: 0 5px;
    }
    .customerDesc li:last-child::after {
        content: "";
    }
    .customerLocationDetails {
        display: grid;
        grid-template-columns: 7% 93%;  
        row-gap: 20px;
        column-gap: 10px;
        font-size: 14px; 
    }
    .timeDataWrapper {
        display: grid;
        grid-template-columns: 50% 50%;  
        row-gap: 20px;
        column-gap: 10px;
    }
    .timeDataWrapper > div {
        display: flex;
        align-items: center;
    }
    .timeDataWrapper img {
        margin-right: 10px;
    }
    
    .ctaWrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 30px;
    }
    .errorMsg {
        color: #FF0000;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        margin: 20px 0 0 0;
        padding: 0 0 0 30px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            background-image: url('../../components/images/error.svg');
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
        }
    }
}
.timeDataWrapper .fixwrapper > div {
    width: 100%;
    
}