.inputShift{
    position: relative;
}
.inputShift input {
  padding: 0 10px;
  border: 1px solid hsl(0,0%,80%);
  height: 40px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: transparent;
  color: #2e384d;
  font-weight: 500 ;
  box-shadow: none;
  font-size: 14px;
}
.inputShift input:focus {
  outline: none;
  box-shadow: none;
  background: #fff ;
  border:2px solid  #0037ff;
}
.inputShift label{
  position: absolute;
  top: 0;
  left: 10px;
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 0;
  line-height: 40px;
  color: #9aa1b5;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  font-size: 14px;
  padding: 0 10px;
  z-index: 0;
}
.inputShift label:after {
  position: absolute;
  content: '';
  height: 14px;
  background: #fff;
  display: none;
  left: 15px;
  top: 0px;
  z-index: -1;
}
.inputShift label.active,
.inputShift input:focus~label {
  padding: 0 10px;
  font-size: 12px;
  top: -7px;
  left: 10px !important;
  line-height: 13px;
  height: 14px;
  width: auto !important;
  display: inline-block !important;
  border-radius: 0;
  z-index: 1;
  background: #fff;
  margin: 0 10px;
}
.inputShift input:focus~label{
  color:#0037ff;
}
.inputShift label.active:after,
.inputShift input:focus~label:after {
  display: block;
}
.inputShift .error{
  color: #c80039;
  font-size: 12px;
  line-height: 6px;
}
.validatorInput input {
  border:1px solid red;
}
.validatorInput label {
  color: red;
}