.leadInfo {
  flex: 0.5;
  margin-right: 5px;
}
.searchId, .searchName {
  font-size: 14px;
  color: #2e384d;
  padding: 0;
  margin: 0;
}
.searchName {
  opacity: 0.6;
  padding-top: 5px;
}
.searchButton {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  align-items: center;
}
.searchButton p {
  color: #2e384d;
  font-size: 12px;
  background-color: #b0bac9;
  border-radius: 8px;
  margin: 0;
  padding: 2px 5px;
  min-width: 80px;
  text-align: center;
}