.lapse {
  color: #ff6359;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.highlight{
  color: #ff6359;
}

.buyNow{
  color: #0037ff;
} 