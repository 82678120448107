.carImage {
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease; 
    width: 194px;
    height: 172px;
    background-color: #c1c1c1;
    border-radius: 6px;
}
.carImage img {
    border-radius: 6px;
    height: 172px;
}
.carImage .carText {
    background-color: #000000ba;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin:0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 12px;
    color: #fff;
    height: 32px; 
    display: flex;
    justify-content: center;
    align-items: center; 
}
.lapse::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #ff6359;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    right: -10px;
    cursor: default;
}

.sellonlineStrips {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #F0C800;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #FFFFFF;
    height: 26px;
    width: 121px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.sellonlineStrips::after {
    content: '';
    position: absolute;
    right: 0;
    width: 0; 
    height: 0; 
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 15px solid #c1c1c1;
}