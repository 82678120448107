.sellerQuestion {
    padding-bottom: 20px;
}
.sellerQuestionHeader {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #465166;
}
.listTab{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.listTab li:not(:last-of-type){
    margin-bottom: 16px;
}
.gridView{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px 10px;
}

.metetCard{
    margin-bottom: 14px;
}
.questionMain {
    padding: 0 20px;
}
.questionHeading {
    margin-bottom: 12px;
}
.label {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    line-height: 20px;
    color: #7E8594;
}
.label > img {
    margin-right: 3px;
}
.customHeader {
    font-family: 'Poppins';
}