.wrapper{
    height: calc(100vh - 66px);
    overflow-y: scroll;
    background: #F5F5F5;
    padding: 0 20px;
    margin-top: 66px;
}
.wrapper ul {
    padding: 0;
    list-style-type: none;
}
.wrapper ul li {
    padding:0;
} 
.wrapper ul li .priceWrap {
    min-height: 60px;
    background: url('./images/leftBg.png'), url('./images/rightBg.png'), radial-gradient(93.63% 93.63% at 50.02% 6.37%, #0071BC 0%, #113167 50%, #001B4B 75%, #000035 100%);
    background-repeat: no-repeat;
    background-position: top left, top right;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    margin-top: 32px;
    span {
        font-size: 10px;
        line-height: 18px;
        display: block;
        padding-top: 4px;
        opacity: 0.7;
        color: #FFFFFF;
        margin: 10px;
    }
}
.wrapper ul li .congratulations{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01rem;
    color: #FFFFFF;
}
.wrapper ul li .price{
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
    display: block;
}
.wrapper ul li .buttonWrap{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 8px 20px;
    z-index: 1;
}
.wrapper ul li .buttonWrap button {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    box-shadow: none;
}
.wrapper ul li .buttonWrap button.btnReject {
    text-align: center;
    background-image: none;
    background: none;
    border: 2px solid #EF6E0B;
    color: #EF6E0B;
    margin-bottom: 16px;
}
.wrapper ul li .buttonWrap button.btnAccept {
    text-align: center;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
}
.congrates {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    opacity: 0.7;
}
.congrates span {
    font-size: 10px;
    line-height: 18px;
    display: block;
    padding-top: 4px;
}
.offersText {
    background: rgba(243, 247, 249, 0.2);
    border: 1px solid rgba(221, 229, 235, 0.1);
    border-radius: 32px;
    padding: 4px 8px 4px 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-top: 14px;
}
.offersText img {
    margin-right: 8px;
}