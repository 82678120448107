.customerSimilarCars {
    width: 100%;
    .carsCard {
        width: 350px;
        min-height: 166px;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(205, 197, 197, 0.45);
        background-color: #ffffff;
        margin-bottom: 5px;
        margin-left: 10px;
        display: flex;
        .carsCardImage {
            background-image: url('../../components/images/ImageBg.jpg');
            background-repeat: no-repeat;
            width: 158px;
            height: 165px;
            position: relative;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                max-width: 100%;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }
    }
    .yoursCarsContent {
        padding: 0px 0px 0px 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 170px;
        .carsPrice {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1.54px;
            color: #455373;
            display: flex;
            img {
                margin: 0px 4px 0 0;
                width: 11px;
            }
        }
        
          .Your-Car-Driven-65 .text-style-1 {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1.24px;
        }
    }
    
}
.carouselWrapper {
    width: 770px;
    margin-left: 130px;
    position: relative;
    &::before {
        content: '';
        border-left: 1px solid rgba(0, 89, 163, 0.4);
        position: absolute;
        left: -12%;
        top: 0;
        bottom: 0;
        height: 100%;
    }
    :global .slick-prev, :global .slick-next {
        width: 47px;
        height: 47px;
        box-shadow: 0 1px 2px 0 rgba(199, 189, 189, 0.5);
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            color: #000;
        }
    }
    // :global .slick-slide {
    //     width: 210px !important;
    //     margin-right: 80px;
    // }
}
.carouselWrapper :global .slick-prev {
    left: -60px;
    z-index: 1;
}
.carouselWrapper :global .slick-next {
    z-index: 1;
    right: -40px;
}
.carouselWrapper :global .slick-prev:hover, .carouselWrapper :global .slick-prev:focus, .carouselWrapper :global .slick-next:hover, .carouselWrapper :global .slick-next:focus {
    color: #000;
    outline: none;
    background: #fff;
}

.heading {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #465166;
    text-align: center;
    margin: 0px 0 20px 0;
    span {
        font-weight: bold;
    }
}
.staticSlider {
    display: flex;
    justify-content: center;
    .carsCard {
        margin: 0 20px;
    }
}
.carsSectionDetails {
    padding: 10px 0 0 0;
    h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #2E384D;
        margin: 0;
        padding: 0;
    }
    .varient {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #2E384D;
        padding-top: 6px;
    }
    .kmModel {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #2E384D;
        margin: 0;
        padding: 15px 0 0 0;
    }
    .ratingSection {
        margin-top: 10px;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                display: flex;
                justify-content: space-between;
                margin: 5px 0;
                h4 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #2E384D;
                    margin: 0;
                    padding: 0;
                }
                .highRating, .belowHigh {
                    background: rgba(33, 159, 40, 0.1);
                    border-radius: 3px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #219F28;
                    display: flex;
                    padding: 5px;
                    img {
                        padding-right: 5px;
                    }
                }
                .belowHigh {
                    color: #F38701;
                    background-color: #ffecd5;
                }
                .rating {
                    background-color: #fff;
                    border-radius: 3px;
                    padding: 3px 5px;
                    display: flex;
                    align-items: center;
                    .highRating, .below5 {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #219F28;
                        padding-left: 5px;
                    }
                    .below5 {
                        color: #F38701;
                    }
                }
            }
        }
    }
}