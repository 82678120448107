.sellOnlineCard{
    padding: 0 40px;
    .sellCard {
        border-radius: 8px;
        border: 1px solid rgba(0,55,255,.08);
        background-color: #fff;
        height: auto;
        display: block;
        padding: 15px;
        overflow: hidden;
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        position: relative;
        .carDetails {
            width: 33.3%;
        }
        .priceNegotiaton {
            border-left: 1px solid #e0e7ff;
            border-right: 1px solid #e0e7ff;
            justify-content: center;
            align-items: center;
            width: 33.3%;
            padding: 0 20px;
            margin: 0 18px;
            .qualityCallWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                span {
                    display: block;
                }
                .callConnected, .notCalled, .callnotConnected {
                    font-size: 10px;
                    letter-spacing: 0.42px;
                    margin: 0;
                    padding-left: 35px;
                    position: relative;
                    &::before {
                        content: '';
                        background-repeat: no-repeat;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        bottom: 0;
                        margin: auto;
                    }
                }
                .callConnected {
                    color: #00ba67;
                    &::before {
                        background-image: url('../../components/images/callLogGreen.svg');
                    }
                }
                .notCalled {
                    color: #0037ff;
                    &::before {
                        background-image: url('../../components/images/callLogNewBlue.svg');
                    }
                }
                .callnotConnected {
                    color: #e02020;
                    &::before {
                        background-image: url('../../components/images/callLogRed.svg');
                    }
                }
                .qualityCall {
                    margin: 0;
                    padding: 7px 10px;
                    background-color: #f3f5ff;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #00ba67;
                    position: relative;
                    padding-left: 38px;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 10px;
                        bottom: 0;
                        margin: auto;
                        background-repeat: no-repeat;
                        background-image: url('../../components/images/tokenDone.svg');
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .textIconWrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 16px 0;
                h4, .remarks {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                h4 {
                    margin: 0;
                    padding-right: 11px;
                    width: 220px;
                }
            }
            .text {
                font-size: 12px;
                color: #2E384D;
                margin: 0px;
                padding: 0;
                line-height: 18px;
            }
            .ctaWrapper {
                margin: 30px 0;
            }
            .addressWrapper {
                border-top: 1px solid #e0e7ff;
                margin-top: 10px;
                padding-top: 15px;
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 600;
                    color: #585858;
                }
                .editWrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    ul {
                        margin: 0;
                        padding: 0;
                        li {
                            list-style: none;
                            font-size: 12px;
                            line-height: 17px;
                            padding:  11px 0 11px 40px;
                            &.location, &.address {
                                position: relative;
                                &::before {
                                    content: '';
                                    background-image: url('../../components/images/arrowcj.svg');
                                    width: 26px;
                                    height: 25px;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                            }
                            &.address {
                                &::before {
                                    content: '';
                                    background-image: url('../../components/images/homecj.svg');
                                    width: 24px;
                                    height: 22px;
                                }
                            }
                        }
                    }
                }
                .dateTimeWrapper {
                    margin-top: 15px;
                    ul {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        li {
                            list-style: none;
                            padding-left: 40px;
                            font-size: 12px;
                            color: #2E384D;
                            margin-right: 30px;
                            &:last-child {
                                margin-right: 0;
                            }
                            &.date, &,time {
                                position: relative;
                                &::before {
                                    content: '';
                                    background-image: url('../../components/images/calander.svg');
                                    background-size: 100%;
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                            }
                            &.time {
                                &::before {
                                    content: '';
                                    background-image: url('../../components/images/clockGrey.svg');
                                    width: 26px;
                                    height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }   
    }
}
.followUpHistoryWrapper {
    display: flex;
    min-height: 400px;
}
.followupWrapper {
    width: 330px;
}
.followuphistory {
    width: 720px;
}