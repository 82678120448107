.headingSearchWrapper {
  padding: 0 40px 0px 40px;
  display: flex;
  justify-content: space-between;
  background: #f4f6fc;
  .toggleSection {
    background-color: #fff;
    height: 48px;
    width: 456px;
    border-radius: 8px 8px 0 0px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .arrowCalendar {
      display: flex;
      align-items: center;
      img {
        display: block;
        margin-right: 16px;
      }

    }
    .nextPrev {
      display: flex;
      img {
        display: block;
        margin: 0 10px;
        cursor: pointer;
        &.prev {
          transform: rotate(180deg);
        }
      }

    }
  }
}
.cjPlanningWrapper {
  padding: 0 40px;
  margin-top: 60px;
  .cjPlanningData {
    padding: 16px;
    background-color: #fff;
    border-radius: 0 8px 8px 8px;
    display: flex;
    .slotsWrapper {
      width: 150px;
      margin-top: 50px;
      span {
        display: block;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 1px;
        color: #2E384D;
        padding: 5px 0;
      }
    }
    .cjDataWrapper {
      display: flex;
      width: 90%;
      .cjData {
        width: 20%;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1px;
        h3, h4, span {
          font-weight: normal;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 1px;
          color: #979797;
          margin: 10px 0;
          text-align: center;
        }
        h4, span {
          color: #2E384D;
          display: block;
        }
        h3 {
          color: #2E384D;
          &.active {
            color: #003FB8;
            font-weight: 600;
          }
        }
        h4 {
          color: #979797;
        }
        h4.active {
          color: #003FB8;
        }
        span.leave {
          color: #E02020;
          font-weight: 600;
        }
      }
    }
  }

}
.cjPlanning {
  position: relative;
}
.arrowCalendar :global .datepicker_inputShift__1gC1Q :global .react-datepicker__input-container input {
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #2E384D;
}
.arrowCalendar :global .datepicker_inputShift__1gC1Q :global .react-datepicker__input-container {
  position: relative;
  &::after {
    content: '';
    background-image: none;
  }
}