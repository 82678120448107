.allocationStatusPopup {
    width: 600px;
    .allocationStatusPopupHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .notcj {
            background-color: rgba(224, 32, 32, 0.1);
            width: 191px;
            height: 88px;
            text-align: center;
            border-radius: 3px;
            p {
                color: #e02020;
                font-size: 36px;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }
            span {
                font-size: 14px;
                font-weight: 600;
                color: #2e384d;
                margin: 0;
                padding: 0;
            }
        }
    }
    table {
        margin-top: 20px;
        border-spacing: 0;
        border-collapse: unset;
        thead {
            display: table;
            width: 100%;
            table-layout: fixed;
            tr {
                th {
                    font-size: 12px;
                    color: #2e384d;
                    letter-spacing: 1px;
                    font-weight: 600;
                    text-transform: uppercase;
                    min-width: 10ch;
                    line-height: 40px;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 28px;
                    background-color: #f4f6fc;
                    padding: 0 10px;
                }
            }
        }
        tbody {
            display: block;
            max-height: 400px;
            overflow-y: auto;
            tr {
                border-bottom: 1px solid #e0e7ff;
                display: table;
                width: 100%;
                table-layout: fixed;
                td {
                    color: #2e384d;
                    font-size: 12px;
                    line-height: 40px;
                    padding: 0 10px;
                }
            }
        }
    }
}
