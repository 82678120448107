.wrapper{
    overflow: hidden;
    font-family: Roboto;
    border-radius: 10px 10px;
}
.wrapper>img{
    background: #C4C4C4;
    width: 100%;
    height: 155px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}
.footer{
    padding: 6px 10px;
    background: rgba(0, 113, 188, 0.1);
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center
}
.footer>img{
    margin-right: 8px;
}
.content{
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
    color: #172B4D;
}
.inputGroup{
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 24px;
    flex-wrap: wrap;
}
.inputForm{
    padding: 13px 13px;
    width: 1%;
    border: 1px solid;
    outline: 0;
    height: 48px;
    position: relative;
    flex: 1 1 auto;
    border-radius: 5px;
}
.inputForm:not(:last-child){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 13px;
}
.inputGroupAppend {
    margin-left: -2px;
    display: flex;
}

.footerRevamped {
    background: #F5F5F5;
}

.footerRevamped .content {
    color: #465166 !important;
}
.revampedInputWrapper {
    padding-bottom: 100px;
}

.revampedInputWrapper  input {
    border: 1px solid;
    box-shadow: 0px 0px 0px 2px rgba(239, 110, 11, 0.2);
    border-radius: 8px !important;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
}
.revampedInputWrapper .inputGroupAppend {
    margin-left: 16px;
}
.revampedInputWrapper .inputGroupAppend button {
    border-radius: 12px;
    height: 48px;
    line-height: 48px;
    background-image: none;
    background: #EF6E0B;
    color: #fff;
    box-shadow: none;
}