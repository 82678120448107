.carSpecificationWrap{
    width: 634px;
    padding: 0 0px 0 39px;
}
.specificationHeader{
    display: block;
    border-bottom: 1px solid #e0e7ff;
}
.specificationHeader p{
    font-size: 12px;
    font-weight: 600;
    color: #2e384d;
    letter-spacing: 2px;
    display: inline-block;
    padding: 0 16px;
    margin: 0;
    text-transform: uppercase;
    height: 38px;
    cursor: pointer;
}
.headerActive{
    color: #0037ff !important;
    font-weight: 600;
    position: relative;
}
.headerActive::after{
    content: "";
    width: 167.5px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #0037ff;
    display: block;
    border-radius: 2px;
}
.specificationHeader p.padding{
    padding-right: 120px;
}
.carDetailWrap{
    display: block;
    margin-top: 21px;
    max-height: 400px;
    overflow: auto;
    padding-right: 10px;
    margin-right: -10px;
}

.ratingOuter{
    border-radius: 8px;
    background-color: #e0e7ff;
    margin-bottom: 13px;
    cursor: pointer;
    transition: all 0.2s;
}
.detailsHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
.ratingOuter p{
    font-size: 16px;
    color: #2e384d;
    display: inline-block;
    margin: 0;
}
.ratingOuter ul{
    margin: 0;
    padding: 0;
    transition: all 0.2s;
}
.ratingOuter ul li:last-child {
    margin-right: 0;
}
.ratingOuter ul li{
    display: inline-block;
    list-style-type: none;
    cursor: pointer;
    margin-right: 8px;
}
.ratingOuter .arrowUp, .ratingOuter .arrowDown {
    margin-left: 5px;
}
.ratingOuter .arrowUp {
    transform: rotate(0deg);
}
.ratingOuter .arrowDown {
    transform: rotate(180deg);
}
.ratingOuter .carDescription{
    background: #e0e7ff;
    width: 100%;
    padding-top: 13px;
    border-top: 1px solid #fff;
    padding: 10px 20px;
}
.ratingOuter .dataListing li {
    display: flex !important;
    justify-content: space-between;
}
.ratingOuter .carDescription ul{
    width: 100%;
    padding: 0;
}
.ratingOuter .carDescription ul li{
    display: inline-block;
    list-style-type: none;
    width: 100%;
}
.ratingOuter .carDescription ul li span{
    display: inline-block;
    padding-right: 18px;
    padding-bottom: 0;
}
.ratingOuter .carDescription ul li span img{
    width: 14px;
    height: 11px;
}
.carDescription .detailTopic{
    font-size: 12px;
    line-height: 26px;
    color: #2e384d;
    position: relative;
    padding-bottom: 0;
}
.carDescription .detailResult{
    font-size: 12px;
    line-height: 26px;
    color: #2e384d;
    display: inline-block;
    padding-left: 26px;
    padding-bottom: 0;
}
.carDetailWrap::-webkit-scrollbar {
    opacity: 0.5;
    width: 6px;
}
.carDetailWrap::-webkit-scrollbar-track {
    background: #bbb;
    border: 1px solid rgba(96, 96, 96, 0.5);
    border-radius: 4px;
}
.carDetailWrap::-webkit-scrollbar-thumb {
    background: #e0e7ff;
    border-radius: 4px;
}
.carDetailWrap .ratingOuter .carDescription .dataList li{
    display: flex !important;
    justify-content: space-between;
}
.rfc {
    display: flex;
    background: #e0e7ff;
    height: 44px;
    align-items: center;
    border-radius: 8px;
    padding: 0 20px;
    font-size: 16px;
    color: #2e384d;
}
.rfcPrice::before {
    content: "₹";
    padding-right: 2px;
    padding-left: 4px;
}
.carDetails {
    background-color: #e0e7ff;
    border-radius: 8px;
    padding: 20px 0;
}
.carDetails ul li {
    list-style: none;
    font-size: 12px;
    color: #2e384d;
    line-height: 26px;
    display: flex;
}
.carDetails ul li span {
    min-width: 230px;
    position: relative;
}
.carDetails ul li span::after {
    content: ':';
    position: absolute;
    right: 50px;
}
