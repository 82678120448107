.wrapper {
    width: 1280px;
    margin: auto;
}
.logoHeader {
    height: 30px;
    box-shadow: 0 10px 10px 0 rgba(0,55,255,.15);
    display: flex;
    align-items: center;
    img {
        width: 50px;
    }
}
.offerPriceWrapper {
    background-color: #fff;
    margin-top: 8px;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgba(0, 32, 149, 0.05);
    background-color: #ffffff;
    &::before {
        content: '';
        background-image: url('../../components/images/border.svg');
        top: 0;
        right: 0;
        left: 0;
        height: 16px;
        position: absolute;
    }
    .offerlogo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        margin: 0;
        img {
            width: 160px;
        }
    }
    .title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #2e384d;
        padding-top: 15px;
        text-align: center;
        margin: 0;
    }
    .price {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.54px;
        color: #205c8c;
        text-align: center;
        margin-top: 5px;
        height: 40px;
        span {
            position: relative;
            &::before {
                content: '';
                background-image: url('../../components/images/ruppes.svg');
                position: absolute;
                left: -18px;
                top: 5px;
                width: 21px;
                height: 23px;
                background-size: 50%;
                background-repeat: no-repeat;
            }
            &::after {
                content: '';
                background-image: url('../../components/images/shape.svg');
                position: absolute;
                right: -60px;
                top: 0;
                width: 45px;
                height: 32px;
                background-size: 60%;
                background-repeat: no-repeat;
            }
        }
    }
    .offersSection {
        margin: 15px 35px 14px;
        display: flex;
        justify-content: space-between;
    }
}
.similarCars {
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    padding: 15px 35px;
    .customerYourcars {
        width: 246px;
    }
}
.priceSlider {
    position: relative;
    .priceTextAmountWrapper {
        position: absolute;
        top: -40px;
        right: 15%;
        width: 250px;
        .greenText, .orengeText, .redText {
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            flex: 0.6;
            text-align: left;
        }
        .greenText {
            color: #44ca6f;
        }
        .orengeText {
            color: #f77000
        }
        .redText {
            color: #fa0000;
        }
        .noData {
            font-size: 14px;
            padding-top: 50px;
            display: block;
        }
        .optimumAmount {
            position: absolute;
            top: 10px;
            color: #585858;
            font-size: 12px;
            font-weight: bold;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}