.congratulationsPrice {
    background: #FFFFFF;
    border: 1px solid #ECECED;
    box-shadow: 0px 5px 10px rgba(0, 89, 163, 0.15);
    border-radius: 10px;
    width: 93%;
    margin: 50px auto 20px;
    .congratulations {
        background: linear-gradient(268.56deg, #F7B500 16.91%, #FBCF56 51.12%, #FFE8AA 100%);
        height: 83px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
        padding: 0 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &::before {
            content: '';
            background-image: url('./images/congratulations.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            width: 64px;
            height: 64px;
            position: absolute;
            top: 0;
            left: 50px;
            bottom: 0;
            margin: auto;
            border-radius: 100%;
        }
        h4, h5 {
            margin: 0;
            padding: 0 0 0 80px;
            width: 100%;
        }
        h4 {
            font-size: 24px;
            line-height: 18px;
            color: #2E384D;
            text-transform: capitalize;
        }
        h5 {
            font-size: 18px;
            line-height: 18px;
            color: #2E384D;
            opacity: 0.8;
            margin-top: -15px;
        }
    }
    .taglist {
        background: linear-gradient(225deg, rgba(246, 155, 0, 0.9) 0%, rgba(237, 106, 0, 0.9) 100%);
        box-shadow: 0px -2px 19px rgba(85, 85, 85, 0.25);
        padding: 0 50px;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 46px;
            li {
                list-style: none;
                position: relative;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                padding: 0 0 0 34px;
                &::before {
                    content: '';
                    background-image: url('./images/right.svg');
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }

            }
        }
    }
    .marketPriceSection {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 50px;
        .priceSection {
            margin-top: 10px;
            h3{
                font-size: 48px;
                line-height: 72px;
                color: #F8B601;
                margin: 0;
                padding: 0;
                font-weight: bold;
            }
            p { 
                border: 1px solid #ED6A00;
                box-sizing: border-box;
                border-radius: 35px;
                min-height: 41px;
                margin: 0;
                padding: 0 15px;
                font-size: 24px;
                line-height: 28px;
                letter-spacing: 1.08333px;
                color: #2E384D;
                display: flex;
                font-weight: bold;
                align-items: center;
                span {
                    color: #ED6A00;
                    padding-right: 5px;
                }
            }
        }
        .bargains {
            background: rgba(237, 106, 0, 0.04);
            border: 1px solid #ED6A00;
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #465166;
            padding: 20px;
            margin: 0;
            width: 450px;
            span {
                color: #ED6A00;
            }
        }
    }
}