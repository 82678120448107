.filterSearch {
    border: 1px solid #e0e7ff;
    border-radius: 5px;
    padding: 2px 10px;
    display: flex;
    justify-content: flex-end;
    float: right;
    margin: 10px 30px 0 0;
    position: relative;
    input {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 14px;
        width: 100%;
    &::placeholder {
        color: #c1c1c1;
    }
}
    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    .closeSearch {
        font-size: 14px;
        color: darkgrey;
    }
    &::after {
        clear: both;
    }
}
div.searchError {
    color: #ff6359 !important;
    margin: 0;
    padding: 0;
    font-size: 12px !important;
    font-weight: normal !important;
    position: absolute !important;
    top: 30px;
    left: 0;
    
}