.paymentInitiateToken {
    padding: 80px 20px 50px 20px;
    .lostHeading {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .appid {
            font-size: 18px;
            color: #2e384d;
            font-weight: 600;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin: 0;
            padding: 0 0 0 5px;
        }
        .pageDirection {
            font-size: 10px;
            color: #465166;
            margin: 0;
            padding-left: 5px;
            text-transform: capitalize;
        }
    }
    .documentWrapper {
        background: #FFFFFF;
        border: 1px solid #E0E7FF;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 20px;
        h3 {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0.5625px;
            color: #2E384D;
            margin: 0;
            padding: 0;
        }
        .documentCardWrapper {
            display: flex;
            align-items: baseline;
            .documentCard {
                margin-right: 50px;
                h4 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #2E384D;
                    margin: 0;
                    padding: 27px 0 10px 0;
                }
                .fieldWrapper {
                    margin-top: 16px;
                }
            }
        }
        .thirdPartyWrapper {
            margin-top: 30px;
            .partyPayment {
                display: flex;
                align-items: center;
                h3 {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 0.5625px;
                    color: #2E384D;
                    margin: 0;
                    padding: 0 10px 0 0;
                }
            }
            
            .consentLetter {
                display: flex;
                align-items: flex-end;
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    line-height: 22px;
                    color: #9B9B9B;
                    margin: 0;
                    padding: 0 30px 0 0;
                }
                .active {
                    color: #2E384D;
                }
            }
            
        }
        .bankDetialsWrapper {
            margin-top: 20px;
            .bankDetialsHeading {
                display: flex;
                align-items: center;
                
                .sendSms {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.5625px;
                    color: #0037FF;
                    margin: 0;
                    padding: 0 50px 0 20px;
                    position: relative;
                    cursor: pointer;
                    &::after {
                        content: '';
                        background-image: url('../../components/images/sms.svg');
                        background-repeat: no-repeat;
                        width: 40px;
                        height: 18px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
                .smsSent {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.5625px;
                    color: #00BA67;
                    margin: 0 0 0 20px;
                    padding: 0 0 0 30px;
                    position: relative;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/rightRounded.svg');
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;;
                    }
                }
            }
            .bankText {
                display: flex;
                align-items: center;
                margin-top: 16px;
                p {
                    margin: 0;
                    padding: 0;
                }
                .primaryText {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    span {
                        display: block;
                        font-weight: bold;
                        font-size: 8px;
                        line-height: 11px;
                        color: #979797;
                    }
                }
                .pending {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    color: #FF6359;
                    padding-left: 20px;
                    margin-bottom: 11px;
                }
                .bankSuccess{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    color: #00BA67;
                    padding-left: 20px;
                    margin-bottom: 11px;
                }
            }
            .secondaryBank {
                display: flex;
                align-items: center;
                margin-top: 17px;
                p {
                    margin: 0;
                    padding: 0 28px 0 0;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000000;
                    span {
                        display: block;
                        font-weight: bold;
                        font-size: 8px;
                        line-height: 11px;
                        color: #979797;
                    }
                }
            }
            .bankDetailsField {
                display: flex;
                margin-top: 16px;
                .fieldWrapper {
                    margin-right: 10px;
                    min-width: 188px;
                }
            }
            .chequeWrapper {
                display: flex;
                align-items: center;
                p {
                    font-size: 16px;
                    line-height: 22px;
                    color: #9B9B9B;
                    margin: 0;
                    padding: 20px 20px 0 0;
                }
                .cheque {
                    color: #2E384D;
                }
            }
            .ctaWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 21px;
            }
        }
        
    }
    .uploadView {
        margin-top: 15px;
        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        }
        span.upload {
            color: #00BA67;
        }
        span.view {
            color: #0037FF;
            padding-left: 11px;
            cursor: pointer;
        }
        span.pending {
            color: #FF6359;
        }
        span.dsmText {
            color: #2E384D;
            font-weight: normal;
            padding-left: 11px;
        }
    }
    .ctaWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;
        button {
            margin: 0 10px;
        }
    }
    
}
.coverWrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .uploadView {
        margin-top: 0;
    }
}
.downloadLetter {
    display: block;
    color: #0037FF;
    font-size: 14px;
    letter-spacing: 0.56px;
    font-weight: 600;
    margin-left: 40px;
    position: relative;
    padding-left: 30px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:focus {
        border: none;
        background-color: transparent;
        outline: none;
    }
    &::before  {
        content: '';
        background-image: url('../../components/images/download.svg');
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 21px;
        height: 21px;   
    }
}

