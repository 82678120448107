.ownerFlag {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #000000c4;
  top: 15px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 24px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}