.depreciationCarReports {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .depreciationCarTitle {
        font-size: 14px;
        font-weight: 600;
        color: #2e384d;
        padding-bottom: 10px;
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            font-size: 12px;
            color: #2e384d;
            padding: 5px 0;
            span {
                width: 180px;
                display: inline-flex;
                position: relative;
                &::after {
                    content: ':';
                    position: absolute;
                    right: 20px;
                    top:0;
                }
            }
        }
    }
}