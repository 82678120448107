.paymentVehicleStatus {
    background-color: #f9faff;
    min-height: 166px;
    padding: 10px;
    position: relative;
    // border-bottom-right-radius: 10px;
    // border-top-right-radius: 10px;
    .stockStatus, .stockStatusDelay, .stockStatusDone {
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2e384d;
        padding-left: 30px;
        margin: 0;
        text-transform: uppercase;
        position: relative;
        &::before {
            content: '';
            background-image: url('../../components/images/pendingIcon.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
        }
    }
    .stockStatusDelay {
        &::before {
            content: '';
            background-image: url('../../components/images/delay.svg');
        }
    }
    .stockStatusDone {
        &::before {
            content: '';
            background-image: url('../../components/images/rightTickIcon.svg');
        }
    }
    .lostDeal {
        font-size: 12px;
        letter-spacing: 1px;
        color: #2e384d;
        display: flex;
        justify-content: center;
        margin: 0;
        padding-top: 5px;
        text-align: center;
        h6 {
            font-size: 12px;
            margin: 0;
            padding-left: 5px;
            line-height: unset;
        }
        h6.red {
            color: #e02020;
        }
        h6.blue {
            color: #0037ff;
        }
    }
    .tokanStatus {
        margin-top: 10px;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            li {
                list-style: none;
                text-align: center;
                // width: 50%;
                white-space: break-spaces;
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                    z-index: 0;
                }
                h4 {
                    margin: 30px 0 0 0;
                    padding: 0;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.5px;
                    font-weight: normal;
                }
            }
            .deliveryBorder, .deliveryBorderFailed {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 59%;
                    height: 1px;
                    background-image: linear-gradient(253deg, #c1eadc, #00B86A);
                    width: 112%;
                }
            }
        }
    }
    .stores {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #e0e7ff;
        display: flex;
        padding: 6px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        // border-bottom-right-radius: 10px;
        img {
            margin-right: 5px;
        }
    }
    .waitingCase {
        text-align: center;
        margin-top: 10px;
        .txt {
            font-size: 12px;
            color: #2e384d;
            margin: 8px;
            padding: 0;
        }
    }
    .allocatecj {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: none;
        .text {
            position: relative;
            display: inline-flex;
            margin: 0;
            padding-left: 20px;
            font-size: 12px;
            font-weight: 600;
            color: #0037ff;
            text-decoration: underline;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                background-image: url('../../components/images/clockBlue.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                width: 14px;
                height: 14px;
            }
        }
        
    }
}
.ocbDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
.ocbDetailsText {
    background-image: url('../images/info.svg');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 26px;
    background-color: transparent;
    border: none;
}
.ocbDetailsText:focus {
    outline: none;
    border: none;
}
.raiseOcb ul  {
    margin: 0;
    padding: 0;
}
.raiseOcb ul li {
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    padding-bottom: 5px;
}
.raiseOcb ul li span {
    font-size: 14px;
}
.raiseOcb {
    width: 250px;
    height: 150px;
}
.ctabox {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}
.ctabox button {
    background-color: #0037ff;
    color: #fff;
    border-radius: 10px;
    height: 34px;
    min-height: 34px;
    border: none;
    padding: 0 20px;
    cursor: pointer;
}
.ctabox button:focus {
    outline: none;
}
.ocbRuning {
    h6 {
        color: #f7c24c;
        font-size: 12px;
    }
    h5 {
        color: #000;
        font-size: 14px;
        margin-top: 20px;
    }
    span {
        font-size: 12px;
        letter-spacing: 1px;
        color: #999;
    }
}
.generatePr {
    h5 {
        text-align: center;
        color: #000;
        font-size: 12px;
    }
    h4 {
        font-size: 14px;
        color: #000;
        text-align: center;
        margin: 15px 0;
        span {
            font-size: 12px;
        }
    }
    h3 {
        color: #00ba67;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
    }
}
.ocbExpred {
    h4 {
        text-align: center;
        color: #E02020;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    h5 {
        font-size: 14px;
        color: #000;
        margin: 15px 0;
        text-align: center;
    }
}
.acceptPrice {
    p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: #333;
        text-align: center;
    }
    h5 {
        font-size: 14px;
        color: #000;
        text-align: center;
        margin:  15px 0 0  0;
        font-weight: bold;
    }
    .ctabox {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ocbRuningStatus {
        border-top: 1px solid #d4d3d3;
        margin-top: 20px;
        padding-top: 10px;
        p {
            color: #f4c251;
            font-size: 12px;
            span {
                color: #333;
                display: inline;
            }
        }
        span {
            font-size: 12px;
            letter-spacing: 1px;
            color: #999;
            text-align: center;
            display: block;
            padding-top: 10px;
        }
    }
}