.waittimeDashboardPopup {
    min-width: 400px;
    .otpWrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 50px 0 0 0;
        row-gap: 20px;
        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}