.appointmentDetailCard{
    padding: 0 40px;
    .appointmentCard {
        border-radius: 8px;
        border: 1px solid rgba(0,55,255,.08);
        background-color: #fff;
        height: auto;
        display: block;
        overflow: hidden;
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        position: relative;
        .customerDetail {
            width: 25%;
        }
        .deliveryPaymentLoans {
            width: 25%;
        }
        .vehicleStatus {
            width: 25%;
        }
        .prStatus {
            width: 25%;
        }
    }
}