
.popupWrap{
    display: block;
    max-width: 255px;
    margin: 0 auto;
}
.popupWrap h2{
    padding-bottom: 24px;
    margin: 0;
}
.customerDesc{
    display: block;
    margin: 0;
    padding-bottom: 24px;
    padding-left: 0;
}
.customerDesc li{
    display: inline-block;
    list-style-type: none;
    font-size: 14px;
    color: rgba(46, 56, 77, 0.67);
}
.customerDesc li::after {
    content: "|";
    margin: 0 5px;
}
.customerDesc li:last-child::after {
    content: "";
}
.getQuote{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
}
.getQuote button{
    width: 143px;
    height: 48px;
    display: inline-block;
}
.getQuote p{
    font-size: 14px;
    color: #b0bac9;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 28px;
}
.text, .priceText{
    font-size: 12px;
    color: #2e384d;
    letter-spacing: 2px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}
.priceText {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 15px;
}
.closeModal {
    background: none;
    border:none;
    outline: none;
    width: 190px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    color: #2e384d;
}
.closeModal:focus {
    outline: none;
}
.greenText, .orengeText, .redText {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    padding-top: 14px;
}
.greenText {
    color: #44ca6f;
}
.orengeText {
    color: #f77000
}
.redText {
    color: #fa0000;
}
.textPai {
    color: #2e384d;
    font-size: 14px;
    margin: 0;
    padding-top: 17px;
}
.textLabel {
    color: #2e384d;
    font-size: 12px;
    margin: 0px;
}
:global .MuiFormControl-marginDense {
    margin-bottom:0 !important;
}
:global .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #003fb8 !important;
}
.quoteText {
    margin-top: 16px;
    margin-bottom: 24px;
}