:global .react-datepicker__input-container, :global .react-datepicker-wrapper {
    width: 100% !important;
}
:global .react-datepicker-popper{
    transform: none !important;
    z-index: 9 !important;
}
.inputShift{
    position: relative;
    height: 40px;
}
.inputShift input{
    padding: 0 24px;
    border: 1px solid #dde5eb;
    height: 40px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    background: transparent;
    color: #465166 !important;
    font-weight: 500 ;
    box-shadow: none;
    width: 100%;
}
.inputShift input:focus {
    outline: none;
    box-shadow: none;
    background: #fff ;
    border:2px solid  #0037ff  ;
}
.inputShift label{
    position: absolute;
    top: 0;
    left: 10px;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 46px;
    color: #9aa1b5;
    -webkit-transition: all .1s easeinout;
    transition: all .1s easeinout;
    font-size: 16px;
    padding: 0 24px;
    z-index: 0;
}
.inputShift label:after {
    position: absolute;
    content: '';
    /* width: calc(100% 30px); */
    height: 14px;
    background: #fff;
    display: none;
    left: 15px;
    top: 0px;
    z-index: -1;
}
.inputShift label.active,
.inputShift input:focus~label {
    padding: 0 24px;
    font-size: 12px;
    top: 7px;
    left: 10px !important;
    line-height: 13px;
    height: 14px;
    width: auto;
    border-radius: 0;
    z-index: 1;
    color: #9aa1b5;
}
.inputShift input:focus~label{
    color:#0037ff;
    
}
.inputShift label.active:after,
.inputShift input:focus~label:after {
    display: block;
}
.error{
    color: #c80039;
    font-size: 12px;
    line-height: 6px;
}
:global .makeStyles-textField-61 {
    margin: 0 !important;
}
:global .react-datepicker__input-container input::placeholder {
    color:#9aa1b5 !important;
}