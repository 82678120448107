.reportPopupWrapper {
    width: 500px;
    min-height: 300px;
}
.reportPopupWrapper ul {
    margin: 32px 0 0 0;
    padding: 0;
    max-height: 500px;
    overflow: auto;
}
.reportPopupWrapper ul li {
    list-style: none;
    border-bottom: 1px solid #D1ECFF;
    padding: 16px 0px;
    list-style: none;
    display: grid;
    grid-template-columns: 50% 25% 25%;
    align-items: center;
}
.reportPopupWrapper ul li p {
    width: 200px;
}
.reportPopupWrapper ul li p, .reportPopupWrapper ul li span {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #465166;
    display: block;
}
.reportPopupWrapper ul li span {
    color: #205C8C;
}
.gotIt {
    margin-top: 20px;
}
.gotIt button {
    width: 100%;
}