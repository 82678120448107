.errorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 300px;
  margin: auto;
  position: absolute;
  top: 25%;
  right: 0;
  bottom: 0;
  left: 5%;
}
.errorWrapper .imgWrapper {
  width: 250px;
  height: 200px;
  position: relative;
}
.errorWrapper .imgWrapper img{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.errorTextWrapper {
  display: flex;
  flex-direction: column;
}
.errorTextWrapper h2 {
  font-size: 20px;
  color: #666;
  font-weight: bold;
}
.text {
  font-size: 16px;
  color: #999;
}