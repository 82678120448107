.appointmentWrapper {
    margin-top: 35px;
    min-width: 400px;
    .InputWrapper {
        margin: 0px 0 20px;
    }
}
.checkinButton {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.text {
    color: #2e384d;
    letter-spacing: 0.83px;
    font-size: 10px;
    font-weight: 600;
}

.questionText {
    margin-bottom: 5px;
    color: #2e384d;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
}
.hidden{
    display: none;
}
.show{
    display: block;
}