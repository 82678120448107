.headingSearchWrapper {
  padding: 0 40px 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f6fc;
  .toggleSection {
    background-color: #fff;
    height: 48px;
    width: 456px;
    border-radius: 8px 8px 0 0px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .arrowCalendar {
      display: flex;
      align-items: center;
      img {
        display: block;
        margin-right: 16px;
        cursor: pointer;
      }
      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 1px;
        color: #2E384D;
      }
    }
  }
}
.cjPlanningWrapper {
  padding: 0 40px;
  margin-top: 60px;
  .cjPlanningData {
    padding: 16px;
    background-color: #fff;
    border-radius: 0 8px 8px 8px;
    display: flex;
    .slotsWrapper {
      width: 150px;
      margin-top: 30px;
      span {
        display: block;
        font-weight: normal;
        font-size: 10px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #2E384D;
        &.active {
          color: #003FB8;
          font-weight: 600;
        }
      }
    }
    .cjDataWrapper {
      display: flex;
      position: relative;
      width: 90%;
      .nextPrev {
        button {
          cursor: pointer;
          background:transparent;
          border: none;
          outline: none;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-image: url('../../components/images/toggleArrow.svg');
          width: 24px;
          height: 24px;
          &.next{
            right: 0;
            transform: rotate(90deg);
          }
          &.prev{
            left: 0;
            transform: rotate(-90deg);
          }
        }
      }
      .cjData {
        width: 20%;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1px;
        h3, span {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1px;
          color: #2E384D;
        }
        span {
          display: block;
          font-weight: normal;
          font-size: 10px;
          line-height: 31px;
          &.active, &.activeRed, &.activeGreen {
            font-weight: 600;
          }
          &.active {
            color: #003FB8;
          }
          &.activeRed {
            color: #E02020;
          }
          &.activeGreen {
            color: #00BA67;
          }
        }
      }
    }
  }

}
.cjPlanning {
  position: relative;
}
.arrowCalendar :global .datepicker_inputShift__1gC1Q :global .react-datepicker__input-container input {
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #2E384D;
}
.arrowCalendar :global .datepicker_inputShift__1gC1Q :global .react-datepicker__input-container {
  position: relative;
  &::after {
    content: '';
    background-image: none;
  }
}