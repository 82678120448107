.incrementalTextbox {
    border: 1px solid #e0e7ff;
    background-color:#fff;
    border-radius: 8px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:12px;
}
.incrementalTextbox button {
    border-radius: 8px;
    background-color:#e0e7ff;
    width: 34px;
    height: 34px; 
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 1px;
}
 
.incrementalTextbox button:active{
    background: rgba(0, 55, 255, 0.80);
    transition: all 0.2s;
}
.incrementalTextbox button:focus {
    outline: none;
}
.incrementalTextbox input[type=text] {
    border: none;
    outline: none;
    padding: 0 10px;
    letter-spacing: 1.14px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    width: 80%;
}
.incrementalTextbox input[type=text]:disabled {
    color: #000;
    border: none;
    outline: none;
    padding: 0 10px;
    letter-spacing: 1.14px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    width: 80%;
}
:global .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 14px !important;
    z-index: 0 !important;
    display: inline-block !important;
    width: auto !important;
}