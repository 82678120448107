.statusWrapGreen, .statusWrapRed, .statusWrapOrange {
    border-radius: 20px 8px 8px 20px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-bottom: 5px !important;
    justify-content: center;
}
.statusTextGreen, .statusTextRed, .statusTextOrange {
    margin: 0 10px 0 -22px;
    padding: 6px 10px 6px 30px;
    border-radius: 20px 8px 8px 20px;
}
.statusTextGreen {
    color: #44ca6f;
    background-color: rgba(68,202,111,.24);
}
.statusTextRed {
    color: #ff6359;
    background-color:#ff63593b;
}
 .statusTextOrange {
    color: #f5ae4d;
    background-color: #f5ae4d38;
}