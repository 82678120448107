.feedBackmain {
    background-color: #e1e8ff;
    padding: 20px 0 15px 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    .wrapper {
        width: 100%;
        margin: 0 auto 0;
        padding: 0 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .feedbackWrapper {
            display: flex;
            justify-content: flex-end;
            .feedback {
                background-color: #fff;
                border-radius: 8px;
                padding: 10px;
                display: flex;
                width: 600px;
                justify-content: space-between;
                align-items: flex-end;
                .textarea {
                    width: 360px;
                }
                .cta {
                    width: 200px;
                }
                :global .form-group {
                   margin: 0; 
                }
            }
        }
        .likeSection {
            display: flex;
            .text {
                font-size: 24px;
                color: #2e384d;
                margin: 0;
                padding-right: 10px;
            }
            img {
                margin: 0 5px;
                opacity: 0.5;
            }
            .buttonWrapper {
                
            }
            .active {
                img {
                    opacity: 1;
                }
            }
        }
        .closeIcon {
            border: none;
            background: #00000057;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            color: #fff;
            position: absolute;
            right: 50px;
            top: -46px;
            cursor: pointer;
            z-index: 1;
            &:focus {
                border: none;
                outline: none;
            }
        }
    }
}
