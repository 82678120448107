.customerOffer {
    width: 100%;
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #cedcf5;
            padding: 4px 0;
            &:last-child {
                border-bottom: none;
            }
            .priceTopRow {
                background-color: #F8FBFD;
                display: flex;
                flex: 1;
                justify-content: space-between;
                height: 56px;
                align-items: center;
                h4, .price {
                    margin: 0;
                    padding: 0;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 33px;
                    letter-spacing: 1.28571px;
                    color: #205C8C;
                }
                .price {
                    img {
                        margin-right: 4px;
                        margin-top: -2px;
                        width: 18px;
                    }
                }
            }
            .offerLeftSection, .offerRightSection {
                padding: 0 15px;
                display: flex;
                align-items: center;
                font-size: 12px;
                .bold {
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 0.583333px;
                    color: #205C8C;
                }
                p {
                    margin: 0;
                    padding-left: 0px;
                }
                img {
                    padding-left: 6px;
                    width: 16px;
                    margin-top: 2px;
                }
            }
            .offerLeftSection {
                .infoIcon {
                    width: 22px;
                }
                :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"]:checked + label:before, :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"] + label:before {
                    background-size: 20px;
                }
                :global .checkbox_checkboxHolder__qYL7l label {
                    top: 4px;
                }
            }
            .offerRightSection {
                span {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0.75px;
                    color: #2e384d;
                    padding-left: 4px;
                }
                .redPrice {
                    color: #e95f5f;
                }
            }
            .clickStripWrapper {
                width: 100%;
                .clickStrip {
                    padding: 10px 0 10px 5px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    position: relative;
                    cursor: pointer;
                    &::before {
                        content: '';
                        background-image: url('../../components/images/arrow.svg');
                        width: 11px;
                        height: 6px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        // transform: rotate(90deg);
                    }
                }
                .subData {
                    width: 100%;
                }
            }
            .clickStripWrapper.active {
                .subData {
                    display: none;
                }
            }

        }
        li.lineThrough {
            .offerRightSection {
                span.redPrice {
                    color: #2e384d !important;
                }
            }
        }
    }
    .text {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: #2e384d;
        padding-top: 15px;
    }
    .cashbackBank, .getDiscount {
        border-radius: 8px;
        background-image: linear-gradient(267deg, #ffebd3, #ffe2dc);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 2px;
        .cashbacktext {
            display: flex;
            position: relative;
            padding-bottom: 2px;
            img {
                position: absolute;
                top: -10px;
                left: -4px;
                width: 64px;
            }
            .strips {
                padding-left: 40px;
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    font-weight: 600;
                    font-style: italic;
                    color: #ffffff;
                    span {
                        border-radius: 10px;
                        box-shadow: 0 5px 10px 0 rgba(0, 55, 255, 0.1);
                        background-image: linear-gradient(85deg, #0037ff, #4f75ff 102%);
                        padding: 3px 20px 4px 30px;
                    }
                }
            }
        }
        .price {
            color: #2e384d;
            font-size: 15px;
            font-weight: bold;
            margin: 0;
            padding-right: 20px;
            width: 140px;
            text-align: right;
        }
    }
    .getDiscount {
        background-image: linear-gradient(267deg, #dce4ff, #d0dbff);
        margin-top: 30px;
        .cashbacktext {
            .strips {
                p { 
                    span {
                        background-image: linear-gradient(85deg, #ff6359, #f8931f 102%);
                    }
                }
            }
        }
    }
}

.lineThrough {
    text-decoration: line-through;
}
.mt5 {
    margin-top: 5px;
}
.mt20 {
    margin-top: 20px;
}
.infoWrapper {
    position: relative;
    &:hover {
        .infoText {
            display: block;
        }
    }
    .infoText {
        position: absolute;
        bottom: 27px;
        left: -7px;
        z-index: 1;
        background-color: #205c8c;
        color: #fff;
        font-size: 10px;
        border-radius: 8px;
        padding: 5px 10px;
        display: none;
        width: 200px;
        &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #205c8c;
            position: absolute;
            left: 10px;
            bottom: -10px;
        }
    }
}