.searchSection {
    margin-top: 15px;
    display: flex;
  }
.title {
  padding-top:20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.title p{
  margin: 0;
  padding-left: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.headingSearchWrapper {
  padding: 0 40px 10px 40px;
  display: flex;
  justify-content: space-between;
  background: #f4f6fc;
  box-shadow: 0 10px 10px 0px rgba(0, 55, 255, 0.15);
}
.negotiationPagewrapper {
  margin-top: 60px;
  padding-top: 10px;
  overflow:hidden;
}
.loadmore {
  text-align: center;
  margin-top: -15px;
  margin-bottom: 5px;
}