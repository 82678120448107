.evaluatorRetailView, .evaluatorView {
    position: relative;
    .reload {
        position: absolute;
        right: 0;
        top:-30px;
        margin: 0;
        padding-right: 18px;
        font-weight: normal;
        cursor: pointer;
        font-size: 14px;
        &::after {
            content: '';
            background-image: url('../images/refresh.svg');
            background-repeat: no-repeat;
            width: 14px;
            height: 14px;
            position: absolute;
            right: 0;
            top: 5px;
        }
    }
    .retailView {
        border-bottom: 1px solid #e0e7ff;
        font-size: 15px;
        margin-top: 70px;
        padding: 0 10px 10px 10px;
        cursor: pointer;
        font-weight: bold;
        p {
            margin: 0;
            padding: 0;
        }
    }
    .retailViewTable {
        margin-top: 10px;
        min-height: 300px;
        table {
            border-spacing: 0 8px;
            border-collapse: unset;
            table-layout: fixed;
            thead {
                padding: 10px;
                tr {
                    background: #e0e7ff;
                    th {
                        font-size: 14px;
                        padding: 15px;
                        font-weight: 500;
                        &:first-child {
                            padding-left: 10px;
                        }
                        &:last-child {
                            padding-right: 10px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 14px;
                        color: #2e384d;
                        padding: 15px;
                        background-color: #fff;
                        .onoffswitch {
                            position: relative;
                            width: 60px;
                            input {
                                display:none;
                            }
                            .onoffswitchLabel {
                                display: block;
                                overflow: hidden;
                                cursor: pointer;
                                border-radius: 20px;
                                
                                &::before {
                                    content: '';
                                    display: none;
                                }
                            }
                        }
                        .done {
                            color: #2db923;
                            font-weight: bold;
                        }
                        .pending {
                            color: #2196F3;
                            font-weight: bold;
                        }
                        .denied {
                            color: #FF5D41;
                            font-weight: bold;
                        }
                        .starts {
                            color: #FFB81F;
                            font-weight: bold;
                        }
                        .font-weight-bold{
                            font-weight: bold;
                        }
                        &:first-child {
                            padding-left: 10px;
                        }
                        &:last-child {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.onoffswitchInner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitchCheckbox:checked + .onoffswitchLabel .onoffswitchInner {
    margin-left: 0;
}
.onoffswitchInner:before, .onoffswitchInner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 22px;
    font-size: 14px;
    color: white;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.onoffswitchInner:before {
    content: "Yes";
    padding-left: 10px;
    background-color: #2db923;
    color: #fff;
}
.onoffswitchInner:after {
    content: "No";
    padding-right: 10px;
    background-color: #dc502a;
    color: #fff;
    text-align: right;
}
.onoffswitchSwitch {
    display: block;
    width: 16px;
    height: 16px;
    margin: 2px;
    background: #ffffff;
    border-radius: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}
.onoffswitchCheckbox:checked + .onoffswitchLabel .onoffswitchSwitch {
    right: 0px;
}
.plusIcon, .minusIcon {
    position: relative;
}
.plusIcon::after, .minusIcon::after {
    position: absolute;
    right: 0;
    top:0;
    font-size: 20px;
}
.plusIcon::after {
    content: '+';
}
.minusIcon::after {
    content: '-';
}
