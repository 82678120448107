.range {
  .submitButton {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
  }
}
.maxDay {
  font-size: 12px;
  padding-left: 20px;
}