.costumerDetailWrapper {
    border-radius: 8px;
    border: 1px solid #e0e7ff;
    padding: 15px 0 38px 30px;
    background-color: #fff;
    margin-top: 15px;
    .labelWithTextWrapper {
        .textWrapper {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            padding-top: 32px;
            position: relative;
            flex-wrap: wrap;
            .rescheduleUrl {
                background-color: transparent;
                color: #007bff;
                font-size: 16px;
                border: none;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
            .loader {
                position: absolute;
                top: 40px;
                right: 8px;
                margin: 0;
            }
            .detailsLeftWrapper {
                flex: 0.6;
                margin-right: 50px;
                position: relative;
                .changevnWrapper {
                    display: flex;
                    .changevn {
                        margin-right: 20px;
                        width: 160px;
                    }
                }
                .primaryEmail {
                    width: 30ch;
                    display: flex;
                    justify-content: space-between;
                    .fixemail {
                        width: 26ch;
                        overflow: hidden;
                    }
                    .clipboadImage {
                        width: 20px;
                        cursor: pointer;
                        margin-top: 20px;
                    }
                }
            }
            .detailsRightWrapper {
                flex: 0.4;
                position: relative;
                .loader {
                    position: absolute;
                    top: 6px;
                    right: 8px;
                    margin: 0;
                }
            }
        }
    }
}
.labelWithText {
    display: flex;
    justify-content: space-between;
    flex: 0.4;
}
:global .input-text_inputShift__K7ipV {
    width: 100%;
}
.copiedIcon{
    line-height: 20px;
    font-size: 10px;
    display: flex;
    align-items: flex-end;
}