.evaluatorStoreSelect {
    background-color: #fff;
    box-shadow: 0px 4px 8px 0 rgba(0, 55, 255, 0.1);
    margin: 40px 40px 0 40px;
    padding: 10px 0px 30px;
    h3 {
        font-size: 18px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #e0e7ff;
        padding: 15px 0 25px;
    }
    .selectStore {
        padding: 5px 40px;
        margin: 30px 0 10px 0;
    }
}