.allocateWrapper {
    width: 450px;
    .txt {
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2e384d;
        margin-top: 10px;
        padding-bottom: 8px;
    }
    .customerLocationDetails {
        display: grid;
        grid-template-columns: 7% 93%;  
        row-gap: 20px;
        column-gap: 10px;
        font-size: 14px; 
    }
    .allocatecj {
        display: grid;
        grid-template-columns: 7% 43% 7% 43%;   
        row-gap: 20px;
        column-gap: 4px;
        font-size: 14px; 
        margin-top: 20px;
        align-items: center;
        .mr10 {
            margin-right: 10px;
        }
    }
    .autoAllocate {
        display: flex;
        align-items: center;
        margin-top: 20px;
        span {
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #465166;
        }
    }
    .ctaButton {
        display: flex;
        justify-content: space-around;
        margin-top: 70px;
      }
    .address {
        position: relative;
        display: flex;
        .addressWrapper {
            width: 350px;
        }
        .addressUpdateCta {
            display: flex;
            justify-content: flex-end;
            margin-top: -1rem;
        }
        .edit {
            position: relative;
            padding-left: 30px;
            font-size: 14px;
            color: #0037ff;
            font-weight: bold;
            margin-left: 10px;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0;
                background-image: url('../images/edit.svg');
                width: 25px;
                height: 25px;
                background-size: 100%;
            }
        }
    }
}
.timeDateWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .inputWrap {
        width: 49%;
        label {
            display: none;
        }
    }
    .location {
        width: 66%;
        label {
            display: none;
        }
    }
    .pincode {
        width: 32%;
        label {
            display: none;
        }
    }
}
