.selected {
  color: #0037ff;
  border: 1px solid #0037ff !important;
}
.filters li::before,
.filters li::after {
  content: "" !important;
}
.filters li {
  margin: 0 5px !important;
}
.unassignedSelect {
  min-width: 175px;
  margin-right: 10px;
}
