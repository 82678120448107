.copyAppointment .text{
    color: #585858;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}
.appointmentButton {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.copyAppointment {
    width: 450px;
    min-height: 200px;
    padding-top: 30px;
}