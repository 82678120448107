.leadCarDetailWrapper {
    margin-top: 15px;
    border-radius: 8px;
    border: 1px solid #e0e7ff;
    padding: 15px 0 38px 30px;
    background-color: #fff;
    .detailsLeftWrapper {
        padding-top: 32px;
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        a {
            color: #0037ff;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
        .store {
            margin-right: 50px;
        }
    }
    .carDetails {
        margin-top: 10px;
        a {
            font-size: 14px;
            color: #0037ff;
        }
    }
    .diffReportLink {
        color: #0037ff;
        cursor: pointer;
        font-size: 14px;
        &:hover {
            text-decoration: underline;
        }
    }
}