.cjplanningCard {
    table {
        border-spacing: 0 12px;
        border-collapse: unset;
        thead {
            tr {
                position: sticky;
                z-index: 1;
                top: 122px;
                box-shadow: 1px 10px 8px -7px #d1d1d1;
                background: #f4f6fc;
                height: 80px;
                th {
                    padding: 10px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #2E384D;
                    &:last-child {
                        text-align: center;
                    }
                    ul {
                        margin: 0;
                        padding: 10px 0 0 0;
                        display: flex;
                        justify-content: space-evenly;
                        li {
                            list-style: none;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 14px;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            color: #979797;
                        }
                    }
                    .infoSection {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        .tooltipSection {
                            position: relative;
                            img {
                                margin-left: 10px;
                                cursor: pointer;
                            }
                            .infoTool {
                                width: 180px;
                                background-color: #FFFFFF;
                                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
                                border-radius: 10px;
                                padding: 12px;
                                display: none;
                                position: absolute;
                                right: -40px;
                                top: 24px;
                                &::before{
                                    content: '';
                                    width: 0; 
                                    height: 0; 
                                    border-left: 25px solid transparent;
                                    border-right: 25px solid transparent;
                                    border-bottom: 25px solid #fff;
                                    position: absolute;
                                    top: -9px;
                                    right: 20px;
                                }
                                p {
                                    margin: 0;
                                    padding: 0;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 18px;
                                    letter-spacing: 0.5px;
                                    color: #585858;
                                    text-align: left;
                                    text-transform: none;
                                }
                                ul.attendanceInfo {
                                    margin: 16px 0 0 0;
                                    padding: 0;
                                    display: block;
                                    li {
                                        display: flex;
                                        align-items: center;
                                        margin: 8px 0;
                                        line-height: 32px;
                                        letter-spacing: 0;
                                        span {
                                            list-style: none;
                                            width: 32px;
                                            height: 32px;
                                            background-color: #00BA67;
                                            border-radius: 100%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            margin-right: 10px;
                                            &::before {
                                                content: 'P';
                                                font-weight: 600;
                                                font-size: 14px;
                                                line-height: 32px;
                                                color: #FFFFFF;
                                            }
                                            &.leave {
                                                background-color: #E02020;
                                                &::before {
                                                    content: 'L';
                                                }
                                            }
                                            &.weeklyOff {
                                                background-color: #2E384D;
                                                &::before {
                                                    content: 'O';
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover {
                                .infoTool {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                border: 1px solid rgba(0, 55, 255, 0.0796274);
                td {
                    padding: 10px;
                    background-color: #FFFFFF;
                    position: relative;
                    box-sizing: border-box;
                    &:first-child {
                        text-align: left;
                        border-radius: 8px 0 0 8px;
                    }
                    &:last-child {
                        border-radius: 0px 8px 8px 0px;
                    }
                    .carDetail {
                        margin-left: 10px;
                        ul {
                            margin: 0;
                            padding: 0;
                            li {
                                list-style: none;
                                font-size: 14px;
                                letter-spacing: 1px;
                                color: #585858;
                                padding: 6px 0 6px 0px;
                                display: flex;
                                align-items: center;
                                .detailsInfo {
                                    padding: 0;
                                    width: 90%;
                                    margin-left: 16px;
                                    font-size: 12px;
                                    line-height: 16px;
                                    letter-spacing: 0.5px;
                                    color: #2E384D;
                                    .max {
                                        white-space: nowrap;
                                        max-width: 18ch;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: block;
                                    }
                                }
                                .detailsIcon {
                                    margin: 0;
                                    padding: 0;
                                    width: 18px;
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #0037ff;
                                    img {
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                    .count {
                        font-size: 12px;
                        line-height: 18px;
                        min-width: 20px;
                        min-height: 18px;
                        color: #0037FF;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background-color: #ebefff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2px;
                        border-radius: 0px 0px 10px 0px;
                    }
                }
            }
        }
    }
    ul.attendance {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        li, &.leave, &.weeklyOff {
            list-style: none;
            width: 32px;
            height: 32px;
            background-color: #00BA67;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
                content: 'P';
                font-weight: 600;
                font-size: 14px;
                line-height: 32px;
                color: #FFFFFF;
            }
            &.leave {
                background-color: #E02020;
                &::before {
                    content: 'L';
                }
            }
            &.weeklyOff {
                background-color: #2E384D;
                &::before {
                    content: 'O';
                }
            }
        }
    }
}
