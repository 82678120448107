.wrapper {
    padding-left: 104px;
} 
.wrapper.openMenu {
    padding-left: 225px;
}

:global .Toastify__toast {
    min-width: 300px !important;
    border-radius: 10px !important;
    box-shadow: 0 6px 12px 0 rgba(68, 202, 111, 0.25) !important;
    border: solid 1px rgba(68, 202, 111, 0.25) !important;
    text-align: center;
    min-height: 48px !important;   
  }
:global .Toastify__toast-container--top-center {
    top: 70px !important;
}
.hiWrapper, .normalhiWrapper {
    position: relative;
}
.hiWrapper :global .appointment-page_appointmentList__2k8f5 table thead tr::after {
    top: 300px !important;
}
.hiWrapper :global .appointment-page_appointmentList__2k8f5 table {
    margin-top: 300px !important;
}
.hiWrapper :global .appointment-page_appointmentList__2k8f5 table tr th {
    top: 308px !important;
}
.hiWrapper .appointment-page_appointmentList__2k8f5 table {
    margin-top: 672
}
.normalhiWrapper :global .appointment-page_appointmentList__2k8f5 table {
    margin-top: 230px;
}
.normalhiWrapper :global .appointment-page_appointmentList__2k8f5 table thead tr::after {
    top: 232px;
}
.normalhiWrapper :global .appointment-page_appointmentList__2k8f5 table tr th {
    top: 236px;
}
.normalhiWrapper :global .appointment-page_appointmentList__fn0K3 table{
    top: 125px;
}