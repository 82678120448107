.otpConfirmPopup {
    margin-top: 30px;
    .txt {
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2e384d;
        margin-top: 10px;
        padding-bottom: 8px;
    }
    .lists {
        font-size: 14px;
        color: #2e384d;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                display: flex;
                justify-content: space-between;
                margin: 5px 0;
                span {
                    font-weight: normal;
                    width: 50%;
                }
                p {
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                    width: 50%;
                }
                .sendOtp {
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0.75px;
                    color: #0037ff;
                    padding-top: 5px;
                    cursor: pointer;
                }
            }
        }
    }
    .inputWrapper {
        margin-top: 50px;
    }
    .ctaWrapper {
        margin: 80px 0 0 0;
        display: flex;
        justify-content: space-around;
    }
}