.reportsTab {
ul {
    margin: 0;
    padding: 0;
    display: flex;
    li {
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    color: #2e384d;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-right: 80px;
    padding-bottom: 5px;
    &:hover, &.active
    {
      color: #0037ff !important;
      border-bottom: 2px solid #0037ff !important;
      cursor: pointer;
    }
    }
  }
}