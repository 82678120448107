.leadsWrapper {
  position: relative;
  cursor: pointer;
  width: auto;
  margin-left: 20px;
  p{
    font-size: 14px;
    font-weight: bold;
    color: #2e384d;
    letter-spacing: 1px;
    position: relative;
    padding-right: 20px;
    margin:0;
    height: 60px;
    display: flex;
    align-items: center;
    span {
      padding-left: 5px;
      font-weight: 600;
      color: #2e384d;
    }
    &:hover {
      color: #0037ff;
    }
    &.titleActive, .titleActive:hover {
      color:#0037ff !important;
      transition: all 0.2s;
      &::after {
        background-image: url('../images/arrow.svg');
      }
      // &::before {
      //   content: '';
      //   border: 1px solid #0037ff;
      //   position: absolute;
      //   bottom: 0px;
      //   left: 0;
      //   width: 100%;
      // }
    }
    &::after {
      content: '';
      background-image: url('../images/lead_arrow.svg');
      background-repeat: no-repeat;
      background-position: 0 0;
      width: 11px;
      height: 6px;
      position: absolute;
      top: 28px;
      right: 0px;
    }
  }
  .leadsDropdown {
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    top: 60px;
    left: 0px;
    z-index: 999;
    box-shadow: 0 6px 12px 0 rgba(0,55,255,.1);
    width: 200px;
    -webkit-transition: all .2s;
    transition: all .2s;
    display: none;
    transform: none !important;
    ul {
      margin: 0;
      padding: 0;
      li {
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;
        list-style: none;
        position: relative;
        text-align: center;
        .rightSign {
          position: absolute;
          left: 8px;
          top: 18px;
          z-index: 1;
        }
        .subChildArrow {
          background-image: url('../images/lead_arrow.svg');
          background-repeat: no-repeat;
          background-position: 0 0;
          width: 11px;
          height: 6px;
          position: absolute;
          top: 22px;
          right: 20px;
          transform: rotate(-90deg); 
        }
        a {
          height: 48px;
          line-height: 48px;
          text-decoration: none;
          font-size: 14px;
          letter-spacing: 1px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          border-bottom: 1px solid #e0e7ff;
          padding-left: 30px;
          color: #2e384d;
          img {
            padding-right: 20px;
          }
          &:hover {
            background-color: #f4f6fc;
            color: #0037ff;
          }
        }
        .child {
          position: fixed;
          top: 60px;
          background-color: #fff;
          min-height: 96px;
          margin-right: 0%;
          border-left: 1px solid #e0e7ff;
          border-bottom-right-radius: 8px;
          z-index: 9;
          left: 350px;
          right: 0px;
          padding: 0px 10px 10px 10px;
          box-shadow: 0 6px 10px 0 rgba(0, 55, 255, 0.1);
          min-width: 300px;
          display: none;
          span {
            font-weight: 600;
            color: #2e384d;
            font-size: 12px;
            letter-spacing: 2px;
            padding: 10px 10px 5px;
            display: block;
            text-align: left;
          }
        }
        &:last-child {
          a {
            border-bottom: none;
          }
          &:hover {
            a {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
        &:hover {
          .child {
            display: block;
          }
        }
        // &:first-child {
        //   a {
        //     position: relative;
        //     &::after {
        //       content: '';
        //       background-image: url('../images/lead_arrow.svg');
        //       background-repeat: no-repeat;
        //       background-position: 0 0;
        //       width: 11px;
        //       height: 6px;
        //       position: absolute;
        //       top: 22px;
        //       right: 20px;
        //       transform: rotate(-90deg);
        //     }
        //   }
        //   &:hover {
        //     a {
        //       &::after {
        //         background-image: url('../images/arrow.svg');
        //       }
        //     }
        //   }
        // }
        &:hover {
          .subChildArrow {
            background-image: url('../images/arrow.svg');
          }
        }

      }

    }
    &.active {
      display: block;
    }
  }
  &:hover {
    p {
      color: #0037ff;
      &::before {
        content: '';
        border: 1px solid #0037ff;
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
      }
      &::after {
        background-image: url('../images/arrow.svg');
        transform: rotate(180deg);
        transition: all 0.2s;
      }
    }
  }
}

@media screen and (min-width: 600px){
  .leadsWrapper {
    p {
      span {
        white-space: nowrap;
        max-width: 12ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (min-width: 1025px)  {
  .leadsWrapper {
    p {
      span {
        max-width: none;
      }
    }
  }
}