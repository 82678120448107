.uploadCsv {
    border: 1px solid #e0e7ff;
    margin: 100px 20px 0 20px;
}
.uploadCsv ul {
    margin: 0;
    padding: 0;
}
.uploadCsv ul li {
    list-style: none;
    display: grid;
    grid-template-columns: 20% 80%;
    column-gap: 10px;
    align-items: center;
    border-bottom: 1px solid #e0e7ff;
}
.uploadCsv ul li:last-child {
    border-bottom: none;
}
.uploadCsv ul li label {
    font-size: 18px;
    font-weight: bold;
    color: #2e384d;
    text-align: center;
    border-right: 1px solid #e0e7ff;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.lastuplated {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 0;
    padding: 0;
}
.lastuplated h3 {
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 20px;
    font-weight: bold;
}
.uploadCsv ul li span {
    font-size: 12px;
    color: #333;
}