.selectDocumentUpload {
    display: grid;
    grid-template-columns: 20% 80%;
    margin: 35px 60px;
    ul {
        margin: 0 0 0 10px;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            display: flex;
            margin: 0 5px;
        }
    }
}