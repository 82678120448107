.lapse {
  color: #ff6359;
  font-size: 12px;
  letter-spacing: 0.5px;
  position: relative;
}
.lapse::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #ff6359;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 3px;
}
.time {
  padding-left: 26px;
}