
.globalSearchWarpper {
    position: relative;
}
.globalSearch {
    height: 32px;
    border-radius: 3px;
    border: solid 1px rgba(0, 55, 255, 0.2);
    background-color: #ffffff;
    padding: 0px 0 0px 10px;
    position: relative;
    min-width: 280px;
    display: flex;
}
.globalSearch  input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 12px;
    color: #2e384d;
}
.searchIcon {
    background-image: url('../images/search.svg');
    background-position: 5px 8px;
    background-repeat: no-repeat;
    width: 32px;
    height: 34px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
} 
.searchIcon:focus {
    outline: none;
    border: none;
}
.searchList {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top:1px solid rgba(0, 55, 255, 0.2);
    border-left:1px solid rgba(0, 55, 255, 0.2);
    border-bottom: 1px solid rgba(0, 55, 255, 0.2);
    border-right: 1px solid rgba(0, 55, 255, 0.2);
    box-shadow: 0 3px 4px 0 rgba(0, 55, 255, 0.2);
    background-color: #ffffff;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    z-index: 9;
    max-height: 242px;
    overflow: auto;
}
.searchList ul {
    margin: 0;
    padding: 0;
}
.searchList ul li {
    list-style: none;
    padding: 0 10px;
    border-bottom: 1px solid #e0e7ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    cursor: pointer;
}
.searchList ul li.noRecords {
    font-size: 14px;
    color: #2e384d;
    justify-content: center;
    opacity: 0.5;
}
.searchList ul li:hover {
    background-color: #e0e7ff;
}
.globalSearchLoader {
    position: absolute;
    top: 2px;
    right: 10px;
}
.collapsedSearch {
    cursor: pointer;
}