.priceTextAmount {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #585858;
    padding-top: 14px;
}
.psiSlider{
    margin-top: 40px; 
    width: 100%; 
}