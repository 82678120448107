.chargesHoldbacks {
    margin-top: 20px;
    .documentWrapper {
        background: #FFFFFF;
        border: 1px solid #E0E7FF;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 20px;
        h3 {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0.5625px;
            color: #2E384D;
            margin: 0;
            padding: 0;
        }
        .info {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #205C8C;
            margin: 16px 0 0 0;
            padding: 0 0 0 30px;
            position: relative;
            &::before {
                content: '';
                background-image: url('./images/infoIcon.svg');
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
            }
        }
        .verificationWrapper {
            display: flex;
            align-items: center;
            margin-top: 16px;
            p {
                margin: 0;
                padding: 0;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.5625px;
            }
            .verification {
                color: #FF6359;
                font-size: 18px;
            }
            .verificationApproved {
                color: #00BA67;
                font-size: 18px;
            }
            .send {
                margin-left: 20px;
                position: relative;
                padding-right: 50px;
                color: #0037FF;
                cursor: pointer;
                &::before {
                    content: '';
                    background-image: url('../../components/images/sms.svg');
                    background-repeat: no-repeat;
                    width: 40px;
                    height: 18px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .smsSent {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5625px;
                color: #00BA67;
                margin: 0 0 0 20px;
                padding: 0 0 0 30px;
                position: relative;
                &::before {
                    content: '';
                    background-image: url('../../components/images/rightRounded.svg');
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;;
                }
            }
        }
        .customerOffer {
            margin-top: 30px;
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    border-bottom: 1px solid #cedcf5;
                    padding: 4px 0;
                    &:last-child {
                        border-bottom: none;
                    }
                    .priceTopRow {
                        background-color: #ebf2ff;
                        display: flex;
                        flex: 1;
                        justify-content: space-between;
                        height: 40px;
                        align-items: center;
                        line-height: 40px;
                        letter-spacing: 1.28571px;
                        color: #205C8C;
                        h4 {
                            margin: 0;
                            padding: 0;
                            font-size: 18px;
                            font-weight: bold;
                            letter-spacing: 1.29px;
                            color: #205c8c;
        
                        }
                        .price {
                            font-size: 18px;
                            font-weight: 600;
                            letter-spacing: 0.83px;
                            color: #205c8c;
                            margin: 0;
                            padding:0;
                            img {
                                margin-right: 4px;
                                margin-top: -2px;
                                width: 16px;
                            }
                        }
                    }
                    .offerLeftSection, .offerRightSection {
                        padding: 0 15px;
                        display: flex;
                        align-items: center;
                        .bold {
                            font-weight: bold;
                            font-size: 14px;
                            img {
                                margin-right: 5px;
                                width: 15px;
                            }
                        }
                        p {
                            margin: 0;
                            padding-left: 0px;
                            font-size: 14px;
                        }
                        img {
                            padding-left: 6px;
                            width: 14px;
                            margin-top: 2px;
                        }
                    }
                    .offerLeftSection {
                        .infoIcon {
                            width: 22px;
                        }
                        :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"]:checked + label:before, :global .checkbox_checkboxHolder__qYL7l input[type="checkbox"] + label:before {
                            background-size: 20px;
                        }
                        :global .checkbox_checkboxHolder__qYL7l label {
                            top: 4px;
                        }
                    }
                    .offerRightSection {
                        span {
                            margin: 0;
                            padding: 0;
                            font-size: 12px;
                            font-weight: 600;
                            letter-spacing: 0.75px;
                            color: #2e384d;
                            padding-left: 4px;
                        }
                        .redPrice {
                            color: #e95f5f;
                        }
                    }
                    .clickStripWrapper {
                        width: 100%;
                        .clickStrip {
                            padding: 10px 0 10px 5px;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            position: relative;
                            cursor: pointer;
                            &::before {
                                content: '';
                                background-image: url('../../components/images/arrow.svg');
                                width: 11px;
                                height: 6px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                                transform: rotate(-180deg);
                            }
                        }
                        &.active {
                            .clickStrip {
                                &::before {
                                    content: '';
                                    transform: rotate(0deg);
                                }
                            }
                        }
                        .subData {
                            width: 100% !important;
                        }
                    }
                    .clickStripWrapper.active {
                        .subData {
                            display: none;
                        }
                    }
                    .payableText {
                        display: flex;
                        justify-content: space-between;
                        flex: 100%;
                        padding: 0 15px;
                        margin-top: 10px;
                        p {
                            margin: 0;
                            padding: 0;
                            color: #205C8C;
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                    .priceTextWrapper {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        flex: 100%;
                        border-top: 1px solid #cedcf5;
                    }
                }
                li.lineThrough {
                    .offerRightSection {
                        span.redPrice {
                            color: #2e384d !important;
                        }
                    }
                }
            }
            .text {
                font-size: 12px;
                letter-spacing: 0.5px;
                color: #2e384d;
                padding-top: 15px;
            }
            .cashbackBank, .getDiscount {
                border-radius: 8px;
                background-image: linear-gradient(267deg, #ffebd3, #ffe2dc);
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                padding-bottom: 2px;
                .cashbacktext {
                    display: flex;
                    position: relative;
                    padding-bottom: 2px;
                    img {
                        position: absolute;
                        top: -10px;
                        left: -4px;
                        width: 64px;
                    }
                    .strips {
                        padding-left: 40px;
                        p {
                            margin: 0;
                            padding: 0;
                            font-size: 14px;
                            font-weight: 600;
                            font-style: italic;
                            color: #ffffff;
                            span {
                                border-radius: 10px;
                                box-shadow: 0 5px 10px 0 rgba(0, 55, 255, 0.1);
                                background-image: linear-gradient(85deg, #0037ff, #4f75ff 102%);
                                padding: 3px 20px 4px 30px;
                            }
                        }
                    }
                }
                .price {
                    color: #2e384d;
                    font-size: 15px;
                    font-weight: bold;
                    margin: 0;
                    padding-right: 20px;
                    width: 140px;
                    text-align: right;
                }
            }
            .getDiscount {
                background-image: linear-gradient(267deg, #dce4ff, #d0dbff);
                margin-top: 30px;
                .cashbacktext {
                    .strips {
                        p { 
                            span {
                                background-image: linear-gradient(85deg, #ff6359, #f8931f 102%);
                            }
                        }
                    }
                }
            }
        }
        
        .lineThrough {
            text-decoration: line-through;
        }
        .mt5 {
            margin-top: 5px;
        }
        .mt20 {
            margin-top: 20px;
        }
        .infoWrapper {
            position: relative;
            &:hover {
                .infoText {
                    display: block;
                }
            }
            .infoText {
                position: absolute;
                bottom: 27px;
                left: -7px;
                z-index: 1;
                background-color: #205c8c;
                color: #fff;
                font-size: 10px;
                border-radius: 8px;
                padding: 5px 10px;
                display: none;
                width: 200px;
                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-top: 10px solid #205c8c;
                    position: absolute;
                    left: 10px;
                    bottom: -10px;
                }
            }
        }
    }
}