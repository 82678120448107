.loginOuter{
    background: #fff;
    height: auto;
    position: fixed;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.loginHeader{
    font-size: 34px;
    line-height: 50px;
    color: #b0bac9;
    margin-bottom: 10px;
    text-align: center;
}
.loginDetail{
    color: #b0bac9;
    font-size: 14px;
    color: #31708f;
    background-color: #d9edf763;
    border-color: #bce8f1;
    padding: 10px 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}
.loginWrapper {
    display: flex;
}
.loginForm {
    flex: 1 1 41.666667%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.loginImg {
    flex: 2 1 58.333333%;
    margin: auto;
}
.formGroup {
    margin: 0px 0 6px 0;
}
.carBikeWrapper {
    display: flex;
    /* margin-bottom: -80px; */
}
.bikeSection {
    margin-right: 50px;
}
.carBikeWrapper label {
    font-size: 12px;
    color: #31708f;
}
.carLoginWrapper {
    display: none;
}
.errorContainer{
    margin-top: 1.25rem;
}
.loginForm :global #okta-sign-in .auth-header {
    display: none;
}
.loginForm :global #okta-sign-in {
    margin: 0px 0 0 0;
}
.loginForm :global #okta-sign-in.no-beacon .auth-content {
    padding-top: 0;
    padding-bottom: 10px;
}
.loginForm :global #okta-sign-in .custom-checkbox {
    box-shadow: none !important;
}
.loginForm :global #okta-sign-in .custom-checkbox label, .loginForm :global #okta-sign-in .custom-radio label {
    padding: 7px 10px 7px 24px !important;
}

.loginForm :global .primary-auth-form, .loginForm :global .auth-footer, .loginForm :global .auth-divider, .loginForm :global .okta-sign-in-header {
  display: none !important;
}

.loginForm h2 {
    display: none;
}
.loginForm :global #okta-sign-in .auth-divider {
    margin-bottom: 5px;
}
.loginForm :global #okta-sign-in .social-auth-button.link-button:last-of-type {
    margin-bottom: 15px;
}
.loginForm :global #okta-sign-in.auth-container.main-container .margin-btm-30 {
    margin-bottom: 10px;
}
.loginForm :global #okta-sign-in .o-form-button-bar {
    padding-bottom: 10px;
}
.carlogin {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 0 hsl(0deg 0% 69% / 12%);
    border-radius: 3px;
    padding: 15px 42px 20px;
    margin-top: 30px;
}
.carlogin .formGroup {
 margin: 15px 0;   
}
.btnWrapGoogle, .btnWrap {
    width: 100%;
} 
.btnWrapGoogle button, .btnWrap button {
    width: 100%;
}
.btnWrapGoogle button {
    background-color: #fff !important;
    color: #5e5e5e !important;
    border: 1px solid #c3c3c3 !important;
    box-shadow: none !important;
    line-height: 30px;
}
.btnWrapGoogle button span {
    text-align: center;
    width: 100%;
}
.forgot {
    margin: 10px 0;
    font-size: 12px;
}
.or {
    background-color: #a7a7a7;
    height: 1px;
    margin-bottom: 25px;
    text-align: center;
    margin-top: 20px;
}
.or span {
    background-color: #fff;
    position: relative;
    top: -.5em;
    padding: 0 15px;
}
@media screen and (max-width: 1024px) {
    .loginWrapper {
        flex-wrap: wrap;
        margin: 0 15%;
        flex: 1;
    }
    .loginImg {
        display: none;
    }
    .carBikeWrapper {
        margin-bottom: -5px;
    }
}
